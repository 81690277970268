
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { imgStoragePath } from '../../constants'
import { Row, Col } from 'reactstrap';
export default class LogoutInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            logoutInfoFlag: false,
        }
    }
    render() {
        const { logoutInfoFlag } = this.state
        let marginVal = this.props.margin
        return (
            <>
                {
                    logoutInfoFlag ?
                        <Row style={this.props.margin ? { marginTop: marginVal } : null}>
                            <Col md="12">
                                <p className="deployment-info">
                                    <span>If you are facing access issue on Project Management, because of recent Project Custom Roles Release on (14th Oct 2024) please try with logout and login and clear cache.</span>
                                    <span >
                                        <img width="13" onClick={() => { this.setState({ logoutInfoFlag: false }) }} src={imgStoragePath + "toast_cross.png"} alt="Close Icon" />
                                    </span>
                                </p>
                            </Col>
                        </Row>
                        : null
                }
            </>
        )
    }
}