import React, { useState, useEffect, Fragment }  from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import {DropzoneArea} from 'material-ui-dropzone';
import { Breadcrumbs, Link, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@material-ui/core';
import { PageLoader } from '../../../shared_elements';
import { checkApiStatus } from '../../../utils_v2';
import { globalGetService,  globalPutService } from '../../../utils_v2/globalApiServices';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { trackActivity } from '../../../utils/mixpanel';
import { CheckBox } from '@material-ui/icons';
const UploadDocuments = ({params, uploadFilesInfo, toggleModalFn, getResponseBack,updateCheckListFiles}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [checkListData, setCheckListData] = useState({navigation:[],folders:[]})
    const [selectedUuid, setSelectedUuid] = useState( uploadFilesInfo?.checklist?.last_used_folder_uuid  ? {name:'', uuid:uploadFilesInfo?.checklist?.last_used_folder_uuid}:null);
    const [files, setFiles] = useState([]);
    useEffect(() => {
        getCheckListLinkData(uploadFilesInfo.checklist.last_used_folder_uuid, 'firstCall')
    },[]);
    const getCheckListLinkData = (uuid, callType) => {
        setLoading(uploadFilesInfo.checklist.last_used_folder_uuid  && true);
        globalGetService(uploadFilesInfo.checklist.last_used_folder_uuid && `technical/v2/workorder/${params.workOrderSlug}/record-folders-files/${uuid}/?asset_type=${params.type}`)
        .then(response => {
          setLoading(false);
          if(checkApiStatus(response)){
            setCheckListData(response.data.data);
            if(callType) {
                let name = response.data.data.navigation[response.data.data.navigation.length -1].name;
                setSelectedUuid({uuid:uuid, name:name});
            }
          }
        })
    }
    const handleFileUpload = () => {
        setLoading(true);
        let formData = new FormData();    
        formData.append('folder_uuid', selectedUuid.uuid);
        if(uploadFilesInfo?.onlyProjects){
            formData.append('only_technical_permission', true);
        }
        for(let i = 0; i < files.length; i++){
            formData.append('files[]', files[i]);
        }
        globalPutService(`technical/v2/workorder/${params.workOrderSlug}/document-checklists/${uploadFilesInfo.checklist.id}/?asset_type=${params.type}`,formData)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                toggleModalFn();updateCheckListFiles(response.data.data);getResponseBack()
                enqueueSnackbar("Files Uploaded Successfully", { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                trackActivity('Project Management', {
                    event_type: 'File(s) Upload Success',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    id: uploadFilesInfo.checklist.id ? uploadFilesInfo.checklist.id : '',
                    request_body: formData ? formData : '',
                    response: response.data.data,
                    success_msg: 'File(s) Uploaded Successfully'
                });
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                trackActivity('Project Management', {
                    event_type: 'File(s) Upload Failed',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    id: uploadFilesInfo.checklist.id ? uploadFilesInfo.checklist.id : '',
                    request_body: formData ? formData : '',
                    response: response.data.data,
                    error_msg: response.data.message,
                    error_source: 'Backend'
                });
            }
        });
    };
    const handleDropzoneChange = (uploadedFiles) => {
      setFiles(uploadedFiles);
    };
    return(
        <div className='upload-document-section'>
        <Dialog
            open={uploadFilesInfo.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='lg'
        >
            <DialogTitle id="scroll-dialog-title">
                Upload Document(s)
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{width:'720px'}}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <DropzoneArea
                                filesLimit={10}
                                maxFileSize={104857600}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                showPreviews={true}
                                dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['info','error']}
                                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                clearOnUnmount={true}
                                onChange={handleDropzoneChange} 
                            /> 
                            <span className='files-upload-limit' > Note : Max files upload limit is 10 in single time.</span>
                           {uploadFilesInfo?.checklist?.last_used_folder_uuid ? 
                            <div className='checklist-file-linking'>
                                <div className='linking-navigation'>
                                    <Breadcrumbs maxItems={3} aria-label="breadcrumb">
                                        {checkListData.navigation.map((nav, index) =>
                                        checkListData.navigation.length - 1 == index ?
                                            <Link onClick={() => getCheckListLinkData(nav.uuid)} className="list-inline-item navigation_modal">{nav.name}</Link>
                                            :
                                            <Link onClick={() => getCheckListLinkData(nav.uuid)} className="list-inline-item navigation_modal">{nav.name}</Link>
                                        )}
                                    </Breadcrumbs>
                                </div>
                                <div style={{padding:'5px'}}>
                                    { checkListData.folders.length ?
                                    <>
                                    {checkListData.folders.map((folder, index) => 
                                        <Grid container spacing={1}>
                                            <Grid item md={10}>
                                                <h4 className='flex-centered' onClick={() => getCheckListLinkData(folder.uuid)}>
                                                    <FolderOpenIcon color='primary' fontSize='small' /> <span>{folder.name}</span>
                                                </h4>
                                            </Grid>
                                            <Grid item md={2}>
                                                 <Checkbox
                                                    color='primary'
                                                    size='small'
                                                    checked={selectedUuid && selectedUuid.uuid ? folder.uuid === selectedUuid.uuid:false}
                                                    onChange={(e) => setSelectedUuid(e.target.checked ? {name:folder.name,uuid:folder.uuid}:null)}
                                                    />
                                            </Grid>
                                        </Grid>
                                    )}</>
                                    :<p style={{textAlign:'center'}}>No folders found</p>}
                                </div>
                            </div> : null}
                            
                        </Grid>
                    </Grid>
                </div>
                
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item md={9}>
                        <Fragment>
                            <p>Current file(s) will be stored in : 
                                <b>{ selectedUuid && selectedUuid.name ? selectedUuid.name:''}</b>
                            </p>
                            { !(selectedUuid && selectedUuid.name) ?
                                <span>Select the folder to store in Records DataRoom:</span>:null
                            }
                        </Fragment>
                    </Grid>
                    <Grid item md={3}>
                        <ul className='list-inline' style={{float:'right'}}>
                            <li className='list-inline-item'>
                                <Button onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                            </li>
                            <li className='list-inline-item'>
                                <Button disabled={ files.length && selectedUuid && selectedUuid.uuid  ? false:true } onClick={handleFileUpload} color="primary" size='small' variant='contained'>Save</Button>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                
            </DialogActions>
        </Dialog>
        {isLoading ? <PageLoader/> : null}
     </div>
    )
}
export default withRouter(UploadDocuments);