import React from 'react';
import {Tooltip,Button, TableCell, TableRow } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const FindingRow = ({previewType, index, item, onEdit, onDelete, onView, openAttachments, onHoverEvent}) => {
    return(
        <TableRow  hover
                onMouseLeave={(e) => onHoverEvent('leave')}
                onMouseEnter={(e) => onHoverEvent('enter')}
                >
            <TableCell >
                    {index}
            </TableCell>
            <TableCell>{item?.section?.name||'--'}</TableCell>
            <TableCell>{item?.finding_type||'--'}</TableCell>
            {previewType === 'brief' ? 
                <>
                    <TableCell>{item?.damage_dimension||'--'}</TableCell>
                    <TableCell>{item?.damage_limit||'--'}</TableCell>    
                </>:null
            }
            <TableCell>{item?.remarks||'--'}</TableCell>
            {previewType === 'brief' ?
                <>
                    <TableCell>{item?.classifications||'--'}</TableCell>
                    <TableCell>{item?.category||'--'}</TableCell>
                    <TableCell>{item?.repair_type||'--'}</TableCell>
                    <TableCell>{item?.intervals||'--'}</TableCell>
                    <TableCell>
                        {item?.images?.length ? <> <span style={{'vertical-align':'top'}}>({item?.images?.length})</span><AttachFileIcon onClick={openAttachments} fontSize='small'/></> : ''}
                    </TableCell>
                </>:null
            }
            <TableCell align='right' width='100'>
                <ul className='list-inline'>
                    <li className='list-inline-item '>
                        <Tooltip title="View" arrow>
                            <VisibilityIcon onClick={onView} color='primary' fontSize='small' />
                        </Tooltip>

                    </li>
                    <li className='list-inline-item '>
                        <Tooltip title="Edit" arrow>
                            <EditIcon onClick={onEdit} color='primary' fontSize='small' />
                       </Tooltip>
                    </li>
                    <li className='list-inline-item '>
                        <Tooltip title="Delete" arrow>
                            <DeleteOutlineIcon onClick={onDelete} color='secondary' fontSize='small' />
                       </Tooltip>
                    </li>
                </ul>
            </TableCell>
        </TableRow>
    )
}
export default FindingRow;