import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Drawer, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip, IconButton } from '@material-ui/core';
import { ImageSlider, EditImageDialog } from '../../Elements';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeletePopUp, PageLoader } from '../../../shared_elements';
import { dropzonePreviewUI } from '../../../constants';
import { checkApiStatus } from '../../../utils_v2';
import { getFindingApi, addEditFindingApi, changeFindingImageApi, deleteFindingImageApi, annotateFindingImgApi } from '../apiServices';
import { findingErrorCode } from '../'
const findingObj = {
  section:null,
  finding_type: null,
  damage_dimension: '',
  damage_limit:'',
  remarks:'',
  classifications:null,
  category:null,
  repair_type: null,
  arm_srm_reference:'',
  dd_wo_reference:'',
  corrective_actions:'',
  intervals:''
};
const findingTypes = [
  {value:"Dent",label:"Dent"},
  {value:"Scratch",label:"Scratch"},
  {value:"Nick",label:"Nick"},
  {value:"Gouge",label:"Gouge"},
  {value:"Erosion",label:"Erosion"},
  {value:"Corrosion",label:"Corrosion"},
  {value:"Puncture",label:"Puncture"},
  {value:"Lightning Strike",label:"Lightning Strike"},
  {value:"Hail strike",label:"Hail strike"},
  {value:"Wave",label:"Wave"},
  {value:"Crack",label:"Crack"},
  {value:"Others", label:"Others"}
];
const classifications = [
  {value:"Major",label:"Major"},
  {value:"Minor",label:"Minor"},
];
const category = [
  {value:"A",label:"A"},
  {value:"B",label:"B"},
  {value:"C",label:"C"}
];
const repairType = [
  {value:"Doubler",label:"Doubler"},
  {value:"Composite",label:"Composite"},
]
class AddEditFinding extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      formSubmitLoader:false,
      initiateHotSpotCoOrdinate:false,
      findingCrud:{
        modal:false,
        data:{},
        error:{},
        mode:''
      },
      deleteModal:false,
      deleteIds:{},
      sliderInfo: {
        modal:false,
        images:[],
        startIndex:0
      },
      imgAnnotation:{
        modal:false,
        data:{}
      }
    }
    this.getFindingApi = getFindingApi.bind(this);
    this.addEditFindingApi = addEditFindingApi.bind(this);
    this.changeFindingImageApi = changeFindingImageApi.bind(this);
    this.deleteFindingImageApi = deleteFindingImageApi.bind(this);
    this.annotateFindingImgApi = annotateFindingImgApi.bind(this);
    window.addEventListener('message', ({data}) => {
      switch(data?.type){
        case 'selectedHotspot':
          if(data.hotspot.title === 'New Hotspot'){
            this.setState(prevState => ({
              ...prevState,
              initiateHotSpotCoOrdinate:true,
              findingCrud: {
                ...prevState.findingCrud,
                data:{...findingObj, x_coordinate:data.hotspot.xPos, y_coordinate:data.hotspot.yPos, z_coordinate:data.hotspot.zPos}
              }
            }))
          }else{
            let findingArray = data.hotspot.title.toString().split('_') 
            if(this.props.id){
              if(this.props.id === findingArray[0]){
                this.getFindingFn(findingArray[0], findingArray[1])  
              }
            }else{
              // this.getFindingFn(findingArray[0], findingArray[1])
            }
          }          
          break;
        case 'hotspotMovement':
          this.setState(prevState => ({
            ...prevState,
            findingCrud: {
              ...prevState.findingCrud,
              data:{...prevState.findingCrud.data, x_coordinate:data.hotspotPositions.xPos, y_coordinate:data.hotspotPositions.yPos, z_coordinate:data.hotspotPositions.zPos}
            }
          }))
          break
        case 'screenshotData':
          break;
      }
    })
  }
  toggleModalFn = (data) => {
    this.setState({findingCrud: data})
  }
  getFindingFn = (id, sectionId) => {
    this.setState({pageLoader:true});
    this.getFindingApi(this.props, sectionId, id)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            modal:true,
            data:response.data.data,
            error:{},
            mode:'edit'
          }
        }));
        this.props.toggleFindingModal(true);
      }
    })
  }
  addFindingFn = () => {
    const { findingCrud } = this.state;
    this.setState({pageLoader:true});
    let payload = findingCrud.data;
    if(!payload.id){
      payload = {
        ...payload,
        step_id:this.props.stepId,
        step_type:this.props.stepType
      }
    }
    this.addEditFindingApi(this.props, payload, 'contentOnly')
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          initiateHotSpotCoOrdinate:false,
          findingCrud: {
            data:{},
            error:{},
            modal:false,
            mode:''
          }
        }));
        this.props.getFindingResponse(response, findingCrud.data.id ? 'edit':'add');
        this.props.toggleFindingModal(false);
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else if (response.data.statusCode === 100) {
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            error: response.data.error
          }
        }))
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  uploadFindingImgFn = (files) => {
    const { findingCrud } = this.state;
    this.setState({pageLoader:true});
    this.addEditFindingApi(this.props, {files:files, id: findingCrud.data.id}, 'fileOnly')
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            data:response.data.data
          }
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  changeFindingImageFn = (id, file) => {
    this.setState({pageLoader:true});
    this.changeFindingImageApi(this.props, id, file)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            data: {
              ...prevState.findingCrud.data,
              images: prevState.findingCrud.data.images.map(item => item.id === id ? response.data.data :item)
            }
          }
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  deleteFindingImageFn = () => {
    const { deleteIds } = this.state;
    this.setState({pageLoader:true});
    this.deleteFindingImageApi(this.props, deleteIds)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          findingCrud: {
            ...prevState.findingCrud,
            data: {
              ...prevState.findingCrud.data,
              images: prevState.findingCrud.data.images.filter(item => item.id !== deleteIds.id)
            }
          },
          deleteModal:false,
          deleteIds:{}
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  onFieldChange = (event, keyParam, data) => {
    if(keyParam === 'reject_file'){
      this.props.enqueueSnackbar(`${data.name} File format is not supported`, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.setState(prevState => ({
        ...prevState,
        findingCrud: {
          ...prevState.findingCrud,
          data:{
            ...prevState.findingCrud.data,
            [keyParam]:data
          }
        }
      }));
    }
  }
  updateSlider = (pictures) => {
    this.setState(prevState => ({
      ...prevState,
      sliderInfo: {
        ...prevState.sliderInfo,
        modal:true,
        images: pictures.map(item => {return {title:'', original:item.image, thumbnail:item.image}}),
        startIndex:0
      }
    }))
  }
  saveAnnotateImg = (file) => {
    const { imgAnnotation } = this.state;
    this.setState({pageLoader:true});
    this.annotateFindingImgApi(this.props, file, imgAnnotation)
    .then(response => {
      this.setState({pageLoader:false});
      if(checkApiStatus(response)){
        this.setState(prevState => ({
          ...prevState,
          imgAnnotation:{modal:false, data:{}},
          findingCrud: {
            ...prevState.findingCrud,
            data:{
              ...prevState.findingCrud.data,
              images: prevState.findingCrud.data.images.map(item => item.id === imgAnnotation.data.id ? response.data.data:item)
            }
          }
        }));
        this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }else{
        this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    })
  }
  updateErrorField = (key, message) => {
    this.setState((prevState)=> ({
      ...prevState,
      findingCrud: {
        ...prevState.findingCrud,
        error: {
          ...prevState.findingCrud.error,
          [key]: message
        }
      }
    }))
  }
  addNewHotSpot = () => {
    // Trigger the New or Custom HotSpot on 3D Model
    const messages = [{type: 'addNewHotspot'}];
    document.getElementById('fabrikIframe').contentWindow.postMessage(messages, this.props.fabrikUrl);
  }
  onConfirmAddHotspot = () => {
    this.setState(prevState => ({
      ...prevState,
      findingCrud:{
        ...prevState.findingCrud,
        modal:true,
        mode:'add'
      }
    }));
    this.props.toggleFindingModal(true);
  }
  onCancelAddHotspot =() => {
    this.setState(prevState => ({
      ...prevState,
      initiateHotSpotCoOrdinate:false,
      findingCrud:{
        ...prevState.findingCrud,
        modal:false,
        mode:'add'
      }
    }));
    this.props.toggleFindingModal(false);
    this.props.reset3DToInitial();
  }
  render(){
    const { pageLoader, formSubmitLoader, initiateHotSpotCoOrdinate, imgAnnotation, findingCrud, deleteModal, deleteIds, sliderInfo } = this.state;
    const { findingSections, id, sectionId, findingModal, reset3DToInitial } = this.props;
    return(
      <Fragment>
        { id ?
          <Tooltip title="Edit" arrow>
            <IconButton size="small" color="primary" onClick={() => this.getFindingFn(this.props.id, this.props.sectionId)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          :
          <Fragment>
            {!initiateHotSpotCoOrdinate && !findingCrud.modal ?
              <li className='list-inline-item'>
                <Button onClick={this.addNewHotSpot}  color="primary" variant='outlined' size="small">Add Finding</Button>
              </li>:null
            }
            <li className='list-inline-item'>
                    <Button onClick={this.onConfirmAddHotspot} color="secondary" variant="outlined" size="small">Confirm</Button>
                </li>
            { initiateHotSpotCoOrdinate && !findingModal ?
              <Fragment>
                <li className='list-inline-item'>
                    <Button onClick={this.onConfirmAddHotspot} color="secondary" variant="outlined" size="small">Confirm</Button>
                </li>
                <li className='list-inline-item'>
                    <Button onClick={this.onCancelAddHotspot} color="primary" variant="outlined" size="small">Cancel</Button>
                </li>
              </Fragment>:null
            }
            
          </Fragment>
        }
        { findingCrud.modal ?
          <Drawer
            variant="persistent"
            anchor="right"
            open={findingCrud.modal}
            onClose={() => this.toggleModalFn({modal:false, data:{}, error:{}, mode:''})}
            aria-labelledby="scroll-dialog-title"
          >
            <div className="add-edit-finding-drawer" style={{width:`${window.innerWidth/2-30}px`}}>
              <div className='drawer-head' id="scroll-dialog-title">
                <h4>{id ? 'Edit Finding':'Add Finding'}</h4>
              </div>
              <div style={{height:`${window.innerHeight-100}px`, overflow:'auto'}} className='drawer-body' dividers={true}>
                <form>
                  <Grid container spacing={1}>
                    {id ?
                      <Fragment>
                          <Grid item xs={12} md={12}>
                            <div>
                              <DropzoneArea
                                filesLimit={10}
                                maxFileSize={10000000}
                                showPreviewsInDropzone={false}
                                acceptedFiles={['image/png', 'image/jpeg', 'image/jpg']}
                                dropzoneClass="drag-drop-cnt small-blk"
                                onDrop={(files) => this.uploadFindingImgFn(files)}
                                showAlerts={['error', 'info']}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <ul className="list-inline" style={{textAlign:'left'}}>
                              { findingCrud.data.images && findingCrud.data.images.map((item) =>
                                <li className="list-inline-item pictorial-finding-list">
                                  <div className='pictorial-finding-card' style={{backgroundImage: "url(" + item.image + ")"}}>
                                    <div className="dropped-picture-cta">
                                      <span onClick={() => this.updateSlider(findingCrud.data.images)}>
                                        <VisibilityIcon color="primary" fontSize="small" />
                                      </span>
                                      <Fragment>
                                        <span onClick={() => this.setState({imgAnnotation:{modal:true, data:item}}) }>
                                          <EditIcon color="primary" fontSize="small" />
                                        </span>
                                        <span onClick={() => this.setState({deleteModal:true, deleteIds:{id:item.id}}) }>
                                          <DeleteOutlineIcon color="secondary" fontSize="small" />
                                        </span>
                                      </Fragment>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </ul>
                          </Grid>
                      </Fragment>
                      :
                      <Grid item xs={12} md={12}>
                        <DropzoneArea
                          maxFileSize={10000000}
                          dropzoneText="Drag & Drop Attachment or Click"
                          filesLimit={10}
                          acceptedFiles={['image/png', 'image/jpeg', 'image/jpg']}
                          previewGridProps={dropzonePreviewUI}
                          showPreviews={true}
                          showPreviewsInDropzone={false}
                          maxWidth='sm'
                          dropzoneClass="drag-drop-cnt small-blk"
                          showAlerts={['error', 'info']}
                          onChange={(files) => this.onFieldChange('', 'files', files)}
                        />
                      </Grid>
                    }
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={findingSections.list}
                        getOptionLabel={option => option.name}
                        id="section"
                        value={findingCrud.data.section ? findingCrud.data.section :null}
                        renderInput={params => <TextField required error={findingCrud.error.section ? true:false} helperText={findingCrud.error.section ? findingCrud.error.section:''} {...params} label="Aircraft Section" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                        onChange={(e, value) => {this.onFieldChange(e, 'section', value); this.updateErrorField('section', '')}}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={findingTypes}
                        getOptionLabel={option => option.label}
                        id="finding_type"
                        value={findingCrud.data.finding_type ? findingTypes.find(item => item.value === findingCrud.data.finding_type) :null}
                        renderInput={params => <TextField required error={findingCrud.error.finding_type ? true:false} helperText={findingCrud.error.finding_type ? findingCrud.error.finding_type:''} {...params} label="Damage Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                        onChange={(e, value) => {this.onFieldChange(e, 'finding_type', value ? value.value:value); this.updateErrorField('finding_type', '')}}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="damage_dimension"
                        label="Damage Dimension"
                        fullWidth
                        margin="normal"
                        error={findingCrud.error.damage_dimension ? true:false}
                        helperText={findingCrud.error.damage_dimension ? findingCrud.error.damage_dimension:''}
                        value={findingCrud.data.damage_dimension ? findingCrud.data.damage_dimension:''}
                        inputProps={{ maxLength: 30 }}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'damage_dimension', e.target.value)}
                        onFocus={(e) => this.updateErrorField('damage_dimension', '')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="damage_limit"
                        label="Damage Within Limit"
                        fullWidth
                        margin="normal"
                        error={findingCrud.error.damage_limit ? true:false}
                        helperText={findingCrud.error.damage_limit ? findingCrud.error.damage_limit:''}
                        value={findingCrud.data.damage_limit ? findingCrud.data.damage_limit:''}
                        inputProps={{ maxLength: 30 }}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'damage_limit', e.target.value)}
                        onFocus={(e) => this.updateErrorField('damage_limit', '')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="remarks"
                        label="Description"
                        fullWidth
                        margin="normal"
                        error={findingCrud.error.remarks ? true:false}
                        helperText={findingCrud.error.remarks ? findingCrud.error.remarks:''}
                        value={findingCrud.data.remarks ? findingCrud.data.remarks:''}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                        onFocus={(e) => this.updateErrorField('remarks', '')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={classifications}
                        getOptionLabel={option => option.label}
                        id="classifications"
                        value={findingCrud.data.classifications ? classifications.find(item => item.value === findingCrud.data.classifications) :null}
                        renderInput={params => <TextField {...params} label="Classification" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                        onChange={(e, value) => this.onFieldChange(e, 'classifications', value ? value.value:value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options = {category}
                        getOptionLabel={option => option.label}
                        id="category"
                        value={findingCrud.data.category ? category.find(item => item.value === findingCrud.data.category) :null}
                        renderInput={params => <TextField {...params} label="Category" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                        onChange={(e, value) => this.onFieldChange(e, 'category', value ? value.value:value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options = {repairType}
                        getOptionLabel={option => option.label}
                        id="repair_type"
                        value={findingCrud.data.repair_type ? repairType.find(item => item.value === findingCrud.data.repair_type) :null}
                        renderInput={params => <TextField {...params} label="Repair Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                        onChange={(e, value) => this.onFieldChange(e, 'repair_type', value ? value.value:value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="dd_wo_reference"
                        label="D&D WO Reference"
                        fullWidth
                        margin="normal"
                        error={findingCrud.error.dd_wo_reference ? true:false}
                        helperText={findingCrud.error.dd_wo_reference ? findingCrud.error.dd_wo_reference:''}
                        value={findingCrud.data.dd_wo_reference ? findingCrud.data.dd_wo_reference:''}
                        inputProps={{ maxLength: 30 }}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'dd_wo_reference', e.target.value)}
                        onFocus={(e) => this.updateErrorField('dd_wo_reference', '')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="arm_srm_reference"
                        label="AMM / SRM Reference"
                        fullWidth
                        margin="normal"
                        error={findingCrud.error.arm_srm_reference ? true:false}
                        helperText={findingCrud.error.arm_srm_reference ? findingCrud.error.arm_srm_reference:''}
                        value={findingCrud.data.arm_srm_reference ? findingCrud.data.arm_srm_reference:''}
                        inputProps={{ maxLength: 30 }}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'arm_srm_reference', e.target.value)}
                        onFocus={(e) => this.updateErrorField('arm_srm_reference', '')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="corrective_actions"
                        label="Corrective Action"
                        fullWidth
                        margin="normal"
                        error={findingCrud.error.corrective_actions ? true:false}
                        helperText={findingCrud.error.corrective_actions ? findingCrud.error.corrective_actions:''}
                        value={findingCrud.data.corrective_actions ? findingCrud.data.corrective_actions:''}
                        inputProps={{ maxLength: 30 }}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'corrective_actions', e.target.value)}
                        onFocus={(e) => this.updateErrorField('corrective_actions', '')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="intervals"
                        label="Threshold / Intervals"
                        fullWidth
                        margin="normal"
                        error={findingCrud.error.intervals ? true:false}
                        helperText={findingCrud.error.intervals ? findingCrud.error.intervals:''}
                        value={findingCrud.data.intervals ? findingCrud.data.intervals:''}
                        inputProps={{ maxLength: 30 }}
                        InputLabelProps={{shrink: true}}
                        onChange={(e) => this.onFieldChange(e, 'intervals', e.target.value)}
                        onFocus={(e) => this.updateErrorField('intervals', '')}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div style={{padding:'10px', border:'1px solid #d7d7d7', display:'none'}}>
                        {findingCrud.data.id ?
                          <Button size="small" variant="outlined" color="primary" onClick={() => this.props.editCoOrdinates(findingCrud.data.id, findingCrud.data.section.id)}>Change Axis</Button>:null
                        }
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              disabled={true}
                              id="x_pos"
                              label="X Pos"
                              fullWidth
                              margin="normal"
                              value={findingCrud.data.x_coordinate}
                              InputLabelProps={{shrink: true}}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              disabled={true}
                              id="y_pos"
                              label="Y Pos"
                              fullWidth
                              margin="normal"
                              value={findingCrud.data.y_coordinate}
                              InputLabelProps={{shrink: true}}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              disabled={true}
                              id="z_pos"
                              label="Z Pos"
                              fullWidth
                              margin="normal"
                              value={findingCrud.data.z_coordinate}
                              InputLabelProps={{shrink: true}}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div className='drawer-footer'>
                <ul className='list-inline'>
                  <li className='list-inline-item'>
                    <Button size="small" variant="contained" color="primary" onClick={this.addFindingFn}>Save</Button>
                  </li>
                  <li className='list-inline-item'>
                    <Button size="small" color="primary" onClick={() => {this.setState({initiateHotSpotCoOrdinate:false,findingCrud:{modal:false, data:{}, error:{}, mode:''}}); reset3DToInitial(); this.props.toggleFindingModal(false);}}>Cancel</Button>
                  </li>
                </ul>
              </div>
            </div>
          </Drawer>:null
        }
        <DeletePopUp
          modal={deleteModal}
          toggleModalFn={() => {this.setState({deleteModal:false, deleteIds:{id:null, pictures:[]}})}}
          title="Delete"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={this.deleteFindingImageFn}
        />
        { sliderInfo.modal ?
          <ImageSlider
            sliderInfo={sliderInfo}
            toggleModalFn={() => this.setState({sliderInfo:{modal:false, images:[], startIndex:''}})}
          />:null
        }
        { imgAnnotation.modal ?
          <EditImageDialog
            imgAnnotation={imgAnnotation}
            saveAnnotateImg={this.saveAnnotateImg}
            toggleModalFn={() => this.setState({imgAnnotation: {modal:false}})}
          />:null
        }
        { pageLoader ? <PageLoader /> : null }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddEditFinding));
