import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, UncontrolledTooltip ,Form,FormGroup} from 'reactstrap';
import { Creatable } from 'react-select';
import {  GetInputField } from'../../Elements';
import { imgStoragePath } from '../../../../constants';
import { checkString, checkValidDate, checkIntNumber,checkEmail, checkNumber, checkDateDiff } from '../../../../formValidator';
import PlacesAutocomplete from 'react-places-autocomplete';
import FilterTypeDropdown from '../../../../shared/TableFilter/components/FilterTypeDropdown';
import FilterList from '../../../../shared/TableFilter/components/FilterList';
import FilterTypes from '../../../../shared/TableFilter/components/FilterTypes';
import FilterLabel from '../../../../shared/TableFilter/components/FilterLabel';
import CKeditor from "../../../../shared/CKEditor";
import moment from 'moment';
import { uploadLesseeLogoAc } from '../../../../shared/actionCreators';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import { toastFlashMessage } from '../../../../utils';
import { FieldCol, FieldGroup, FieldLabel, FieldInput } from '../../Elements_V';
export default class AddEditProject extends Component{
  constructor(props){
    super(props);
    this.state = {
      project:{},
      inspectionType:[],
      projectEngineerDropdown:[],
      projectLeadDropdown:[],
      selectedInactiveEng:[],
      selectedInactiveLead:[],
      error: {},
      archiveErrorText:'',
      cancel_date: '',
      archive_reason: '',
      contactObj:{
        name: '',
        email:'',
        desigantion:'',
        phone_number: ''
      },
      confirmModal: false,
      projectExtendedFlag:false,
      projectExtendedValue:'',
      dropdownOpen: false,
      projectCloseFlag: false,
      projectStatusFlag: false,
      newLessee:false
    }
    this.toggle = this.toggle.bind(this);
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  };
  updateGeneralInstructions = (evt) =>{
    var newContent = evt.editor.getData();
    this.setState(prevState => ({
      ...prevState,
      project:{
        ...prevState.project,
        instructions:newContent
      },
      error: {
        ...prevState.error,
        instructions: ''
      }
    }));
  }
  equipmentRequiremets = (evt) =>{
    var newContent = evt.editor.getData();
    this.setState(prevState => ({
      ...prevState,
      project:{
        ...prevState.project,
        equipment_requirements:newContent
      },
      error: {
        ...prevState.error,
        equipment_requirements: ''
      }
    }));
  }
  miscComments = (evt) =>{
    var newContent = evt.editor.getData();
    this.setState(prevState => ({
      ...prevState,
      project:{
        ...prevState.project,
        miscellaneous_comments:newContent
      },
      error: {
        ...prevState.error,
        miscellaneous_comments: ''
      }
    }));
  }
  componentDidMount(){
    let data = Object.assign({}, this.props.project.data);
    data = {
      ...data,
      lessee_id : this.props.project.data.lessee && this.props.project.data.lessee.id ? this.props.project.data.lessee.id : this.props.project.type == 'edit' ? null : '',
      department: this.props.type != 'add' ? data.department && data.department.value ? data.department.value: '':''
    }
    delete data['lessee'];
    this.setState({
      project:data,
      projectExtendedValue:this.props.project.data.expected_end_date?this.props.project.data.expected_end_date:'',
      inspectionType:this.props.inspectionType
    });
    if(this.props.genInstruction && this.props.genInstruction != null && this.props.genInstruction != undefined && this.props.project.type == 'add'){
      this.setState(prevState =>({
        ...prevState,
        project:{
          ...prevState.project,
          miscellaneous_comments:this.props.genInstruction.miscellaneous_comments,
          equipment_requirements:this.props.genInstruction.equipment_requirements,
          instructions:this.props.genInstruction.project_general_instruction,
        },
      }))
    }
    let projectEngineerDropdown = this.props.projectEngineer.map(user =>  ({name: user.name, id:user.id}));
    let selectedInactiveEng = this.props.projectEngineerAll ? this.props.projectEngineerAll.filter(user =>  data.engineers.includes(user.id)).map(user => ({name: user.name, id:user.id, disabled:true})) : [];
    projectEngineerDropdown = [...projectEngineerDropdown, ...selectedInactiveEng];
    let projectLeadDropdown = this.props.projectLead.map(user =>  ({name: user.name, id:user.id}));
    let selectedInactiveLead = this.props.projectLeadAll ? this.props.projectLeadAll.filter(user =>  data.leads.includes(user.id)).map(user => ({name: user.name, id:user.id, disabled:true})) : [];
    projectLeadDropdown = [...projectLeadDropdown, ...selectedInactiveLead];
    this.setState({ projectEngineerDropdown, projectLeadDropdown, selectedInactiveEng, selectedInactiveLead })
  }
  updateJsonFormat = (key, value, type, index) => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        [type] :  [...prevState.project[type]].map((data, keyIndex) => keyIndex == index ? {
          ...data,
          [key] : value
        } : data )
      }
    }));
  }
  changeProjectStatus = (status) =>{
    if(status == 0){
      this.setState(prevState => ({
        ...prevState,
        projectStatusFlag: true,
        project:{
          ...prevState.project,
          archive_status: 1
        },
        error: {
          ...prevState.error,
          status: ''
        }
      }));
    }else{
      this.setState(prevState => ({
        ...prevState,
        projectStatusFlag: true,
        project:{
          ...prevState.project,
          status:status
        },
        error: {
          ...prevState.error,
          status: ''
        }
      }));
    }
  }

  toggleDelModal = () =>{
    this.setState({
      projectStatusFlag: false,
      archive_reason: ''
    })
  }

  toggleCloseModal = (flag) =>{
    if(this.state.cancel_date){

    }
    this.setState(prevState => ({
      ...prevState,
      projectCloseFlag:  !prevState.projectCloseFlag,
      cancel_date: ''
    }))
    // this.setState({
    //   projectCloseFlag: !this.state.projectCloseFlag,
    // })
  }
  removeAttachFiles = (name) => {
    let files = this.state.project.files;
    files = Array.from(files).filter( function(file){
        return file.name != name
    });
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        files: files
      }
    }))
  }
  updateForm = (key,value, data) => {
    const { selectedInactiveLead, selectedInactiveEng } = this.state
    if(key=='name'){
      this.filterProjectList(value);
    }
    if(key == "engineers"){
       if(selectedInactiveEng.filter(eng =>  value.includes(eng.id)).length !== selectedInactiveEng.length){
         let selectedInactiveUser = selectedInactiveEng.find(inactive => !value.includes(inactive.id))
         this.setState(prevState => ({
           ...prevState,
           projectEngineerDropdown: prevState.projectEngineerDropdown.filter(eng =>  eng.id != selectedInactiveUser.id )
         }))
       }
    }
    if(key == "leads"){
       if(selectedInactiveLead.filter(lead =>  value.includes(lead.id)).length !== selectedInactiveLead.length){
         let selectedInactiveUser = selectedInactiveLead.find(inactive => !value.includes(inactive.id))
         this.setState(prevState => ({
           ...prevState,
           projectLeadDropdown: prevState.projectLeadDropdown.filter(lead =>  lead.id != selectedInactiveUser.id )
         }))
       }
    }
      this.setState(prevState => ({
        ...prevState,
        project:{
          ...prevState.project,
          [key]:value
        },
        error: {
          ...prevState.error,
          [key]: ''
        }
      }));

  }
  updateLesseeForm = (value) => {
    if(value){
      if(typeof(value.id) == "string"){
        this.setState(prevState => ({
          ...prevState,
          project:{
            ...prevState.project,
            lessee_id:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            }
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          project:{
            ...prevState.project,
            lessee_id:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        project:{
          ...prevState.project,
          lessee_id:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      project:{
        ...prevState.project,
        lessee_id:{
          ...prevState.project.lessee_id,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          project:{
            ...prevState.project,
            lessee_id:{
              ...prevState.project.lessee_id,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }

 filterProjectList=(value)=>{
  let filterArray=[];
   if(value.length>2){
      filterArray= this.props.inspectionType.filter(item => ((item.name.toUpperCase()).includes(value.toUpperCase())));
      if(filterArray.length>0){
        this.setState({
           inspectionType:filterArray,
           dropdownOpen: true
        });
       }else{
         this.setState({
          inspectionType:this.props.inspectionType,
          dropdownOpen: false
       });
       }
   }else{
    this.setState({
     inspectionType:this.props.inspectionType,
     dropdownOpen: true
    });
   }
 }

  addContact = (type) => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        [type] : [ ...prevState.project[type], this.state.contactObj]
      }
    }));
  }
  deleteContact = (type, index) => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        [type] : [...prevState.project[type]].filter((data, key) => key != index)
      }
    }));
  }
  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        location : address
      },
      error: {
        ...prevState.error,
        location: ''
      }
    }));
  };

  handleSelect = address => {
    let location =  geocodeByAddress(address);
    location.then(results =>
      getLatLng(results[0]))
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          project: {
            ...prevState.project,
            location : address,
            lat: latLng['lat'],
            lang: latLng['lng'],
          }

      })))
      .catch(error => console.error('Error', error));
      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>   data['types'][0] == 'country'  );
          this.setState(prevState => ({
            ...prevState,
            project: {
              ...prevState.project,
                country: place.length ? place[0]['long_name'] : address
              }
            }))
           })
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
            project: {
              ...prevState.project,
                country_code: place.length ? place[0]['short_name']:address
              }
            }))
        }

        )
        .catch(error => console.error('Error', error));
  };
  addEditProject = (e) => {
    e.preventDefault();
    let validateNewInput = {
      name: checkString({value: this.state.project.name, required: true, minLength: '', maxLength: '', message: 'Please select Project Name'}),
      start_date: checkValidDate({value: this.state.project.start_date, required: true, minLength: '', maxLength: '', message: 'Please enter Start Date'}),
      expected_end_date: checkValidDate({value: this.state.project.expected_end_date, required: true, minLength: '', maxLength: '', message: 'Please enter Expected End date'}),
      location: checkString({value: this.state.project.location, required: true, minLength: '', maxLength: '', message: 'Please enter location'}),
      project_remarks: checkString({value: this.state.project.project_remarks, required: this.state.project.project_form === 1 ? true : false, minLength: '', maxLength: '', message: 'Please enter Project Remarks'}),
      leads: this.state.project.leads.length ? '' : 'Atleast one project lead is required',
      engineers: this.state.project.engineers.length ? '' : 'Atleast one project engineer is required',
    };
    if(this.props.projectNames.filter(item => item.label == this.state.project.name).length == 0){
      this.setState(prevState =>({
        ...prevState,
        error: {...prevState.error, name: 'Please select a project name from dropdown'}
      }))
      return;
    }
    let fileSize = 0

    if(this.state.project.files && typeof this.state.project.files != 'object'){
      for(let file of this.state.project.files){
        fileSize = fileSize + file.size
      }
    }
    if(fileSize > (15 * 1048576)){
      this.setState(prevState =>({
        ...prevState,
        error: {...prevState.error, files: 'Total attachment size allowed is 15MB'}
      }))
      return;
    }
    if(this.props.project.type == 'add'){
      setTimeout(() => {
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
          this.setState({confirmModal: true})
        }else{
          this.setState({
            error: validateNewInput
          });
        }
      }, 500)
    }else{
      this.props.addEditProject(this.state.project, this.props.project.type)
    }
  }
  addEditProjectFn = (project, type, data) => {
    if(this.state.project.archive_status == 1){
      if(data.archive_reason){
        this.props.addEditProject({...project, archive_reason:data.archive_reason}, type, data)
        this.props.toggleEditSideBar({type: 'add', flag: false, data: {}})
        setTimeout(() => this.toggleDelModal([]), 500);
      }else {
        this.setState({archiveErrorText:'Please enter Reason for Archive'})
      }
    }else{
      this.props.addEditProject(project, type, data)
      this.props.toggleEditSideBar({type: 'add', flag: false, data: {}})
      setTimeout(() => this.toggleDelModal([]), 500);
    }
  }
  closeProjectFn = (slug, data) => {
    if(data.cancel_date){
      if(moment(data.cancel_date) < moment(this.state.project.start_date)){
        this.setState({cancelErrortext:'Please enter Closure Date greater than Start date'})
      }else {
        this.props.closeProject(slug, data)
        this.setState({projectCloseFlag: false})
      }

    }else {
      this.setState({cancelErrortext:'Please enter Valid Date of Closure'})
    }
  }

  toggle(value) {
    this.setState(prevState => ({
      dropdownOpen: value
    }));
  }

  compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
    const genreA = a.label.toUpperCase();
    const genreB = b.label.toUpperCase();

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }


  render(){
    const { project, error, newLessee, projectEngineerDropdown, projectLeadDropdown} = this.state;
    const { type } = this.props.project;
    return(
      <div>
      <form className="project-crud-form" onSubmit={(e) =>  this.addEditProject(e)}>
        <div className="sidebar-form" style={{ height: (window.innerHeight - 70) + 'px'}}>
          <Row>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'dropdown',
                  <label className="for-label">Project Name<img width="15" style={{marginLeft:'5px',cursor:'pointer'}} onClick={()=>this.props.workOrderInfo()}src={imgStoragePath+"help_question.png"} alt="img"/></label>,
                  { type: type == 'add' && project.status && ![1,2].includes(project.status.id) ? 'add' : 'view',
                    value: project.name,
                    error: error.name,
                    options: this.props.type != 'add' && project.show_new_project_name === 0 ? this.props.projectNames.filter(item => item.remarks !== 'new_name').sort(this.compare) : this.props.projectNames.sort(this.compare),
                    labelKey: 'label',
                    valueKey: 'label',
                    updateForm: (value) => this.updateForm('name', value)
                  }
              )
            }
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'textarea',
                  <label className="for-label">Project Remarks {type != 'view' && project.project_form === 1 ?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: project.project_remarks,
                    error: error.project_remarks,
                    updateForm: (value) => this.updateForm('project_remarks', value)
                  }
              )
            }
            </Col>
            <Col md="12" style={{marginBottom: '20px',cursor:'pointer'}}>
              <label className="for-label" style={{opacity: '1.0'}}>
                <input disabled={type == 'add' && project.status && ![1,2].includes(project.status.id) ? false : true}  type="checkbox"  value={project.project_form} checked={project.project_form === 1 ? true : false}
                  onChange = {(e) => this.updateForm('project_form', e.target.checked ? 1 : 0)}
                /> Miscellaneous Services
              </label>
              <span className="warning-info-text">Selecting this box means that the Project does not include any specific asset for inspection. Only T012 - Miscellaneous Services project report form will be assigned to Project Engineer</span>
            </Col>
            <Col md="12" style={{marginBottom: '20px'}}>
            <div>
              <div class="form-group-edit">
                <label className="for-label">Project Attachment <img id="attachmentToolTip" src={imgStoragePath+'phase-2/info_grey.png'} style={{ position: 'relative', width: '12px' }} /></label>
                <UncontrolledTooltip className="text-left" placement="right" target="attachmentToolTip">
                    <div style={{ textAlign: "left" }}>
                        <p>
                          Attach sample reports or other pertinent information for guidance to engineer. PDF, MS Word, MS Excel files preferred.
                        </p>
                    </div>
                </UncontrolledTooltip>
                {
                  type != 'edit' ?
                  <div>
                    <label className="btn btn-primary" style={{backgroundColor:'#3f51b5'}}>
                      Attach File
                      <input
                        id="contained-button-file"
                        style={{opacity:'0', width: '0'}}
                        multiple={true}
                        type="file"
                        onChange={(evt) => this.updateForm('files', evt.target.files)}
                      />
                    </label>
                    <h6 className="error-msg">{error.files}</h6>
                    {(() => {
                      let files = [];
                      if(project.files){
                        for (let i = 0; i < project.files.length; i++) {
                            files.push(project.files[i].name)
                        }
                      }

                      if(files.length){
                        return(
                          files.map((file, index) => <p>{file} <span onClick={()=>this.removeAttachFiles(file)} style={{cursor:'pointer', color:'#ff0000', paddingLeft: '5px'}}><i className="fa fa-trash"></i></span></p>)
                        )
                      }
                    })()}

                  </div>
                  :
                  <ul className="list-inline">
                    {
                      project.files && Object.keys(project.files).length ?
                        Object.keys(project.files).map(key =>
                            <li className="list-inline-item">
                              <a href={project.files[key]} target="_blank">{key}</a>
                            </li>
                        )
                      :null
                    }
                  </ul>
                }
                {
                  // project.files ?
                  // [...project.files].forEach(file => <p>{file.name}</p>):null
                }
              </div>
              <h6 class="error-msg"></h6>
            </div>
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Start Date {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: project.start_date,
                    error: error.start_date,
                    updateForm: (value) => this.updateForm('start_date', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                project.start_date !== null && project.start_date !== undefined && project.start_date !== '' ?
                GetInputField(
                  'date',
                  <label className="for-label">Expected End Date{type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    error: error.expected_end_date,
                    value: project.expected_end_date,
                    minDate: moment(project.start_date),
                    updateForm: (value) => this.updateForm('expected_end_date', value)
                  }
              ):null}
            </Col>
            {project.remarks?
            <Col md="6" style={{marginBottom: '20px'}}>
              { GetInputField(
                  'string',
                  <label className="for-label">Project Extended Reason</label>,
                  { type: type,
                    value: project.remarks,
                    error: error.remarks,
                    updateForm: (value) => this.updateForm('remarks', value)
                  }
              )}
            </Col>:project.expected_end_date && this.state.projectExtendedValue &&
            moment(project.expected_end_date).diff(moment(this.state.projectExtendedValue)) > 0 ?

            <Col md="6" style={{marginBottom: '20px'}}>
            { GetInputField(
                'string',
                <label className="for-label">Project Extended Reason</label>,
                { type: type,
                  value: project.remarks,
                  error: error.remarks,
                  updateForm: (value) => this.updateForm('remarks', value)
                }
            )}
          </Col>:null
            }
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label">Project Lead{type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: project.leads,
                    error: error.leads,
                    options: projectLeadDropdown,
                    labelKey: 'name',
                    valueKey: 'id',
                    updateForm: (value) => this.updateForm('leads', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">ZOHO Enquiry ID <img id="operatorToolTip" src={imgStoragePath+'phase-2/info_grey.png'} style={{ position: 'relative', width: '12px' }} /></label>,
                  { type: type,
                    value: project.zoho_opportunity_id,
                    error: error.zoho_opportunity_id,
                    updateForm: (value) => this.updateForm('zoho_opportunity_id', value)
                  }
              )}
              <UncontrolledTooltip className="text-left" placement="right" target="operatorToolTip">
                  <div style={{ textAlign: "left" }}>
                      <p>
                        SPARTA is integrated with Enterprise CRM (For eg. ZOHO CRM for Acumen). Enter CRM Enquiry ID to transfer Project details to CRM. Contact digitalbusiness@acumen.aero for details.
                      </p>
                  </div>
              </UncontrolledTooltip>
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'dropdown',
                  <label className="for-label">Project Departments</label>,
                  { type: type,
                    value: project.department,
                    error: error.department,
                    options: this.props.projectDepartment,
                    labelKey: 'label',
                    valueKey: 'value',
                    updateForm: (value) => this.updateForm('department', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label">Project Engineers{type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: project.engineers,
                    error: error.engineers,
                    options:projectEngineerDropdown,
                    labelKey: 'name',
                    valueKey: 'id',
                    updateForm: (value) => this.updateForm('engineers', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
               <label className="for-label">Location {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>
               {
                 window.google !== undefined && window.google !== null ?
                 <PlacesAutocomplete
                      value={project.location}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                    >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{position: 'relative'}}>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Location',
                            className: 'location-search-input',
                          })}
                        />
                        { project.location ?
                          <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div> :  null
                        }
                      </div>
                    )}
                  </PlacesAutocomplete>
                 :GetInputField(
                     'string',
                     "",
                     { type: type,
                       value: project.location,
                       error: error.location,
                       updateForm: (value) => this.updateForm('location', value)
                     }
                 )
               }
                <h6 className="error-msg" >{error.location}</h6>
            </Col>
            <div style={newLessee ? {width: '100%', background: '#f3f3f3', margin: '45px', paddingTop: '20px'} : {width: '100%'}}>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Lessee
                </FieldLabel>
                <Creatable
                  value={project.lessee_id}
                  options={this.props.sLesseeList}
                  isClearable={true}
                  onChange={this.updateLesseeForm}
                  className="custom-select-block"
                  placeholder='Add or Select Lessee'
                  valueKey="id"
                  labelKey="name"
                  type={type}
                />
                 <h6 className="error-msg">{error.lessee_id}</h6>
              </FieldGroup>
            </FieldCol>
            {
              newLessee ?
              <Fragment>
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Contact Address
                    </FieldLabel>
                    <FieldInput
                      value={project.lessee_id ? project.lessee_id.contact_address:'' }
                      type={type}
                      keyParam="contact_address"
                      updateField={this.updateNewLessee}
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Country
                    </FieldLabel>
                    <FieldInput
                      value={project.lessee_id ? project.lessee_id.country:'' }
                      type={type}
                      keyParam="country"
                      updateField={this.updateNewLessee}
                    />
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                      Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                      <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                    </FieldLabel>
                  </FieldGroup>
                </FieldCol>
              </Fragment> : null
            }
          </div>
            <Col md="6" style={{marginBottom: '20px'}}>
              { GetInputField(
                  'string',
                  <label className="for-label">Third Party</label>,
                  { type: type,
                    value: project.third_party,
                    error: error.third_party,
                    updateForm: (value) => this.updateForm('third_party', value)
                  }
              )}
            </Col>
            <Col md="12" style={{marginBottom: '20px',cursor:'pointer'}}>
              <label className="for-label" style={{opacity: '1.0'}}>
                <input  type="checkbox"  value={project.cor_required} checked={project.cor_required}
                  onChange = {(e) => this.updateForm('cor_required', e.target.checked)}
                /> COR (Confidential Operator Report) Required
              </label>
            </Col>
            <Col className="add-contacts" md="12" style={{marginBottom: '20px'}}>
              <h4>Client Contact <Link onClick = {() => this.addContact('client_contacts')}>+ Add Contact</Link></h4>
              <table className="normal-table-layout">
                <thead>
                  <tr>
                    {['Name', 'Email', 'Designation','Phone', ''].map((label, index) => <th key={index}>{label}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(project).length ?
                    project.client_contacts.map((data, index) => {
                      return(
                        <tr>
                          <td>
                            <input type="text" value={data.name} onChange= {(e) => this.updateJsonFormat('name',e.target.value, 'client_contacts', index)} />
                          </td>
                          <td style={{width: '300px'}}>
                            <input type="text" value={data.email} onChange= {(e) => this.updateJsonFormat('email',e.target.value, 'client_contacts', index)}/>
                          </td>
                          <td>
                            <input type="text" value={data.designation} onChange= {(e) => this.updateJsonFormat('designation',e.target.value, 'client_contacts', index)} />
                          </td>
                          <td >
                            <input type="text" value={data.phone_number} onChange= {(e) => this.updateJsonFormat('phone_number',e.target.value, 'client_contacts', index)} />
                          </td>
                          <td style={{width: '45px'}}>
                         {
                          index != 0 ?
                          <img  onClick={() => this.deleteContact('client_contacts', index)} style={{width: '12px', cursor:'pointer'}} src={imgStoragePath + "blue_delete_icon.png"} alt="" />
                          : null
                          }
                        </td>
                        </tr>
                      )}):null
                  }
                </tbody>
              </table>
            </Col>
            <Col className="add-contacts" md="12" style={{marginBottom: '20px'}}>
              <h4>Lessee Contact<Link onClick = {() => this.addContact('operator_contacts')}>+ Add Contact</Link></h4>
              <table className="normal-table-layout">
                <thead>
                  <tr>
                    {['Name', 'Email', 'Designation','Phone', ''].map((label, index) => <th key={index}>{label}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(project).length ?
                    project.operator_contacts.map((data, index) => {
                      return(
                        <tr>
                          <td>
                            <input type="text" value={data.name}  onChange= {(e) => this.updateJsonFormat('name',e.target.value, 'operator_contacts', index)} />
                          </td>
                          <td style={{width: '300px'}}>
                            <input type="text" value={data.email}  onChange= {(e) => this.updateJsonFormat('email',e.target.value, 'operator_contacts', index)} />
                          </td>
                          <td>
                            <input type="text" value={data.designation}  onChange= {(e) => this.updateJsonFormat('designation',e.target.value, 'operator_contacts', index)} />
                          </td>
                          <td >
                            <input type="text" value={data.phone_number} onChange= {(e) => this.updateJsonFormat('phone_number',e.target.value, 'operator_contacts', index)} />
                          </td>
                          <td style={{width: '45px'}}>
                          {
                            index != 0 ?
                            <img  onClick={() => this.deleteContact('operator_contacts', index)} style={{width: '12px', cursor:'pointer'}} src={imgStoragePath + "blue_delete_icon.png"} alt="" />
                            : null }
                          </td>
                        </tr>
                      )}):null
                  }
                </tbody>
              </table>
            </Col>
            <Col className="add-contacts" md="12" style={{marginBottom: '20px'}}>
              <h4>Third Party Contact <Link onClick = {() => this.addContact('third_party_contacts')}>+ Add Contact</Link></h4>
              <table className="normal-table-layout">
                <thead>
                  <tr>
                    {['Name', 'Email', 'Designation','Phone', ''].map((label, index) => <th key={index}>{label}</th>)}
                  </tr>
                </thead>
                <tbody>
                { Object.keys(project).length ?
                  project.third_party_contacts.map((data, index) => {
                    return(
                      <tr>
                        <td>
                          <input type="text" value={data.name} onChange= {(e) => this.updateJsonFormat('name',e.target.value, 'third_party_contacts', index)}/>
                        </td>
                        <td style={{width: '300px'}}>
                          <input type="text" value={data.email} onChange= {(e) => this.updateJsonFormat('email',e.target.value, 'third_party_contacts', index)}/>
                        </td>
                        <td>
                          <input type="text" value={data.designation} onChange= {(e) => this.updateJsonFormat('designation',e.target.value, 'third_party_contacts', index)}/>
                        </td>
                        <td>
                            <input type="text" value={data.phone_number} onChange= {(e) => this.updateJsonFormat('phone_number',e.target.value, 'third_party_contacts', index)}/>
                        </td>
                        <td style={{width: '45px'}}>
                           {
                            index != 0 ?
                            <img  onClick={() => this.deleteContact('third_party_contacts', index)} style={{width: '12px', cursor:'pointer'}} src={imgStoragePath + "blue_delete_icon.png"} alt="" />
                            : null
                            }
                        </td>
                    </tr>
                  )}):null
                }
                </tbody>
              </table>
            </Col>
            <Col className="add-ck-editor" md="12" style={{marginBottom: '20px'}}>
              <h4>General Instruction</h4>
              <CKeditor
                events={{"change": this.updateGeneralInstructions}}
                content={this.state.project.instructions}
              />
            </Col>
            <Col className="add-ck-editor" md="12" style={{marginBottom: '20px'}}>
              <h4>Miscellaneous comments</h4>
              <CKeditor
                events={{"change": this.miscComments}}
                content={this.state.project.miscellaneous_comments}
              />
            </Col>
            <Col className="add-ck-editor" md="12" style={{marginBottom: '20px'}}>
              <h4>Equipment requirement</h4>
              <CKeditor
                events={{"change": this.equipmentRequiremets}}
                content={this.state.project.equipment_requirements}
              />
            </Col>
          </Row>

         <Row className="delete-archive-project">
         <Col md="12" style={{marginBottom: '20px'}}>
            {
                type == 'edit' ?
                  <Link style={{color: 'red', fontSize: '14px'}} onClick={() => this.toggleCloseModal()}>{this.props.projectLoader ? 'Processing...':'Close Project'}</Link>
                :null
              }
           </Col>
           <Col md="12" style={{marginBottom: '20px'}}>
            {
                type == 'edit' ?
                  <Link style={{color: 'red', fontSize: '14px'}} onClick={() => this.changeProjectStatus(0)}>{this.props.projectLoader ? 'Processing...':'Archive Project'}</Link>
                :null
              }
           </Col>
           <Col md="12" style={{marginBottom: '20px', fontSize: '14px'}}>
             {
                type == 'edit' ?
                  <Link style={{color: 'red'}} onClick={() => this.changeProjectStatus(8)}>{this.props.projectLoader ? 'Processing...':'Cancel Project'}</Link>
                :null
              }
           </Col>
         </Row>
        </div>


        { type != 'view' ?
          <div className="edit-sidebar-footer">
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.props.projectLoader} type="submit" className="btn btn-primary">{this.props.projectLoader ? 'Processing...':'Save'}</button>
              </li>


              <li className="list-inline-item">
                <Link onClick={() => this.props.toggleEditSideBar({type: 'add', flag: false, data: {}})}> Cancel</Link>
              </li>
             </ul>
          </div>:null
        }
      </form>
      <Modal isOpen={this.state.projectStatusFlag} toggle={() => this.toggleDelModal([])} className="modal-dialog-centered modal-tform-delete-pictures archive-project-modal">
        <ModalHeader toggle={() => this.toggleDelModal([])}>
          {
            this.state.project.archive_status == 1 ? 'Do you want to archive this project?' : 'Do you want to cancel this project?'
          }
        </ModalHeader>
        <ModalBody>
          { this.state.project.archive_status === 1 ? GetInputField(
              'string',
              <label className="for-label">Reason For Archive</label>,
              { type: type,
                value: this.state.archive_reason,
                error: this.state.archive_reason === '' ? this.state.archiveErrorText :'',
                length:255,
                updateForm: (value) => this.setState({archive_reason: value})
              }
          ):null}
                <Col md="6" style={{marginBottom: '20px'}}>
              { GetInputField(
                  'textarea',
                  <label>Reason for Cancellation</label>,
                  { type: type,
                    value: project.cancellation_reason,
                    updateForm: (value) => this.updateForm('cancellation_reason', value)
                  }
              )}
            </Col>
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => {this.addEditProjectFn(this.state.project, this.props.project.type, {archive_reason: this.state.archive_reason});}}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal([])}>No</button>
              </li>
          </ul>
        </ModalBody>
      </Modal>
      <Modal isOpen={this.state.projectCloseFlag} toggle={() => this.toggleCloseModal([])} className="modal-dialog-centered modal-tform-delete-pictures close-project-dialog">
        <ModalHeader toggle={() => this.toggleCloseModal([])}>
          {
           'Do you want to close this project?'
          }
        </ModalHeader>
        <ModalBody style={{height: '400px'}}>
          {
            GetInputField(
              'date',
              <label className="for-label">Date Of Closure <span className="reqStar">*</span></label>,
              { type: type,
                value: this.state.cancel_date,
                error:this.state.cancelErrortext,
                minDate:project.start_date,
                updateForm: (value) => {this.setState({cancel_date: value});this.setState({cancelErrortext:''})}
              }
          )}
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" disabled={this.state.cancel_date == ''? true : false} onClick={() => {this.closeProjectFn(project.slug, {cancel_date: this.state.cancel_date});setTimeout(() => this.state.cancel == '' ?  this.toggleCloseModal([]): null, 500)}}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => this.toggleCloseModal([])}>No</button>
              </li>
          </ul>
        </ModalBody>
      </Modal>
      <Modal isOpen={this.state.confirmModal} toggle={() => this.setState({confirmModal: false})} className="modal-dialog-centered modal-tform-delete-pictures">
        <ModalHeader toggle={() => this.setState({confirmModal: false})}>
          Create Project
        </ModalHeader>
        <ModalBody>
          <div>
          {
           'Are you sure you want to create project with the available General Instructions and Miscellaneous comments?'
          }
          </div>
          <ul className="list-inline">
              <li className="list-inline-item">
                <button type="button" className="btn btn-danger" onClick={() => {this.props.addEditProject(this.state.project, this.props.project.type);this.setState({confirmModal: false})}}>Yes</button>
              </li>
              <li className="list-inline-item">
                <button type="button" className="btn btn-primary" onClick={() => {this.setState({confirmModal: false})}}>No</button>
              </li>
          </ul>
        </ModalBody>
      </Modal>

    </div>
    )
  }
}
