import React, { useEffect, useState } from 'react';
import { withRouter, Link, browserHistory } from 'react-router';
import { FormSkeletonLoader, FormWrapper } from '../../Elements';
import { ExportManu, PageLoader, STableLoader } from '../../../shared_elements';
import { trackActivity } from '../../../utils/mixpanel';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils_v2';
import moment from 'moment';
import { globalGetService, globalPostService, globalStaticExportService } from '../../../globalServices';
import { useSnackbar } from 'notistack';
import { Paper } from '@material-ui/core';
import Navs from './Navs';
import { Skeleton } from '@material-ui/lab';
import InvalidUrl from '../../../shared_elements/components/InvalidUrl';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
const T014Nav = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [currentFormDetail, setCurrentFormDetail] = useState(null);
    const [formLoader, setFormLoader] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoad, setSkeletonLoad] = useState(false);

    useEffect(() => {
        getCurrentFormDetail('formLoader','skLoad');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'T014 Form'
        });
    }, []);
    const getCurrentFormDetail = (loaderType, skLoad) => {
        if (loaderType) { setFormLoader(true) }
        if (skLoad=='skLoad') { setSkeletonLoad(true) }
        globalGetService(`technical/${params.type === 'engine' ? 'engine-workorder' : 'workorder'}/${params.workOrderSlug}/forms/?asset_type=${params.type}`, { form: params.formSlug, fields: 'id,name,project_number,start_date,expected_end_date,slug' })
            .then(response => {
                if (loaderType) { setFormLoader(false) }
                if (skLoad=='skLoad') { setSkeletonLoad(false) }
                if (checkApiStatus(response)) {
                    setCurrentFormDetail(response.data.data)
                }
            })
    }
    const exportFormReport = (file) => {
        enqueueSnackbar('T014 Report Downloading ...', { variant: 'info', anchorOrigin: { horizontal: 'right', vertical: 'bottom' } })
        globalStaticExportService(`technical/workorder/${params.workOrderSlug}/t014/export?type=T014&asset_type=${params.type}`)
            .then(response => {
                downloadFileType(response, (`T014_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), file.extension);
                closeSnackbar();
                trackActivity('Project Management', {
                    event_type: 'File Exported',
                    page_title: 'T014 Form',
                    project_slug: params.project_slug ? params.project_slug : '',
                    file_name: `T014_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`,
                    file_extension: file.extension,
                    success_msg: 'T014 Report Downloaded'
                })
            });
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/?asset_type=${params.type}`, data)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    getCurrentFormDetail('formLoader');
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    trackActivity('Project Management', {
                        event_type: 'Form Status Updated',
                        page_title: 'T014 Form',
                        form_slug: params.formSlug ? params.formSlug : '',
                        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                        status: data.status ? data.status : '',
                        request_body: data ? data : '',
                        success_msg: response.data.message
                    });
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    trackActivity('Project Management', {
                        event_type: 'Form Status Update Failed',
                        page_title: 'T014 Form',
                        form_slug: params.formSlug ? params.formSlug : '',
                        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                        status: data.status ? data.status : '',
                        request_body: data ? data : '',
                        error_source: 'Backend',
                        error_msg: response.data.message
                    });
                }
            });
    }
    let formViewPermission = currentFormDetail?.list?.length && currentFormDetail?.list?.find(form => form.slug == currentFormDetail?.current_form?.slug)
    let formType = window.location?.pathname?.toUpperCase()?.includes(currentFormDetail?.current_form?.name)
    return (
        <section className="asset-tech-specs">
            {currentFormDetail && Object.keys(currentFormDetail).length && formType && formViewPermission?
                <FormWrapper
                    hideAction={currentFormDetail?.current_form.progress === 0 ? true : false}
                    formInfo={currentFormDetail}
                    exportReportBtn={<ExportManu exportReportFn={(file) => exportFormReport(file)} disabled={currentFormDetail?.current_form.progress === 0 ? true : false} title="Export Report" files={[{ title: 'Excel', extension: 'xls', key: '' }]} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                /> : formLoader ? <FormSkeletonLoader /> : null
            }
            { isLoading || formLoader? null :
                formType ?formViewPermission ?
                <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 125}px`, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Navs params={params} currentFormDetail={currentFormDetail} getCurrentFormDetail={getCurrentFormDetail} />
            </Paper>
             : 
             <RestrictedAccess/>:
             <InvalidUrl getResponseBack={() => browserHistory.push(`/technical/project/view/${currentFormDetail?.project?.slug}`)} />
             }
            {isLoading ? <PageLoader /> : null}
            {skeletonLoad ? <STableLoader count={10} /> : null}
            
        </section>
    )
}
export default T014Nav;