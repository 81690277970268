import React, { Component } from 'react';
import { Link } from 'react-router';
import {  ReviewCollapse } from '../../../Elements';
export default class AircraftDocsAndReview extends Component {
  constructor(props){
    super(props);
    this.state = {
      recordReviews: {}
    }
  }
  componentDidMount(){
    let dataObj = Object.assign({}, this.props.recordReviews);
    this.setState({
			recordReviews: dataObj,
		})
	}
  updateIntroduction = (e) =>  {
   e.preventDefault();
   this.props.updateIntroduction(this.props.recordReviews)
  }
	render(){
    let { recordReviews, formInfo } = this.props;
    if(!Object.keys(recordReviews).length){
      return false
    }
		return(
			<div>
        <form onSubmit={(e) => this.updateIntroduction(e)}>
          <div className="sidebar-form review-editor" style={{ height: '100vh'}}>
            { Object.keys(recordReviews.answer).length && recordReviews.answer.value && Object.keys(recordReviews.answer.value).length ?
              recordReviews.answer.value.map((review, index) =>
                <ReviewCollapse
                  updateReviewCollapse={this.props.updateReviewCollapse}
                  type={recordReviews.id}
                  removeReviewCollapse={() => this.props.removeReviewCollapse(recordReviews.id, index)}
                  toggleReviewCollapse = {() => this.props.toggleReviewCollapse(recordReviews.id, index)}
                  updateCkEditer = {this.props.updateCkEditer}
                  review={review}
                  key={index}
                  index= {index}
                  formInfo={formInfo}
                />
              ) : null
            }
          </div>
          <div className="edit-sidebar-footer">
            <ul className="list-inline">
              { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <li className="list-inline-item">
                  <input className="primary-btn" type="submit" value="Save" />
                </li>:null
              }
              { formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ?
                <li className="list-inline-item">
                  <Link onClick={() => this.props.addReviewCollapse(recordReviews.id, {title: '', content: '', flag: 0})}> Add New Section</Link>
                </li>:null
              }
              <li className="list-inline-item">
                <Link onClick={this.props.toggleEditSideBar} > Cancel</Link>
              </li>
            </ul>
          </div>
          </form>
		</div>
		)
	}
}
