import React, { Fragment } from 'react';
import moment from 'moment';
import { browserHistory } from 'react-router';
import { TableRow, TableCell } from '@material-ui/core';
import { getLocalStorageInfo } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
export default function ProjectAnalyticList({ item, index}) {
  return (
    <TableRow hover tabIndex={-1} style={{cursor:'pointer'}} onClick={() => getLocalStorageInfo().defaultLessor?.id === 13 || getLocalStorageInfo().defaultLessor?.id === 90  ? null : browserHistory.push('/technical/project/view/' + item.slug)}>
      <TableCell >{index}</TableCell>
      <TableCell > {item.project_number ? item.project_number : '--'}</TableCell>
      <TableCell style={{ minWidth: '200px' }} > {item.name ? item.name : '--'}</TableCell>
      <TableCell style={{ minWidth: '200px' }} > {item.department&& item.department.label ? item.department.label : '--'}</TableCell>
      <TableCell style={{ minWidth: '150px' }} >
        {item.project_status ?
          <Fragment>
            <span style={{ padding: '5px 10px', background: item.project_status === 'Completed' ? '#5d3cdb' : item.project_status === 'Ongoing' ? '#df8c0c' : '#2eaeb5', display: 'inline-block', color: '#ffffff', borderRadius: '4px' }}>
              {item.project_status}
            </span> <br />
            {
              item.project_status == "Completed" ?
                <span style={{ fontSize: '11px' }}>{item.end_date ? `On ${moment(item.end_date).format(displayDateFormatShort)}` : ''}</span> : null
            }
            <span></span>
          </Fragment>
          :
          '--'
        }
      </TableCell>
      <TableCell style={{ minWidth: '150px' }} >
        {item.leads && item.leads.length ?
          <ul style={{ listStyle: 'none' }}>
            {item.leads.map(lead => <li><p className='proj-name-card' >{lead.name}</p></li>)}
          </ul> : '--'
        }
      </TableCell>
      <TableCell style={{ minWidth: '150px' }} >
        {item.engineers && item.engineers.length ?
          <ul style={{ listStyle: 'none' }}>
            {item.engineers.map(engineer => <li><p className='proj-name-card' >{engineer.name}</p></li>)}
          </ul> : '--'
        }
      </TableCell>
      <TableCell onClick={() => {browserHistory.push('/technical/project/view/' + item.slug)}}>
        {item.asset_details ? item.asset_details.assets_count : '--'}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{item.lessor ? item.lessor : '--'}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{item.lessee ? item.lessee : '--'} </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{item.start_date ? moment(item.start_date).format(displayDateFormatShort) : 'TBD'}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{item.expected_end_date ? moment(item.expected_end_date).format(displayDateFormatShort) : 'TBD'}</TableCell>
      <TableCell style={{ minWidth: '200px' }}>{item.location ? item.location : '--'}</TableCell>
    </TableRow>
  )
}
