import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { browserHistory } from 'react-router';
import { Paper, Grid, Button, Dialog } from "@material-ui/core";
import { TaskManagementWrapper, TaskList, AddTask } from "../components";
import { TableListComp, FilterUIComp, ExportManu, EmptyCollection, PageLoader, DeletePopUp } from "../../../shared_elements";
import { STableLoader } from "../../../shared_elements/loaders";
import { taskHd, tasksFilterOps } from "..";
import { globalDeleteService, globalExportService, globalGetService, globalPostService, globalPutService } from "../../../utils_v2/globalApiServices";
import { checkApiStatus, onCheckPermission, downloadFileType, getLocalStorageInfo, removeEmptyKey } from "../../../utils_v2";
import moment, { updateLocale } from "moment";
import FilterComponent from '../../../shared_elements/filter_component';
import { trackActivity } from '../../../utils/mixpanel';
import { getProjectWorkOrdersApi, getWorkOrderFormsApi, getAllStagesApi, getAllMilestoneApi, getPlatformConstantsApi, getAllFormsApi } from "../apiService";
import RestrictedAccess from "../../../shared_elements/components/RestrictedAccess";
import ImportTask from "../components/ImportTask";
import {castleAirInstance} from '../../../shared_elements/components'
const queryString = require('query-string');
const Tasks = ({params, location}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [stages, setStages] = useState([]);
  const [milestones, setMilestone] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [forms, setForms] = useState([]);
  const [pltConstants, setPltConstants] = useState([]);
  const [taskUsers, setTaskUsers] = useState([]);
  const [deleteTask, setDeleteTask] = useState({modal:false, task:null})
  const [addEditTask, setAddEditTask] = useState({modal:false, task:null})
  const [sort, setSort] = useState("")
  const [sort_by, setSortBy] = useState("")
  const [filter, setFilter] = useState({})
  const [noAcess, setNoAcess] = useState(false)
  const [tasksInfo, setTasksInfo] = useState({ pagination: {}, list: [], projects_count: [], })
  useEffect(() => {
    getTasksData({ per_page: 10, ...location.query}, "skeletonLoader")
    getTaskUsers({role:'task_users'});
    getProjectWorkOrders();
    getAllStages();
    getAllMilestone();
    getPlatformConstants();
    getAllForms();
    getProjectDetails();
    trackActivity('Task Management', {
      event_type: 'Page Visited',
      page_title: 'Tasks',
    });
  }, []);

  const getTasksData = (query = {}, loaderType) => {
    if(loaderType === 'skeletonLoader'){
      setSkeletonLoader(true)
    }else{
      setLoading(true)
    }
    globalGetService( `technical/project/${params.project_slug}/tasks/`, query ).
    then((response) => {
      if(loaderType === 'skeletonLoader'){
        setSkeletonLoader(false)
      }else{
        setLoading(false)
      }
      if (checkApiStatus(response)) {
        delete query.sort
        delete query.sort_by
        delete query.page
        delete query.per_page
        setTasksInfo(response.data.data)
        setFilter(query)
      }
    })
  }
  const getTaskUsers = (query) => {
    globalGetService(`technical/users/`, {...query,project_slug:params.project_slug})
    .then(response => {
      if(checkApiStatus(response)){
        setTaskUsers(response.data.data)
      }
    });
  }
  const getProjectWorkOrders = () => {
    getProjectWorkOrdersApi(params).then((response) => {
      if (checkApiStatus(response)) {
        setWorkOrders(response.data.data)
      }
    })
  }

  const getAllStages = () => {
    getAllStagesApi(params, {view:'all', fields:'id,name'}).then((response) => {
      if (checkApiStatus(response)) {
        setStages(response.data.data)
      }
    })
  }

  const getAllForms = () => {
    getAllFormsApi().then((response) => {
      if (checkApiStatus(response)) {
        setForms(response.data.data)
      }
    })
  }

  const getPlatformConstants = () => {
    getPlatformConstantsApi({constant_types:['priority','severity','task_status']}).then((response) => {
      if (checkApiStatus(response)) {
        setPltConstants(response.data.data)
      }
    })
  }

  const getAllMilestone = () => {
    getAllMilestoneApi(params, {view:'all', fields:'id,name'}).then((response) => {
      if (checkApiStatus(response)) {
        setMilestone(response.data.data)
      }
    })
  }

  const onDeleteTask = () => {
    setLoading(true)
    globalDeleteService( `technical/project/${params.project_slug}/tasks/${deleteTask.task.id}/` )
    .then((response) => {
      setLoading(false)
      if (checkApiStatus(response)) {
        setDeleteTask({modal:false, task:null})
        enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, })
        getTasksData({ per_page: 10 }, "pageLoader")
        trackActivity('Task Management', {
          event_type: 'Delete Task Success',
          page_title: 'Delete Task',
          project_slug: params.project_slug,
          task_id: deleteTask.task.id ? deleteTask.task.id : '',
          response_msg: response.data.message
        })
      } else {
        enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, })
        trackActivity('Task Management', {
          event_type: 'Delete Task Failed',
          page_title: 'Delete Task',
          project_slug: params.project_slug,
          task_id: deleteTask.task.id ? deleteTask.task.id : '',
          response_msg: response.data.message
        })

      }
    })
  }
  const exportTasksApi = (query = {}, file) => {
    enqueueSnackbar('Task Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
    globalExportService(`technical/project/${params.project_slug}/tasks/`, { ...query, download: file.extension, })
    .then((response) => {
      downloadFileType(response, `Tasks_${getLocalStorageInfo().defaultLessor.name}_${moment().format( "YYYY-MM-DD" )}.`, file.extension )
      closeSnackbar();
      enqueueSnackbar('Task Report Downloaded', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'bottom'}});
      trackActivity('Task Management', {
        event_type: 'Export Task Success',
        page_title: 'Export Task',
        project_slug: params.project_slug,
        file_extension: file.extension ? file.extension : '',
        file_name: `Tasks_${getLocalStorageInfo().defaultLessor.name}_${moment().format( "YYYY-MM-DD" )}.` ? `Tasks_${getLocalStorageInfo().defaultLessor.name}_${moment().format( "YYYY-MM-DD" )}.` : '',
      })
    })
  }
  const getProjectDetails = (loaderType) => {
    setLoading(true)
    globalGetService(`technical/project/${params.project_slug}/`)
    .then(res => {
      setLoading(false)
        if(res.data.statusCode == 404){
            setNoAcess(true)
        }
    })
}
  const createSortHandler = (sortField) => {
    setSort(sortField)
    setSortBy(sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "asc")
    if (sortField === sort) {
      if (sort_by === "asc") {
        getTasksData(
          {
            ...filter,
            sort: sortField,
            sort_by: "desc",
            per_page: tasksInfo.pagination.per_page,
          },
          "pageLoader"
        )
      } else {
        getTasksData(
          {
            ...filter,
            sort: sortField,
            sort_by: "asc",
            per_page: tasksInfo.pagination.per_page,
          },
          "pageLoader"
        )
      }
    } else {
      getTasksData(
        {
          ...filter,
          sort: sortField,
          sort_by: "asc",
          per_page: tasksInfo.pagination.per_page,
        },
        "pageLoader"
      )
    }
  }

  let filterOptions = {
    ...tasksFilterOps,
    status: {
      ...tasksFilterOps.status,
      options: pltConstants.filter((item) => item.type === 'task_status')
    },
    owner: {
      ...tasksFilterOps.owner,
      options: taskUsers,
    },
    priority: {
      ...tasksFilterOps.priority,
      options: pltConstants.filter((item) => item.type === 'priority')
    },
    severity: {
      ...tasksFilterOps.severity,
      options: pltConstants.filter((item) => item.type === 'severity')
    },
    follower: {
      ...tasksFilterOps.follower,
      options: taskUsers
    },
    work_order: {
      ...tasksFilterOps.work_order,
      options: workOrders
    },
    form: {
      ...tasksFilterOps.form,
      options: forms
    },
  }
  if(onCheckPermission('task_management','stages','R')){
    filterOptions = {
      ...filterOptions,
      project_stage: {
        ...tasksFilterOps.project_stage,
        options: stages
      }
    }
  }else{
    delete filterOptions.project_stage
  }

  if(onCheckPermission('task_management','milestone','R')){
    filterOptions = {
      ...filterOptions,
      project_milestone: {
        ...tasksFilterOps.project_milestone,
        options: milestones
      },
    }
  }else{
    delete filterOptions.project_milestone
  }
  if(castleAirInstance){
    delete filterOptions.priority
    delete filterOptions.severity
    delete filterOptions.follower
    delete filterOptions.project_stage
    delete filterOptions.work_order
    delete filterOptions.form
  }
  return (
    <section className="projects-management-apps projects-management-section">
        {noAcess? <RestrictedAccess />:
      <TaskManagementWrapper>
      { skeletonLoader ? <STableLoader count={8} />:
        <div style={{height:`${window.innerHeight-140}px`, overflow:'auto'}}>
          <Paper square className="filter-cta">
            <Grid container spacing={1} alignItems='center'>
              <Grid item xs={12} sm={12} md={8}>
                <FilterComponent
                  filter={filter}
                  filterMenu={filterOptions}
                  getResponseBack={(applyFilter) => {getTasksData({...applyFilter, page:1, per_page:tasksInfo.pagination.per_page}, 'pageLoader'); browserHistory.push({...location,query:applyFilter})}}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <ul className="list-inline cta-list">
                  { onCheckPermission('task_management','tasks','C') ?
                    <li className="list-inline-item">
                      <Button color="primary" variant="contained" size="small" onClick={() => setAddEditTask({modal:true, task:null})} > Add Task </Button>
                    </li>:null
                  }
                  { onCheckPermission('task_management','tasks','C') && castleAirInstance ?
                    <li className="list-inline-item">
                    <ImportTask getResponseBack={getTasksData} slug={params.project_slug} />
                    </li>:null
                  }
                  { onCheckPermission('task_management','tasks','EXP') ?
                    <li className="list-inline-item">
                      <ExportManu
                        disabled={tasksInfo.list.length === 0 ? true : false}
                        title="Export"
                        files={[{ title: "Excel", extension: "xls", key: "" }]}
                        exportReportFn={(file) => exportTasksApi({download:file.extension, ...removeEmptyKey(filter)}, file)}
                      />
                    </li>:null
                  }
                </ul>
              </Grid>
            </Grid>
          </Paper>
          {(() => {
            let hds = taskHd;
            if(castleAirInstance){
              hds= hds.filter(item => !['Severity','Priority','Follower','Stages','Work Order','Form'].includes(item?.label))
            }
            if(!onCheckPermission('task_management','stages','R')){
              hds = hds.filter(item => item.label !== 'Stages');
            }
            if(!onCheckPermission('task_management','milestone','R')){
              hds = hds.filter(item => item.label !== 'Milestone');
            }
            return(
              <TableListComp
                style={{height: !tasksInfo.list.length ? 'auto':`${window.innerHeight-235}px`,overflow:'auto'}}
                heads={hds}
                sort={sort}
                sort_by={sort_by}
                data={tasksInfo.list.map((item, index) => (
                  <TaskList
                    tasksInfo={tasksInfo}
                    key={index}
                    item={item}
                    editTasks={() => setAddEditTask({modal:true, task:item})}
                    onRemoveTasks={(item) => setDeleteTask({modal:true,task:item})}
                    filter={filter}
                  />
                ))}
                noRecord={ tasksInfo.list.length ? null : <EmptyCollection title="No records found" /> }
                pagination={tasksInfo.pagination}
                onChangePage={(event, newPage) => getTasksData( { ...filter, page: newPage + 1, per_page: tasksInfo.pagination.per_page, }, "pageLoader" ) }
                onChangeRowsPerPage={(event) => getTasksData( { ...filter, page: 1, per_page: event.target.value, }, "pageLoader" ) }
                createSortHandler={createSortHandler}
              />
            )
          })()}
        </div>
      }
      </TaskManagementWrapper>}
      {addEditTask.modal ?
        <AddTask
          toggleModalFn={() => setAddEditTask({modal:false, task:null})}
          getResponseBack={() => getTasksData({per_page:10}, 'pageLoader')}
          milestones={milestones}
          workOrders={workOrders}
          stages={stages}
          taskUsers={taskUsers}
          pltConstants={pltConstants}
          addEditTask={addEditTask}
        />: null
      }
      {deleteTask.modal ? (
        <DeletePopUp
          modal={deleteTask.modal}
          toggleModalFn={() => setDeleteTask({modal:false, task:null})}
          title="Delete Task"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => onDeleteTask()}
          confirmText="Delete"
          isLoading={isLoading}
        />
      ) : null}
      {isLoading ? <PageLoader /> : null}
    </section>
  )
}
export default Tasks
