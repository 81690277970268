import React, {Fragment, useState, useEffect} from 'react';
import moment from 'moment';
import { Link, withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { read, utils } from 'xlsx';
import AddIcon from '@material-ui/icons/Add';
import { Paper, Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus, isPositiveInteger, onCheckPermission } from '../../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import { displayDateTimeFormatShort } from '../../../constants/';
import { trackActivity } from '../../../utils/mixpanel';

const ImportTemplate = ({params, importTemplate, toggleModalFn, getResponseBack})  => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [tabIndex, setTabIndex] = useState(0);
    const [modal, setModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [importCrud, setImportCrud] = useState({template:null});
    const [templates, setTemplates] = useState([])
    const [pres, setPres] = useState([]);
    const [error, setError] = useState([]);
    const getFormTemplates = (query) => {
        globalGetService(`technical/templates/`, {type:'T013'})
        .then(response => {
            if(checkApiStatus(response)){
                setTemplates(response.data.data.list)
            }
        });
    }
    const onSpartaTemplate = (value) => {
        if(value === 1){
            setLoading(true);
            globalGetService(`technical/v2/workorder/${params.workOrderSlug}/document-default-sections/?asset_type=${params.type}`)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    setPres(response.data.data);
                }else{

                }
            })
        }
    }
    const onChangeTemplate = (template) => {
        setImportCrud({template});
        setLoading(true);
        if(template){
            (async() => {
                const f = await (await fetch(template.file_path)).arrayBuffer();
                const wb = read(f); // parse the array buffer
                const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
                const data = utils.sheet_to_json(ws,{blankRows: false}); // generate objects
                setPres(data); // update state
                setLoading(false);
            })();
        }else{
            setPres([]);
            setLoading(false);
        }
    }
    const onFieldChange = (e, keyParam, value, index) => {
        setPres(pres.map((item, itemIndex) =>  itemIndex !== index ? item: {...item, [keyParam]: value}))
    }
    const onRemove = (index) => {
        setPres(pres.filter((item, itemIndex) => itemIndex !== index))
    }
    const onSetupTemplate = () => {
        // Validations for Template Import;
        setLoading(true);
        globalPostService(`technical/v2/workorder/${params.workOrderSlug}/workbook/import/?asset_type=${params.type}`, pres)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                window.location.reload();
                setModal(false);
                trackActivity('Project Management', {
                    event_type: 'Template Import Success',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    request_body: pres ? pres : '',
                    response: response.data.data,
                    success_msg: response.data.message
                });
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                trackActivity('Project Management', {
                    event_type: 'Template Import Failed',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    request_body: pres ? pres : '',
                    error_msg: response.data.data,
                    error_source:'Backend'
                });
            }
        })
    }
    return(
        <Fragment>
            <div className='import-form-template'>
                <h4>There is no section mapped to this form right now</h4>
                <p>Please choose the template to map the section</p>
                <Button onClick={() => {getFormTemplates(); setModal(true)}} color='primary' variant='outlined' size='small' startIcon={<AddIcon color='primary' fontSize='small' />}>Import Template</Button>
            </div>
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => {setModal(false)}}
                    className='project-management-modal'
                    maxWidth={false}
                >
                    <DialogTitle id="scroll-dialog-title">
                        Import Template
                    </DialogTitle>
                    <DialogContent dividers={true} >
                        <div style={{width:'780px', minHeight:'400px'}}>
                            <Paper>
                                {/* <Tabs
                                    value={tabIndex}
                                    onChange={(event, newValue) => {setPres([]);setTabIndex(newValue);onSpartaTemplate(newValue);setImportCrud({template:null})}}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label={<span>Custom Template</span>} />
                                    <Tab label={<span>Sparta Template</span>} />
                                </Tabs> */}
                            </Paper>
                            { tabIndex === 0 ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            options = {templates}
                                            getOptionLabel={option => option.name +' : '+ moment(option.created_at).format(displayDateTimeFormatShort)}
                                            id="template"
                                            value={importCrud.template}
                                            onChange={(e, value) => onChangeTemplate(value)}
                                            renderInput={params => <TextField required {...params} label="Select Template" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                        />
                                    </Grid>
                                    { onCheckPermission('project_management', 'template','C') ?
                                    <Grid item sm={6} style={{padding:'25px 0px 0px 7px'}} >
                                          or &nbsp;
                                        <Link to='/technical/form/templates/'>
                                            <Button variant='contained' color='primary' startIcon={<AddIcon  fontSize='small' />}>
                                                Add New Template
                                            </Button>
                                        </Link>
                                    </Grid>
                                    : null}

                                </Grid>:null
                            }
                            <Grid container spacing={1}>
                            {
                                 isLoading ?
                                     <Grid item sm={12}>
                                        <div style={{textAlign:'center', marginTop:'45px'}} > 
                                         <CircularProgress size={40} />
                                        </div>
                                    </Grid> : null
                             }
                                <Grid item md={12}>
                                    { pres.length ?
                                        <Table className='mui-table-format'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Section Name<sup>*</sup></TableCell>
                                                    <TableCell>Sub Section Name</TableCell>
                                                    <TableCell>Checklist</TableCell>
                                                    <TableCell>Notes</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { /* generate row for each president */
                                                    pres.map((pres,index) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                { pres['Section Name'] ? 
                                                                    <TextField
                                                                        required
                                                                        id={`name_${index}`}
                                                                        fullWidth
                                                                        margin="none"
                                                                        value={pres['Section Name']}
                                                                        onChange={(e) => onFieldChange(e, 'name', e.target.value, index)}
                                                                        error={error && error[index] &&  error[index].name}
                                                                        helperText={error && error[index] &&  error[index].name}
                                                                        InputLabelProps={{shrink: true}}
                                                                        variant='outlined'
                                                                    />:null
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                { pres['Sub Section Name'] ?
                                                                    <TextField
                                                                        required
                                                                        id={`description_${index}`}
                                                                        fullWidth
                                                                        margin="none"
                                                                        value={pres['Sub Section Name']}
                                                                        onChange={(e) => onFieldChange(e, 'description', e.target.value, index)}
                                                                        InputLabelProps={{shrink: true}}
                                                                        variant='outlined'
                                                                    />:null
                                                                }
                                                                
                                                            </TableCell>
                                                            <TableCell>
                                                                { pres['Checklist'] ?
                                                                    <div style={{width:'100px'}}>
                                                                        <TextField
                                                                            required
                                                                            id={`max_limit_${index}`}
                                                                            fullWidth
                                                                            margin="none"
                                                                            value={pres['Checklist']}
                                                                            onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'max_limit', e.target.value, index): e.preventDefault()}}
                                                                            error={error && error[index] &&  error[index].max_limit}
                                                                            helperText={error && error[index] &&  error[index].max_limit}
                                                                            InputLabelProps={{shrink: true}}
                                                                            variant='outlined'
                                                                        />
                                                                    </div>:null
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {pres['Notes'] ?
                                                                    <div style={{width:'100px'}}>
                                                                        <TextField
                                                                            required
                                                                            id={`physical_order_${index}`}
                                                                            fullWidth
                                                                            margin="none"
                                                                            value={pres['Notes']}
                                                                            onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'physical_order', e.target.value, index): e.preventDefault()}}
                                                                            error={error && error[index] &&  error[index].physical_order}
                                                                            helperText={error && error[index] &&  error[index].physical_order}
                                                                            InputLabelProps={{shrink: true}}
                                                                            variant='outlined'
                                                                        />
                                                                    </div>:null
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {pres.length !== 1 ?
                                                                    <DeleteOutlineIcon color='secondary' onClick={() => onRemove(index)} />:null
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>:null
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => {setModal(false)}} color="primary" size='small' variant='outlined'>Cancel</Button>
                        { pres.length ?
                            <Button disabled={isLoading} onClick={onSetupTemplate} color="primary" size='small' variant='contained'>
                                {isLoading ? <CircularProgress size={20} /> : 'Save'}
                            </Button>:null
                        }
                    </DialogActions>
                </Dialog>:null    
            }
        </Fragment>
    )
}
export default withRouter(ImportTemplate);