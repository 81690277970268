import moment from 'moment';
import { globalGetService, globalFileUploadPutService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService, globalExportService, globalDownloadService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, getLocalStorageInfo, dataURItoBlob } from '../../utils_v2';
import { downloadFileType } from '../../utils';
import { fieldValidation } from '../../utils_v2/formValidation';
import { findingErrorCode } from './';
export function getCurrentFormDetailApi(props={}){
  globalGetService(`technical/${props.params.assetType === 'engine' ? 'engine-workorder' :'workorder'}/${props.params.workOrderSlug}/forms/`,{form:props.params.formSlug})
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        currentFormDetail:response.data.data
      })
    }
  })
}

export function get3DInformationApi(props={}){
  globalGetService(`technical/workorder/${props.params.workOrderSlug}/fabrik/details/`)
  .then(response => {
    let result = response.data.data;
    if(checkApiStatus(response)){
      this.setState({
        threeDInfo:result,
        stepId:result?.fabrik_steps?.length ? result.fabrik_steps[0].step_id:'',
        stepType: result?.fabrik_steps?.length ? result.fabrik_steps[0].label :''
      })
    }
  })
}
export function changeFormStatusApi(props={}, data={}){
  this.setState({pageLoader:true});
  globalPostService(`technical/workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/status-update/`,data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.getCurrentFormDetailApi(this.props);
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function getFindingSectionsApi(props={}, query={}){
  this.setState({pageLoader:true});
  globalGetService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-sections/`, query)
  .then(response => {
    this.setState({pageLoader:false, skeletonLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        findingSections: response.data.data
      })
    }
  })
}

export function getPictorialFindingsApi(props, query={}, loaderType){
  return new Promise(function(resolve,reject){
    globalGetService(`technical/workorder/${props.params.workOrderSlug}/findings/`,query)
    .then(response => {
        resolve(response)
    })
  })
}

export function getFindingApi(props={}, sectionId, findingId){
  return new Promise(
    function(resolve, reject){
      globalGetService(`technical/workorder/${props.params.workOrderSlug}/aircraft-section/${sectionId}/inspection-findings/${findingId}/`)
      .then(response => {
        resolve(response);
      })
    }
  )
}

export function addEditFindingApi(props, data, contentType){
  return new Promise(
    function(resolve, reject){
      let validationInputs = {
        section:findingErrorCode['section'][fieldValidation({...findingErrorCode['sectionObj'], fieldval: data.section})],
        finding_type:findingErrorCode['finding_type'][fieldValidation({...findingErrorCode['finding_typeObj'], fieldval: data.finding_type})],
        remarks:findingErrorCode['remarks'][fieldValidation({...findingErrorCode['remarksObj'], fieldval: data.remarks})],
      }
      if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''}) || contentType === 'fileOnly'){
        let formData = new FormData();
        if(contentType === 'contentOnly'){
          formData.append('arm_srm_reference', data.arm_srm_reference ? data.arm_srm_reference:'');
          formData.append('damage_dimension', data.damage_dimension ? data.damage_dimension:'');
          formData.append('damage_limit', data.damage_limit ? data.damage_limit:'');
          formData.append('dd_wo_reference', data.dd_wo_reference?data.dd_wo_reference:'');
          formData.append('finding_type', data.finding_type ? data.finding_type:'');
          formData.append('remarks', data.remarks ? data.remarks:'');
          formData.append('corrective_actions', data.corrective_actions ? data.corrective_actions:'');
          formData.append('classifications', data.classifications ?data.classifications:'');
          formData.append('category', data.category ? data.category:'');
          formData.append('repair_type', data.repair_type ? data.repair_type:'');
          formData.append('intervals', data.intervals ? data.intervals:'');
          formData.append('x_coordinate', data.x_coordinate ? data.x_coordinate:'');
          formData.append('y_coordinate', data.x_coordinate ? data.y_coordinate:'');
          formData.append('z_coordinate', data.x_coordinate ? data.z_coordinate:'');
          formData.append('step_id', data.step_id ? data.step_id:'');
          formData.append('step_type', data.step_type ? data.step_type:'');
        }
        if(data.files){
          for(let i = 0; i < data.files.length; i++) {
             let file = data.files[i];
             formData.append('files[]', file);
          }
        }
        if(data.id){
          globalFileUploadPutService(`technical/workorder/${props.params.workOrderSlug}/aircraft-section/${data.section.id}/inspection-findings/${data.id}/`, formData)
          .then(response => {
            resolve(response);
          })
        }else{
          globalFileUploadService(`technical/workorder/${props.params.workOrderSlug}/aircraft-section/${data.section.id}/inspection-findings/`, formData)
          .then(response => {
            resolve(response);
          })
        }
      }else{
        resolve({data:{statusCode:100, error:validationInputs}})
      }
    }
  )
}

export function changeFindingImageApi(props={}, id, file){
  let formData = new FormData();
  formData.append('file', file);
  return new Promise(
    function(resolve, reject){
      globalFileUploadPutService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-picture/${id}/`, formData)
      .then(response => {
        resolve(response)
      })
    }
  )
}

export function deleteFindingImageApi(props={}, deleteIds){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-picture/${deleteIds.id}/`)
      .then(response => {
        resolve(response)
      })
    }
  )
}

export function annotateFindingImgApi(props, file, annotated){
  return new Promise(
    function(resolve, reject){
      dataURItoBlob(file).then(editedImage => {
        let formData = new FormData();
        formData.append('file', editedImage);
        globalPutService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-picture/${annotated.data.id}/`, formData)
        .then(response => {
          resolve(response)
        })
      })
    }
  )
}

export function pictorialFindingReportApi(props, queryParams){
  this.setState({pageLoader:true});
  globalExportService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-sections/`, queryParams)
  .then(response => {
    this.setState({pageLoader:false});
    downloadFileType(response, 'T007_Pictorial_Finding.', queryParams.download)
    this.props.enqueueSnackbar('Report exported successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}
export function pictorialFinding3DReportApi(props, files, extension){
  this.setState({pageLoader:true});
  var formData = new FormData();
  formData.append('download', extension);
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    formData.append('files_'+(i+1), file);
  }
  globalDownloadService(`technical/workorder/${props.params.workOrderSlug}/inspection-finding-sections/`, formData)
  .then(response => {
    this.setState({pageLoader:false});
    downloadFileType(response, 'T007_Pictorial_Finding.', 'pdf')
    this.props.enqueueSnackbar('Report exported successfully', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function deleteFindingApi(props={}, deleteIds){
  return new Promise(function(resolve, reject){
    globalDeleteService(`technical/workorder/${props.params.workOrderSlug}/aircraft-section/${deleteIds.section.id}/inspection-findings/${deleteIds.id}/`)
    .then(response => {
      resolve(response)
    })
  })  
}