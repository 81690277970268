import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import { Grid, Typography } from '@material-ui/core';
import { getLocalStorageInfo } from '../../../utils'
const AssetWidget = (props) => {
  if(getLocalStorageInfo().defaultLessor.lessor_type == 1 && props.showManaged){
          return (
            <Grid item xs={12} sm={props.index !== 0 ? 6 : 12} style={{ padding: '0 10px 10px 0' }}>
              <Grid container spacing={0}>
                <Grid item xs={12} className={props.className} style={{ cursor: 'pointer', marginBottom: '10px' }} onClick={() => props.url ? browserHistory.push(props.url) : null}>
                  <Link component="button" underline="hover" style={{ textAlign: 'center' }}>
                    {
                      props.dbAssetLoader 
                        ? <Typography variant="h6" style={{ color: '#ffffff' }}>Loading...</Typography>
                        : <Typography variant="h6">{props.statusGrpTitle} {props.value !== 0 ? `- ${props.value}` : ''}</Typography>
                    }
                  </Link>
                </Grid>
        
                <Grid item xs={6} style={{ padding: 0 }}>
                  <div className={props.className + " left-column"} onClick={() => props.ownedUrl ? browserHistory.push(props.ownedUrl) : null}>
                    <Link component="button" underline="hover" style={{ textAlign: 'center' }}>
                      {!props.dbAssetLoader ? <Typography variant="h5">{props.owned}</Typography> : null}
                      {
                        props.dbAssetLoader 
                          ? <Typography variant="body2" style={{ color: '#ffffff', paddingTop: '5px', textAlign: 'center' }}>Loading...</Typography>
                          : <Typography variant="h6">OWNED</Typography>
                      }
                    </Link>
                  </div>
                </Grid>
        
                <Grid item xs={6} style={{ padding: 0 }}>
                  <div className={props.className + " right-column"} onClick={() => props.managedUrl ? browserHistory.push(props.managedUrl) : null}>
                    <Link component="button" underline="hover" style={{ textAlign: 'center' }}>
                      {!props.dbAssetLoader ? <Typography variant="h5">{props.managed}</Typography> : null}
                      {
                        props.dbAssetLoader 
                          ? <Typography variant="body2" style={{ color: '#ffffff', paddingTop: '5px', textAlign: 'center' }}>Loading...</Typography>
                          : <Typography variant="h6">MANAGED</Typography>
                      }
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Grid>        

                    )
  }else {
    return(
      <Col style={{marginLeft: '0px', marginRight: '0px',paddingLeft: '0px', paddingRight: props.index != 0 ? '10px' : '0px'}}>
        <Row className="assets-row " style={{padding: '0px'}}>
          <Col md="12" className={props.className} style={{cursor: 'pointer', height: '100px'}} onClick={() => props.url ? browserHistory.push(props.url) : null}>
                <Link className="text-center">
                  {!props.dbAssetLoader ? <h5 style={{fontSize: '24px'}}>{props.value ? props.value : 0}</h5> : null}
                  {
                    props.dbAssetLoader ? <h6 style={{color: '#ffffff'}}>Loading...</h6> :<h6 style={{fontSize: '14px'}}>{props.statusGrpTitle}</h6>
                  }
      					</Link>
    			</Col>
        </Row>
      </Col>

    )
  }
}
export default AssetWidget;
