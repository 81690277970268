import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

export default function DataRoomUpRestriction({toggleModalFn, handleFileUpload}) {
  return (
    <Dialog
            open={true}
            className='project-management-modal'
            maxWidth='sm'
        >
            <DialogTitle id="scroll-dialog-title">
                Upload Document(s)
            </DialogTitle>
            <DialogContent dividers={true}>
            You do not have permissions to upload in Records Module. Press continue if you wish to keep the files in Project module only.
            </DialogContent>
            <DialogActions>
            <Button  onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
            <Button  onClick={handleFileUpload} color="primary" size='small' variant='contained'>Continue</Button>
            </DialogActions>
        
    </Dialog>
  )
}
