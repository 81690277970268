import { regexConstants, errorMsg1 } from '../../constants/regEx';
export const contactObj = {
    full_name: '',
    email: '',
    phone_number: '',
    fax: '',
    title: ''
}
export const contactFilterOps = {
    name: {
      inputType: 'text',
      placeholder: 'Search by Name',
      title:'Name'
    },
    email: {
      inputType: 'text',
      placeholder: 'Search by Email',
      title:'Email'
    }
}
export const errorCode = {
  full_name:{
    0: '',
    1: 'Please enter Name',
    4: errorMsg1
  },
  full_nameObj:{
    required: true,
  },
  email:{
    0:'',
    1: 'Please enter Email',
    4: 'Please enter correct email format'
  },
  emailObj:{
    required: true,
    regexPattern: regexConstants.email
  },
  phone_number:{
    0:'',
    4: 'Please enter correct format'
  },
  title: {
    0: '',
    4: errorMsg1
  },
  titleObj:{
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  phone_numberObj:{
    regexPattern: regexConstants.numberWithSpecChar
  },
  fax:{
    0:'',
    4: 'Please enter correct format'
  },
  faxObj:{
    regexPattern: regexConstants.fax
  },
}
