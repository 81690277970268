import React, { Component } from 'react';
import moment from 'moment';
import {  displayDateTimeFormatShort, imgStoragePath } from '../../../../constants';
export default class ViewAuditLog extends Component{
  constructor(props) {
    super(props);

    this.state = {
      tableStatus:  false
    };
  }
  dataShow = () => {
    this.setState(prevState => ({
      ...prevState,
      tableStatus: !this.state.tableStatus
    }))
  }
  render(){
    const { auditLogView } = this.props;

    return(
    <div className="flex-not-centered outer-wrapper">
        <div className="first-block">
          <div className="height-block">
              <img src={imgStoragePath+"edit_audit_icn.png"} alt="img"/>
              <div className="vl"></div>
          </div>
        </div>
        <div className="scnd-block">
          <div className="shadow-block">
          <div className="flex-not-centered">
            <div className="sec-inner-frst-block">
                <h6>Changed {auditLogView.form_name ? 'Form' : 'Project'} Status at</h6>
                <p>{ auditLogView.date ?  moment.utc(auditLogView.date).format(displayDateTimeFormatShort)   : '--' }</p>
            </div>
            <div className="sec-inner-sec-block">
                <h6>Updated by</h6>
                <p>{ auditLogView.modified_by ? auditLogView.modified_by : '--' }</p>
            </div>
          </div>
          <div className="show-data">
            <span onClick={this.dataShow}>Show Data 	<i style={ this.state.tableStatus ? { transform: 'rotate(180deg)' }:  { transform: 'rotate(0)' } }>&#9660;</i></span>
            <div className="edit-detail-table" style={ this.state.tableStatus ? {display: 'block'} :  {display: 'none' } }>
            <table>
              <thead>
                {
                  auditLogView.change_reason || auditLogView.form_name ?
                  <tr>
                    <th style={{width: '33%'}}>Label</th>
                    <th style={{width: '33%'}}>Old Value</th>
                    <th style={{width: '33%'}}>New Value</th>
                    <th style={{width: '33%'}}>Reason</th>
                  </tr>
                  :<tr>
                    <th style={{width: '33%'}}>Label</th>
                    <th style={{width: '33%'}}>Old Value</th>
                    <th style={{width: '33%'}}>New Value</th>
                  </tr>
                }
              </thead>
                 <tbody>
                 {
                   auditLogView.change_reason || auditLogView.form_name ?
                   <tr>
                     <td>{auditLogView.form_name} Status</td>
                     <td>{auditLogView.from_status && auditLogView.from_status.label ? auditLogView.from_status.label : '--'}</td>
                     <td>{auditLogView.to_status && auditLogView.to_status.label ? auditLogView.to_status.label : '--'}</td>
                     <td>{auditLogView.change_reason ? auditLogView.change_reason : '--'}</td>
                   </tr>
                   :<tr>
                      <td>Status</td>
                     <td>{auditLogView.from_status && auditLogView.from_status.label ? auditLogView.from_status.label : '--'}</td>
                     <td>{auditLogView.to_status && auditLogView.to_status.label ? auditLogView.to_status.label : '--'}</td>
                   </tr>
                 }
                 </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
  </div>

    )
  }
}
