import React, { Fragment } from 'react';
import { Button, Tabs, Tab, Avatar, Paper, Tooltip } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import CKeditor from "../../../../../shared/CKEditor";
import { DeleteModal } from '../../../Elements'
import { getLocalStorageInfo } from '../../../../../utils'
import defaultFileIcon from '../../../../../shared/assets/img/defaultFileIcon.svg'
import deleteIcon from '../../../../../shared/assets/img/delete_icon.svg';
import editIcon from '../../../../../shared/assets/img/edit_icon_mui.png';
import downloadIcon from '../../../../../shared/assets/img/download_icon.png';
import FilePreview from './FilePreview';
import moment from 'moment';
import { trackActivity } from '../../../../../utils/mixpanel';
const imgPath = 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/';
const CommentsAndAttachments = ({ editable, issueDetail, addIssueComment, comments, previewFile, uploadAttachment, updateIssueComment, deleteIssueComments, fileIcons, deleteFileApi, onDownloadAttachment,formInfo }) => {
  const [value, setValue] = React.useState(0);
  const [comment, setComment] = React.useState("");
  const [commentId, setCommentID] = React.useState("");
  const [fileId, setFileID] = React.useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [fileDeleteModal, setFileDeleteModal] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateComment = (evt) => {
    let comm = evt.editor.getData()
    setComment(comm)
  }
  const getFileIcon = (extension) => {
    if (fileIcons.length) {
      if (fileIcons.map(icon => icon.label).includes(extension.toLowerCase())) {
        return imgPath + 'file-icons/' + extension.toLowerCase() + '_icon.svg'
      } else {
        return defaultFileIcon;
      }
    } else {
      return defaultFileIcon;
    }

  }
  return (
    <Fragment>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label={`Comments (${comments && comments.length ? comments.length : 0})`} onClick={() => trackActivity('T011', { page_title: 'Issue Detail', event_type: 'Tab Click', tab_name: 'Comments' })} />
        <Tab label={`Attachments (${issueDetail && issueDetail.files.length ? issueDetail.files.length : 0})`} onClick={() => trackActivity('T011', { page_title: 'Issue Detail', event_type: 'Tab Click', tab_name: 'Attachments' })} />
      </Tabs>
      {value === 0 && comments.length ?
        <Paper style={{ padding: "15px" }}>
          {
            comments.map(comet =>
              <div className="comments-attachment-blk">
                <ul className="list-inline flex-centered comments-blk">
                  <li className="list-inline-item"><Avatar src=""></Avatar></li>
                  <li className="list-inline-item">{comet.user.name}</li>
                </ul>
                <div className="comments" dangerouslySetInnerHTML={{ __html: comet.comment }}></div>
                {
                  getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.id === comet.user_id ?
                    <p className="date-time">{moment.utc(comet.updated_at).format('DD-MM-YYYY HH:MM A') + ' UTC'}
                      {
                        editable ?
                          <span className="edit-delete-cta">
                            <Tooltip title="Delete" arrow>
                              <img width="10" src={deleteIcon} alt='Delete Icon'
                                onClick={() => {
                                  setDeleteModal(true)
                                  setCommentID(comet.id)
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Edit" arrow>
                              <img width="12" src={editIcon} alt='Edit Icon' onClick={() => {
                                setComment(comet.comment)
                                setCommentID(comet.id)
                              }} style={{ marginLeft: '10px' }} />
                            </Tooltip>
                          </span>
                          : null
                      }
                    </p>
                    : null
                }
              </div>
            )
          }
        </Paper> : null
      }
      {
        value === 0 && editable ?
          <Paper style={{ padding: '10px' }}>
            <CKeditor
              events={{ "change": updateComment }}
              content={comment ? comment : ''}
            />
            {
              formInfo?.current_form?.permissions?.can_save?
            <Button color="primary" variant="outlined" disabled={comment === ""} style={{ margin: '10px 0px' }} onClick={() => {
              if (commentId !== "") {
                updateIssueComment(issueDetail.id, comment, commentId);
              } else {
                addIssueComment(issueDetail.id, comment);
              }
              setTimeout(() => {
                setComment('')
                setCommentID('')
              }, 1500);
              trackActivity('T011', { page_title: 'Issue Detail', event_type: 'Click', event_desc: `Clicked on ${commentId === '' ? "Submit" : "Update"} Button` })
            }
            }>{commentId === '' ? "Submit" : "Update"} Comment</Button>
            :null
          }
          </Paper>
          : null
      }
      {value === 1 ?
        <Paper>
          <ul className="list-inline attachments-blk">
            {
              issueDetail.files.map(file =>
                <li className="list-inline-item">
                    {
                    formInfo?.current_form?.permissions?.can_save?
                    <Tooltip title="Delete" arrow>
                    <img className="delete-icon" onClick={() => {
                      setFileID(file.id)
                      setFileDeleteModal(true)
                    }} src={deleteIcon} alt="Delete Icon" />
                  </Tooltip>
                    : null
                    }
                  <Tooltip title="Download" arrow>
                    <a className="download-icon" onClick={() => onDownloadAttachment(file)} download><img src={downloadIcon} alt="Download Icon" /></a>
                  </Tooltip>
                  {
                    editable ?
                      <img style={{ padding: '10px', width: '200px', height: '200px', marginBottom: '10px' }} alt="s" src={getFileIcon(file.name.substr(file.name.lastIndexOf('.') + 1))} onClick={() => {
                        previewFile({ ...file, extension: file.name.substr(file.name.lastIndexOf('.') + 1), path: file.file });
                        trackActivity('T011', { event_type: 'File Previewed', page_title: 'Issue Detail', file_extension: file.name.substr(file.name.lastIndexOf('.') + 1), file_path: file.file })
                      }} />
                      : null
                  }
                  {
                    editable ?
                      <div className="file-preview-cta" onClick={() => {
                        previewFile({ ...file, extension: file.name.substr(file.name.lastIndexOf('.') + 1), path: file.file })
                      }}>
                        <Tooltip title={file.name} arrow>
                          <p className="file-name">{file.name.length > 20 ? file.name.substr(0, 17) + '...' : file.name}</p>
                        </Tooltip>
                      </div>
                      : null
                  }
                </li>
              )
            }
          </ul>
          {
            editable ?
            formInfo?.current_form?.permissions?.can_save?
              <Button color="primary" disabled={!editable} variant="outlined" style={{ position: 'relative', margin: '0px 10px 15px' }} onClick={() => trackActivity('T011', { page_title: 'Issue Detail', event_type: 'Click', event_desc: 'Clicked on ADD ATTACHMENT Button' })}>Add Attachment
                <input
                  type="file"
                  accept={"image/x-png,image/gif,image/jpeg, .pdf, .xls, .xlsx"}
                  onChange={(e) => uploadAttachment(e.target.files)}
                  style={{ position: 'absolute', left: '0', opacity: '0', cursor: 'pointer' }}
                />
              </Button>
              : null
              : null
          }
        </Paper> : null
      }
      <DeleteModal isOpen={deleteModal} toggle={() => setDeleteModal(false)} title={"Are you sure to delete this comment ?"} className="modal-tform-delete-pictures modal-dialog-centered">
        <ul className="list-inline">
          <li className="list-inline-item">
            <button type="button" className="btn btn-danger" onClick={() => {
              deleteIssueComments(issueDetail.id, commentId)
              setTimeout(() => {
                setComment('')
                setCommentID('')
                setDeleteModal(false)
              }, 1500)
            }
            }>Yes</button>
          </li>
          <li className="list-inline-item">
            <button type="button" className="btn btn-primary"
              onClick={() => {
                setTimeout(() => {
                  setComment('')
                  setCommentID('')
                  setDeleteModal(false)
                }, 1500)
              }
              }
            >No</button>
          </li>
        </ul>
      </DeleteModal>
      <DeleteModal isOpen={fileDeleteModal} toggle={() => setFileDeleteModal(false)} title="Are you sure to delete ?" className="modal-tform-delete-pictures modal-dialog-centered">
        <ul className="list-inline">
          <li className="list-inline-item">
            <button type="button" className="btn btn-danger" onClick={() => {
              deleteFileApi(fileId)
              setTimeout(() => {
                setFileID('')
                setFileDeleteModal(false)
              })
            }} >Yes</button>
          </li>
          <li className="list-inline-item">
            <button type="button" className="btn btn-primary" onClick={() => setFileDeleteModal(false)}>No</button>
          </li>
        </ul>
      </DeleteModal>
    </Fragment>
  )
}
export default CommentsAndAttachments;
