import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton, Checkbox } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
export default function List({item, onEdit, onDelete, onSelectRow, deleteContacts,currentFormDetail}){
  return(
    <TableRow hover tabIndex={-1}>
      { currentFormDetail?.current_form?.permissions?.can_save  ?
        <TableCell><Checkbox checked={deleteContacts.contacts.includes(item.id)} onChange={(e) => onSelectRow(e.target.checked)} size='small' color='primary' /></TableCell>:null
      }
      <TableCell>{item.full_name ? item.full_name:'--'}</TableCell>
      <TableCell>{item.email ? item.email:'--'}</TableCell>
      <TableCell>{item.title ? item.title:'--'}</TableCell>
      <TableCell>{item.phone_number ? item.phone_number:'--'}</TableCell>
      <TableCell>{item.fax ? item.fax:'--'}</TableCell>
      <TableCell className="actions-cell">
        { currentFormDetail?.current_form?.permissions?.can_save ? <div style={{width:'70px', float:'right'}}>
          <Tooltip title="Edit">
            <IconButton size="small" color="primary" onClick={onEdit} >
              <EditIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove">
            <IconButton size="small" color="primary" onClick={onDelete} >
              <DeleteIcon color='secondary' fontSize="small" />
            </IconButton>
          </Tooltip>
        </div> : null}
      </TableCell>
    </TableRow>
  )
}
