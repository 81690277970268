import React, { useState } from 'react'
import { withRouter } from 'react-router';
import { TextField, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow } from '@material-ui/core';
import { read, utils } from 'xlsx';
import { GetApp } from '@material-ui/icons';
import { checkApiStatus, downloadFileType } from '../../../utils_v2';
import { globalPostService, globalExportService } from '../../../utils_v2/globalApiServices';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { trackActivity } from '../../../utils/mixpanel';
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack';

export default function ImportSubSectionCheck({ params, toggleModalFn, type, importCheckList, importSubSections }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [pres, setPres] = useState([]);

    const onFieldChange = (e, keyParam, value, index) => {
        setPres(pres.map((item, itemIndex) => itemIndex !== index ? item : { ...item, [keyParam]: value }))
    }
    const onRemove = (index) => {
        setPres(pres.filter((item, itemIndex) => index !== itemIndex))
    }
    const onFilesDrop = (files) => {
        if (files && files.length > 0) {
            setLoading(true);
            const file = files[0];
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const buffer = e.target.result;
                    const wb = read(new Uint8Array(buffer));
                    const ws = wb.Sheets[wb.SheetNames[0]];
                    const data = utils.sheet_to_json(ws, { blankRows: false });
                    setLoading(false);
                    if (data.length === 0) {
                        enqueueSnackbar("Invalid Data", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                    setPres(data);
                };
                reader.readAsArrayBuffer(file);
            } else {
                console.error("Invalid file object. Expected a Blob.");
            }
        }

    }
    const onImportSubSections = () => {
        // Validations for Template Import;
        setLoading(true);
        if (type != 'checkList') {
            globalPostService(`technical/v2/workorder/${params?.workOrderSlug}/workbook/import/?asset_type=${params.type}/?asset_type=${params.type}&section_id=${importSubSections?.sectionId}`, pres)
                .then(response => {
                    setLoading(false);
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        window.location.reload();
                        toggleModalFn();
                        trackActivity('Project Management', { event_type: 'Sub Section Import Success', page_title: 'T013 Form', workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '', request_body: pres ? pres : '', response: response.data.data, success_msg: response.data.message });
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        trackActivity('Project Management', { event_type: 'Sub Section Import Failed', page_title: 'T013 Form', workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '', request_body: pres ? pres : '', error_msg: response.data.data, error_source: 'Backend' });
                    }
                })
        }
        else {
            globalPostService(`technical/v2/workorder/${params?.workOrderSlug}/workbook/import/?asset_type=${params.type}&section_id=${importCheckList?.sectionId}&sub_section_id=${importCheckList?.subSectionId}`, pres)
                .then(response => {
                    setLoading(false);
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        window.location.reload();
                        toggleModalFn();
                        trackActivity('Project Management', { event_type: 'Checklist Import Success', page_title: 'T013 Form', workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '', request_body: pres ? pres : '', response: response.data.data, success_msg: response.data.message });
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        trackActivity('Project Management', { event_type: 'Checklist Import Failed', page_title: 'T013 Form', workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '', request_body: pres ? pres : '', error_msg: response.data.data, error_source: 'Backend' });
                    }
                })
        }
    }
    const downloadSampleTemplate = () => {
        if (type != 'checkList') {
            window.open(`https://sparta-aims.ams3.cdn.digitaloceanspaces.com/media-dev/Sample_Template/Sub_Section_Sample_Import.xlsx`)
                    trackActivity('Project Management', {
                        event_type: 'Sample Sub section Template Downloaded',
                        page_title: 'T013 Form',
                        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                        file_name: 'T013_Sample_Template',
                        file_extension: 'xls'
                    });
        }
        else {
            window.open(`https://sparta-aims.ams3.cdn.digitaloceanspaces.com/media-dev/Sample_Template/Checklist_Sample_Import.xlsx`)
                    trackActivity('Project Management', {
                        event_type: 'Sample CheckList Template Downloaded',
                        page_title: 'T013 Form',
                        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                        file_name: 'T013_Sample_Template',
                        file_extension: 'xls'
                    });
        }
    }
    return (
        <Dialog
            open={true}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth='lg'
        >
            <DialogTitle> Import {type == 'checkList' ? 'CheckList' : 'Sub Section'}  </DialogTitle>
            <DialogContent dividers={true} >
                <div style={{ width: "828px", height: "378px" }}>
                    <Grid container spacing={1}>
                        <Grid item sm='12'>
                            <DropzoneArea
                                acceptedFiles={['.xls, .xlsx']}
                                filesLimit={1}
                                maxFileSize={10428800}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={false}
                                showPreviews={false}
                                dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['info', 'error']}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                clearOnUnmount={true}
                                onChange={(files) => onFilesDrop(files)}
                            />
                            <div>
                                {pres.length ?
                                    <Table className='mui-table-format'>
                                        <TableHead>
                                            <TableRow>
                                                {type != 'checkList' ? <TableCell>Sub Section Name</TableCell> : null}
                                                <TableCell>Checklist</TableCell>
                                                <TableCell>Notes</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { /* generate row for each president */
                                                pres.map((pres, index) => (
                                                    <TableRow>
                                                        {type != 'checkList' ? <TableCell>
                                                            <div style={{ width: '200px' }}>
                                                                {pres['Sub Section Name'] ?
                                                                    <TextField
                                                                        required
                                                                        id={`Sub-Section_${index}`}
                                                                        fullWidth
                                                                        margin="none"
                                                                        value={pres['Sub Section Name']}
                                                                        onChange={(e) => onFieldChange(e, 'Sub Section Name', e.target.value, index)}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        variant='outlined'
                                                                    /> : null
                                                                }
                                                            </div>
                                                        </TableCell> : null}
                                                        <TableCell>
                                                            {pres['Checklist'] ?
                                                                <div style={{ width: '200px' }}>
                                                                    <TextField
                                                                        required
                                                                        id={`Question_${index}`}
                                                                        fullWidth
                                                                        margin="none"
                                                                        value={pres['Checklist']}
                                                                        onChange={(e) => { onFieldChange(e, 'max_limit', e.target.value, index) }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        variant='outlined'
                                                                    />
                                                                </div> : null
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <div style={{ width: '200px' }}>
                                                                <TextField
                                                                    required
                                                                    id={`Notes_${index}`}
                                                                    fullWidth
                                                                    margin="none"
                                                                    value={pres['Notes'] ? pres['Notes'] : ''}
                                                                    onChange={(e) => { onFieldChange(e, 'Notes', e.target.value, index) }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    variant='outlined'
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            {pres.length !== 1 ?
                                                                <DeleteOutlineIcon color='secondary' onClick={() => onRemove(index)} /> : null
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table> : null
                                }
                            </div>
                        </Grid>
                        <div>
                            <p style={{ fontSize: '13px', fontStyle: 'italic', color: '#717171' }}>Note: Please download "Sample {type == 'checkList'?'Checklist': 'Sub Section(s)'} template", edit the template in Excel and upload the same for further customization. </p>
                            <p className='download-sample-temp' onClick={() => downloadSampleTemplate()} > <GetApp /> Download Sample {type == 'checkList'?'Checklist':'Sub Section(s)'} template</p>
                        </div>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                {pres.length ?
                    <Button disabled={isLoading} onClick={onImportSubSections} color="primary" size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={20} /> : 'Save'}
                    </Button> : null
                }
            </DialogActions>
        </Dialog>
    )
}
