import * as actions from '../actions';
import { toastFlashMessage } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
import { globalGetService, globalPostService, globalDeleteService } from '../../../../../globalServices/';
import { getFormDetailsAc } from '../../T001/actionCreators';
export const fetchGapReportDetailsAc = (props) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService('/technical/engine-workorder/' + props.params.workOrderSlug + '/gap-report-sections/', {})
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.GET_GAPREPORT_INV,
						payload: response.data.data
					})
				}
			})
	}
}

export const removeGapReportDetAc = (props, compoIndex, componentId, section) => {
	return (dispatch) => {
		if (!componentId) {
			dispatch({
				type: actions.DELETE_GAP_COMPONENT,
				payload: { index: compoIndex, sectionType: section }
			})
		} else {
			dispatch(triggerLoader(true));
			globalDeleteService('technical/engine-workorder/' + props.params.workOrderSlug + '/gap-report/' + componentId + '/delete/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch(fetchGapReportDetailsAc(props));
						window.location.reload()
						// toastFlashMessage(response.data.message, 'success');
						trackActivity('TE003', {
							event_type: 'Gap Report Detail Deleted',
							page_title: 'Gap Report',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							component_id: componentId ? componentId : '',
							component_index: compoIndex ? compoIndex : '',
							section_type: section ? section : '',
							response_body: response.data.data ? response.data.data : '',
							asset_type: props.params.type ? props.params.type : ''
						})
					}
				})
		}
	}
}

export const updateGapReportDetailsAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('/technical/engine-workorder/' + props.params.workOrderSlug + '/gap-report/', { data: data })
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					toastFlashMessage(response.data.message, 'success');
					trackActivity('TE003', {
						event_type: 'Gap Report Saved',
						page_title: 'Gap Report',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : '',
					})
					dispatch(fetchGapReportDetailsAc(props));
					dispatch(getFormDetailsAc(props, 2))
					/*dispatch({
						type: actions.GET_POWERPLANT_INV,
						payload: response.data.data
					})*/
				}
			})
	}
}

export const triggerLoader = (flag) => {
	return (dispatch) => {
		dispatch({
			type: actions.GAPREPORT_DETLS_LOADER,
			payload: flag
		});
	}
}