import React, { Fragment } from 'react';
const AircraftModelStep = ({goToStep, stepId, threeDInfo}) => {
    return(
        <Fragment>
            <ul className='list-unstyled frame-position-cta right-position'>
                { threeDInfo.fabrik_steps.filter(item => item.position === 'Vertical').map((step, index) => 
                    <li className={stepId === step.step_id ? 'active':''} onClick={()=> goToStep(step.step_id, step.label)}>{step.label}</li>
                )}
            </ul>
            <ul className='list-inline frame-position-cta bottom-position'>
                { threeDInfo.fabrik_steps.filter(item => item.position === 'Horizontal').map((step, index) => 
                    <li onClick={()=> goToStep(step.step_id, step.label)} className={`list-inline-item ${stepId === step.step_id ? 'active':''}`}>{step.label}</li>
                )}
            </ul>
        </Fragment>
    )
}
export default AircraftModelStep;