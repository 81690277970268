import React, { useState } from 'react'
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import AddIcon from '@material-ui/icons/Add';
import { globalPostService } from '../../../utils_v2/globalApiServices';
import { checkApiStatus } from '../../../utils_v2';
import { useSnackbar } from 'notistack';
import { GetApp } from '@material-ui/icons';

export default function ImportTask({getResponseBack, slug}) {
    const [importTask, setImportTask] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [files,setFiles]= useState(null)
    const onFilesDrop = (file) => {
        setFiles(file);
    }
    const importTaskListFn =()=>{
        var formData = new FormData();
        formData.append('import_file',files[0]);
        globalPostService(`technical/project/${slug}/import-tasks/`, formData)
        .then(response=>{
            if(checkApiStatus(response)){
                getResponseBack()
                setImportTask({modal:false})
                enqueueSnackbar(response?.data?.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
            else{
                enqueueSnackbar(response?.data?.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    const downloadSampleTemplate =()=>{
        window.open(`https://sparta-aims.ams3.cdn.digitaloceanspaces.com/media-dev/Sample_Template/Project_Tasks_Sample_Import.xlsx`)
    }
    return (
        <>
            <Button startIcon={<AddIcon color='primary' fontSize='small' />} onClick={() => setImportTask({ modal: true })} color='primary' variant='outlined' size='small'>Import Task List</Button>
            <Dialog
                open={importTask.modal}
                onClose={() => setImportTask({ modal: true })}
                maxWidth='lg'
            >
                <DialogTitle id="scroll-dialog-title">
                    Import Task List
                </DialogTitle>
                <DialogContent dividers={true} style={{ width: "400px" }}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <DropzoneArea
                                acceptedFiles={['.xlsx']}
                                filesLimit={1}
                                maxFileSize={10428800}
                                showPreviewsInDropzone={false}
                                useChipsForPreview={true}
                                showPreviews={true}
                                dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['info', 'error']}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                clearOnUnmount={true}
                                onChange={(files) => onFilesDrop(files)}
                            />
                        </Grid>
                        <p className='download-sample-temp' onClick={() => downloadSampleTemplate()} > <GetApp /> Download Sample template</p>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setImportTask({ modal: false })} color="primary" size='small' variant='outlined'>
                        Cancel
                    </Button>
                    <Button disabled={files?.length ? false :true} onClick={() => importTaskListFn()} color="primary" size='small' variant='contained'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
