import * as actions from '../actions';
import { toastFlashMessage } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel';
import { globalGetService, globalPostService, globalDeleteService } from '../../../../../globalServices/';

export const fetchpowerPlantDetailsAc = (props) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService('/technical/engine-workorder/' + props.params.workOrderSlug + '/get-powerplant-accessories/', {})
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.GET_POWERPLANT_INV,
						payload: response.data.data
					})
				}
			})
	}
}


export const deletePowerPlantDetAc = (props, compoIndex, componentId, section) => {
	return (dispatch) => {
		if (!componentId) {
			dispatch({
				type: actions.DELETE_POWER_COMPONENT,
				payload: { index: compoIndex, sectionType: section }
			})
		} else {
			dispatch(triggerLoader(true));
			globalDeleteService('technical/engine-workorder/' + props.params.workOrderSlug + '/powerplant-accessory/' + componentId + '/delete/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.DELETE_POWER_COMPONENT,
							payload: { index: compoIndex, sectionType: section }
						})
						// toastFlashMessage(response.data.message, 'success');
						trackActivity('TE002', {
							event_type: 'Powerplant Inventory Detail(s) Deleted',
							page_title: 'Power plant Inventory List',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							section_type: section ? section : '',
							component_id: componentId ? componentId : '',
							component_index: compoIndex ? compoIndex : '',
							response_body: response.data.data ? response.data.data : '',
							asset_type: props.params.type ? props.params.type : '',
						})
					}
				})
		}
	}
}

export const updatepowerPlantDetailsAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('/technical/engine-workorder/' + props.params.workOrderSlug + '/update-powerplant-accessories/', data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					toastFlashMessage(response.data.message, 'success');
					trackActivity('TE002', {
						event_type: 'Powerplant Inventory List Updated',
						page_title: 'Power plant Inventory List',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : '',
					})
					dispatch(fetchpowerPlantDetailsAc(props))
					setTimeout(() => {window.location.reload()}, 500)
				} else {
					trackActivity('TE002', {
						event_type: 'Powerplant Inventory List Update Failed',
						page_title: 'Power plant Inventory List',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						error_source: 'Backend',
						error_msg: response.data.message ? response.data.message : '',
						asset_type: props.params.type ? props.params.type : '',
					})
				}
			})
	}
}

export const triggerLoader = (flag) => {
	return (dispatch) => {
		dispatch({
			type: actions.POWERPLANT_DETLS_LOADER,
			payload: flag
		});
	}
}
