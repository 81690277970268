import * as actions from '../actions';
import { toastFlashMessage, dataURItoBlob } from '../../../../../utils';
import { globalGetService,  globalPostService, globalDeleteService, globalFileUploadService } from '../../../../../globalServices/';
import { usageDetailAc } from '../../../../../shared/actionCreators';
import { trackActivity } from '../../../../../utils/mixpanel';
export const fetchBoroscopeDetailsAc = (props) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService('/technical/engine-workorder/' + props.params.workOrderSlug + '/borescope-details/', {})
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.GET_BOROSCOPE_INSP_DTLS,
						payload: response.data.data
					})
				}
			})
	}
}

export const editPlatePicAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		let fileInfo = Object.assign({}, data);
		globalGetService(`technical/engine-workorder/${props.params.workOrderSlug}/form/${props.params.formSlug}/get-pictures-url/`, { pic_id: data.data.id })
			.then(response => {
				if (response.data.statusCode == 200) {
					fileInfo = {
						...fileInfo,
						image: response.data.data
					}
					dispatch({
						type: actions.EDIT_INSPECTION_IMAGE,
						payload: { flag: true, data: fileInfo }
					})
					trackActivity('TE001', {
						event_type: 'File Edited',
						page_title: 'Borescope Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						pic_id: data.data.id ? data.data.id : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : '',
					})
				}
				dispatch(triggerLoader(false));
			})
	}
}

export const uploadImageAc = (props, file) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		var formData = new FormData();
		for (let f of file) {
			formData.append('files[]', f, f.name);
		}
		formData.append('borescope', true);

		var config = {
			onUploadProgress: function (progressEvent) {
				var percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				// dispatch({
				//   type: actions.PROGRESS_PERCENTAGE,
				//   payload: percentage
				// });
			}
		}
		globalFileUploadService('/technical/engine-workorder/' + props.params.workOrderSlug + '/file-upload/', formData, config)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					dispatch({
						type: actions.UPLOAD_IMAGE,
						payload: response.data.data.map(item => {
							let innerItem = { ...item, image: item.url }
							return innerItem
						})
					})
					trackActivity('TE001', {
						event_type: 'File Uploaded',
						page_title: 'Borescope Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: formData ? formData : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : '',
					})
				} else {
					trackActivity('TE001', {
						event_type: 'File Upload Failed',
						page_title: 'Borescope Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: formData ? formData : '',
						error_source: 'Backend',
						error_msg: response.data.message ? response.data.message : '',
						asset_type: props.params.type ? props.params.type : '',
					})
				}
				dispatch(usageDetailAc())
			})
	}
}

export const updateBoroscopeDetlsAc = (props, data) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalPostService('/technical/engine-workorder/' + props.params.workOrderSlug + '/borescope-details/', data)
			.then(response => {
				dispatch(triggerLoader(false));
				if (response.data.statusCode == 200) {
					toastFlashMessage(response.data.message, 'success');
					trackActivity('TE001', {
						event_type: 'Borescope Details Saved',
						page_title: 'Borescope Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						response_body: response.data.data ? response.data.data : '',
						asset_type: props.params.type ? props.params.type : '',
					})
					dispatch({
						type: actions.GET_BOROSCOPE_INSP_DTLS,
						payload: response.data.data
					})
					setTimeout(() => {window.location.reload()}, 500)
				} else {
					trackActivity('TE001', {
						event_type: 'Save Borescope Details Failed',
						page_title: 'Borescope Inspection',
						workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
						form_slug: props.params.formSlug ? props.params.formSlug : '',
						request_body: data ? data : '',
						error_msg: response.data.message ? response.data.message : '',
						error_source: 'Backened',
						asset_type: props.params.type ? props.params.type : '',
					})
				}
			})
	}
}

export const deleteComponentAc = (props, compoIndex, componentId) => {
	return (dispatch) => {
		if (!componentId) {
			dispatch({
				type: actions.DELETE_COMPONENT,
				payload: compoIndex
			})
		} else {
			dispatch(triggerLoader(true));
			globalDeleteService('technical/engine-workorder/' + props.params.workOrderSlug + '/delete-borescope-component/' + componentId + '/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.DELETE_COMPONENT,
							payload: compoIndex
						})
						// toastFlashMessage(response.data.message, 'success');
						trackActivity('TE001', {
							event_type: 'Component Deleted',
							page_title: 'Borescope Inspection',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							component_id: componentId ? componentId : '',
							component_index: compoIndex ? compoIndex : '',
							response_body: response.data.data ? response.data.data : '',
							asset_type: props.params.type ? props.params.type : '',
						})
					}
				})
		}
	}
}

export const deleteImageAc = (props, imageIndex, imageId) => {
	return (dispatch) => {
		if (!imageId) {
			dispatch({
				type: actions.DELETE_BORSCP_IMAGE,
				payload: imageIndex
			})
		} else {
			dispatch(triggerLoader(true));
			globalDeleteService('technical/engine-workorder/' + props.params.workOrderSlug + '/delete-borescope-image/' + imageId + '/', {})
				.then(response => {
					dispatch(triggerLoader(false));
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.DELETE_BORSCP_IMAGE,
							payload: imageIndex
						})
						// toastFlashMessage(response.data.message, 'success');
						trackActivity('TE001', {
							event_type: 'File Deleted',
							page_title: 'Borescope Inspection',
							workorder_slug: props.params.workOrderSlug ? props.params.workOrderSlug : '',
							form_slug: props.params.formSlug ? props.params.formSlug : '',
							image_id: imageId ? imageId : '',
							image_index: imageIndex ? imageIndex : '',
							response_body: response.data.data ? response.data.data : '',
							asset_type: props.params.type ? props.params.type : '',
						})
					}
				})
		}
	}
}

export const saveEditedImageAc = (props, imageId, image) => {
	var formData = new FormData();
	return (dispatch) => {
		dispatch(triggerLoader(true));
		dataURItoBlob(image).then(editedImage => {
			formData.append('file', editedImage);
			globalPostService(`technical/engine-workorder/${props.params.workOrderSlug}/borescope-inspection/${imageId}/`, formData)
				.then(response => {
					if (response.data.statusCode == 200) {
						dispatch({
							type: actions.EDIT_INSPECTION_IMAGE,
							payload: { flag: false, data: {} }
						});
					}
					dispatch(triggerLoader(false));
					toastFlashMessage(response.data.message, 'success');
					dispatch(fetchBoroscopeDetailsAc(props));
				})
		})
	}
}

export const triggerLoader = (flag) => {
	return (dispatch) => {
		dispatch({
			type: actions.BOROSCOPE_DETLS_LOADER,
			payload: flag
		});
	}
}
