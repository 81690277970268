import React, { Component } from 'react';
import Select from 'react-select';
import { Grid, Button, } from '@material-ui/core';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import { getLocalStorageInfo, removeEmptyKey, convertFilterObject, toastFlashMessage } from '../../../../../utils';
import { trackActivity } from '../../../../../utils/mixpanel'
import { usageDetailAc } from '../../../../../shared/actionCreators'
import { imgStoragePath, displayDateTimeFormatShort } from '../../../../../constants';
import { ExportFiles, MSNSwitcherHeader, ListLoader, NoRecordFound, TableListComp, FilterUIComp } from '../../../../../shared';
import { DeleteModal, EditFormBar, FormHeader, SecondaryTechHeaderRH, SecondaryTechHeaderMid, DownloadedLogs, } from '../../../Elements';
import { FieldCol, FieldGroup, FieldLabel } from '../../../Elements_V';
import { List, FinalSummeriesListV1, TechnicalInspectionFormV1, ActivityLog, IssueList, IssueListMenu, IssueDetail, TaskStatusChart, TaskStatusProgressChart, TaskStatusList, BulkOperationMenu, UserStatsChart, HeaderCountCards } from '../components';
import { gapReportHd, gapReporATAtHd, tableContentHd, gapSecReportHd, gapSecReportATAHd, gapSecReportCyclesHd, sectionIds, taskStatusHd } from '../';
import { getTableContentsApi, getTableSectionsApi, updateTableSectionsFieldApi, fetchActivityLogs, deleteTableSectionRowApi, getFormInfoApi, exportReport, updateFormStatusApi, getUserListApi, getUserStatsApi, moveGapIdsApi, bulkAssigneeApi, fetchIssueComments, addIssueComments, updateIssueComment, deleteIssueComments, fetchTags, addTags, fetchIssueTags, addIssueTags, removeIssueTags, getFileIcons, deleteFileApi, onDownloadAttachmentApi, fetchIssueDetails, importExcelApi, updateTechnicalFormDataApi, bulkOpsApi, getAssigneeListApi, getCreatedByListApi, getAssigneeFilterListApi } from '../apiServices';
import moment from 'moment';
import { T011SectionCrud } from '..';
import refreshIcon from '../../../../../shared/assets/img/refresh_icon.svg';
import { browserHistory } from 'react-router';
import { ContentDrop } from '../../../Elements';
import FilePreview from '../components/FilePreview'
require("highcharts/highcharts-3d")(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
class InspectionFormT011v1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false,
      tabIndex: 0,
      formTabIndex: 0,
      sectionId: 0,
      addSectionId: 0,
      editableRow: true,
      openTechForm: false,
      importPRS: false,
      downloadFormLogs: false,
      uploadExcelSidebar: false,
      toggleUploadLogs: false,
      T011SectionCrud: T011SectionCrud,
      t011ReportInfo: { items: null, list: [] },
      t011SectionInfo: { list: [], pagination: {} },
      applyingFilter: {},
      filePreview: {
        open: false,
        data: {}
      },
      deleteModal: false,
      prsDropdownModal: false,
      prsAssigneeModal: false,
      deleteItem: '',
      fullScreen: false,
      formInfo: {},
      filter: {},
      assigneeList: [],
      assigneeFilterList:[],
      createdList:[],
      gap_report_ids: [],
      tags: [],
      issueTags: [],
      hideTitleContent: true,
      operatorFlag: false,
      deleteType: 'single',
      showActivityLogs: false,
      activityLogs: [],
      searchLog: '',
      userList: [],
      userStats: [],
      sectionMoveId: '',
      importError: '',
      free_text: '',
      assigneeId: '',
      attachment: [],
      issueDetail: {
        open: false,
        data: {},
      },
      sort: '',
      sort_by: 'asc',
      T011Crud: {},
      comments: [],
      fileIcons: []
    }
    this.getTableContentsApi = getTableContentsApi.bind(this);
    this.getTableSectionsApi = getTableSectionsApi.bind(this);
    this.updateTableSectionsFieldApi = updateTableSectionsFieldApi.bind(this);
    this.deleteTableSectionRowApi = deleteTableSectionRowApi.bind(this);
    this.getFormInfoApi = getFormInfoApi.bind(this);
    this.exportReport = exportReport.bind(this);
    this.updateFormStatusApi = updateFormStatusApi.bind(this);
    this.fetchActivityLogs = fetchActivityLogs.bind(this);
    this.getUserListApi = getUserListApi.bind(this);
    this.getUserStatsApi = getUserStatsApi.bind(this);
    this.moveGapIdsApi = moveGapIdsApi.bind(this)
    this.bulkAssigneeApi = bulkAssigneeApi.bind(this)
    this.fetchIssueComments = fetchIssueComments.bind(this)
    this.addIssueComments = addIssueComments.bind(this)
    this.updateIssueComment = updateIssueComment.bind(this)
    this.deleteIssueComments = deleteIssueComments.bind(this)
    this.fetchTags = fetchTags.bind(this)
    this.addTags = addTags.bind(this)
    this.fetchIssueTags = fetchIssueTags.bind(this)
    this.addIssueTags = addIssueTags.bind(this)
    this.removeIssueTags = removeIssueTags.bind(this)
    this.getFileIcons = getFileIcons.bind(this)
    this.deleteFileApi = deleteFileApi.bind(this)
    this.onDownloadAttachmentApi = onDownloadAttachmentApi.bind(this)
    this.fetchIssueDetails = fetchIssueDetails.bind(this)
    this.importExcelApi = importExcelApi.bind(this)
    this.updateTechnicalFormDataApi = updateTechnicalFormDataApi.bind(this)
    this.bulkOpsApi = bulkOpsApi.bind(this)
    this.getAssigneeListApi = getAssigneeListApi.bind(this)
    this.getAssigneeFilterListApi = getAssigneeFilterListApi.bind(this)
    this.getCreatedByListApi = getCreatedByListApi.bind(this)
  }
  componentDidMount() {
    this.getUserListApi(this.props);
    this.getAssigneeListApi(this.props);
    this.getCreatedByListApi(this.props)
    this.getFormInfoApi(this.props);
    this.getUserStatsApi(this.props);
    this.fetchTags(this.props);
    this.getAssigneeFilterListApi(this.props)
    this.getFileIcons();
    let location = browserHistory.getCurrentLocation()
    if (location.query.issueId !== undefined && location.query.issueId !== null && location.query.issueId !== '') {
      this.fetchIssueDetails()
      this.getTableContentsApi(this.props, { per_page: 2000 }, 'init');
    } else {
      this.getTableContentsApi(this.props, {}, 'init');
    }
    if (getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas) {
      this.props.usageDetail()
    }
    this.setState(prevState => ({
      ...prevState,
      operatorFlag: getLocalStorageInfo().user.security_groups.filter(item => item.id == 16).length ? true : false
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.tab_name !== nextProps.params.tab_name) {
      // this.getTableSectionsApi(nextProps, { section: this.state.t011ReportInfo.list.filter(item => item.name === nextProps.params.tab_name).length ? this.state.t011ReportInfo.list.filter(item => item.name === nextProps.params.tab_name)[0].id : ""})
      this.getTableSectionsApi(nextProps, { section: nextProps.params.tab_name }, 'init')
      this.setState({
        filter: {
          priority: '',
          disposition: '',
          assignee: ''
        }
      })
    }
  }

  fetchSectionInformation = (sectionId) => {
    if (parseInt(sectionId) != 0) {
      // browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/t011/${this.state.t011ReportInfo.list.filter(item => item.id === sectionId)[0].name}`)
      browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/t011/${sectionId}`)
    } else {
      browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/technical/${this.props.params.workOrderSlug}/forms/${this.props.params.formSlug}/t011`)
      this.getTableContentsApi(this.props, {}, 'init')
    }
  }
  onChangeField = (value, keyParam, index) => {
    this.setState(prevState => ({
      ...prevState,
      t011SectionInfo: {
        ...prevState.t011SectionInfo,
        list: prevState.t011SectionInfo.list.map((section, sectionIndex) => sectionIndex === index ? {
          ...section, [keyParam]: value
        } : section)
      }
    }))
  }

  onFieldChange = (event, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      T011SectionCrud: {
        ...prevState.T011SectionCrud,
        [keyParam]: value
      }
    }))
    if (keyParam == 'assignee') {
      this.getAssigneeListApi(this.props);
    }
  }

  updateCkeditorAdd = (evt, keyParam) => {
    var value = evt.editor.getData();
    this.setState(prevState => ({
      ...prevState,
      T011SectionCrud: {
        ...prevState.T011SectionCrud,
        [keyParam]: value
      }
    }))
  }

  onFieldChangeIssueDetail = (event, keyParam, value) => {
    if (keyParam == 'assignee') {
      if (value) {
        if (this.state.assigneeList.filter(user => user.id == value.id).length || value.id == 0) {
          this.setState(prevState => ({
            ...prevState,
            issueDetail: {
              ...prevState.issueDetail,
              data: {
                ...prevState.issueDetail.data,
                [keyParam]: value
              }
            }
          }))
          setTimeout(() => this.updateTableSectionsFieldApi(this.props, 'assignee', value && value.id ? value.id : 0, this.state.issueDetail.data.id), 1000)
          this.getAssigneeListApi(this.props);
        } else {
          toastFlashMessage('The user is deactivated, you can not assign it as assignee', 'error')
        }
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        issueDetail: {
          ...prevState.issueDetail,
          data: {
            ...prevState.issueDetail.data,
            [keyParam]: value
          }
        }
      }))
    }
  }
  updateCkeditor = (evt, keyParam) => {
    var value = evt.editor.getData();
    this.setState(prevState => ({
      ...prevState,
      issueDetail: {
        ...prevState.issueDetail,
        data: {
          ...prevState.issueDetail.data,
          [keyParam]: value
        }
      }
    }))
  }
  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  toggleUploadExcelSidebar = () => {
    this.setState(prevState => ({
      ...prevState,
      uploadExcelSidebar: !prevState.uploadExcelSidebar
    }))
  }
  uploadAttachment = (files, id) => {
    const { sectionId } = this.state;
    this.updateTableSectionsFieldApi(this.props, 'files', files, this.state.issueDetail.data.id);
  }
  addAttachment = (files) => {
    if(files) {
      this.setState(prevState => ({
        ...prevState,
        attachment: Array.from(files)
      }))
    }
  }
  openDeleteModal = (id) => {
    this.setState(prevState => ({
      ...prevState,
      deleteItem: id,
      deleteModal: true,
      deleteType: 'single'
    }))
  }

  changeFilter = (value, key) => {
    let queryObj = {}
    const { filter, t011SectionInfo, sectionId } = this.state
    queryObj = {
      ...filter,
      [key]: value
    }
    this.setState(prevState => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        [key]: value
      },
      gap_report_ids: []
    }));

  }

  applyFilter = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      applyingFilter: {
        ...prevState.applyingFilter,
        [keyParam]: value
      }
    }))
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getTableSectionsApi(this.props, { ...query });
    delete applyingFilter[keyParam]
    this.setState({ applyingFilter: applyingFilter })
  }

  changePerPage = (count) => {
    const { t011SectionInfo, filter } = this.state;
    let queryParam = {
      ...filter,
      per_page: count,
      page: 1,
      section: this.state.sectionId
    }
    this.getTableSectionsApi(this.props, removeEmptyKey(queryParam));
  }

  changePagination = (type, currentPage) => {
    const { t011SectionInfo, filter } = this.state;
    let queryParam = {
      ...filter,
      page: parseInt(currentPage) + 1,
      per_page: t011SectionInfo.pagination.per_page,
    }
    this.getTableSectionsApi(this.props, removeEmptyKey(queryParam))
  }

  graphToTabMove = (title) => {

  }
  moveGapIds = (section) => {
    this.setState({ sectionMoveId: section })
    this.moveGapIdsApi(this.props, section, this.state.gap_report_ids)
  }
  assignGapIds = (assignee) => {
    this.setState({ assigneeId: assignee })
    this.bulkAssigneeApi(this.props, assignee, this.state.gap_report_ids)
  }

  handleToggleView = (tab, viewMode) => {
    trackActivity('Item Clicked', {
      page_title: 'Problem Resolution Sheet(PRS)',
      tab_name: tab,
      viewmode: viewMode
    })
    this.setState({ tabIndex: tab })
  }
  removeAttachFiles = (deleteIndex) => {
    this.setState(prevState => ({
      ...prevState,
      attachment:prevState.attachment.filter((item, index) => index !== deleteIndex)
    }))
  }
  render() {
    const { applyingFilter, importPRS, userList, userStats, hideTitleContent, activityLogs, sectionId, editableRow, t011ReportInfo, t011SectionInfo, deleteModal, deleteItem, pageLoader, openTechForm, addSectionId, tabIndex, formInfo, type, filter, prsDropdownModal, sectionMoveId, prsAssigneeModal, assigneeList, attachment, createdList, assigneeFilterList } = this.state;
    var editablePermission = false
    if (getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.work_orders && getLocalStorageInfo().user.permission.technical.work_orders.includes('U')) {
      editablePermission = true
    }
    var creatablePermission = false
    if (getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.work_orders && getLocalStorageInfo().user.permission.technical.work_orders.includes('C')) {
      creatablePermission = true
    }
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    var options
    if (!assigneeList.length) {
      options = []
    } else {
      options = assigneeFilterList.map(item => ({
        "value": item.id,
        "label": item.name
      }))
    }
    var createdListOptions
    if(!createdList.length){
      createdListOptions = []
    }else{
      createdListOptions  = createdList.map(item => ({
        "value": item.id,
        "label": item.name
      }))
    }
    let filterOptions = {};
    filterOptions = {
      ...filterOptions,
      status: {
        inputType: 'dropdown',
        placeholder: 'Please Select Status',
        title: 'Status',
        options: [{ label: 'Open', value: 0 }, { label: 'Closed', value: 1 }, { label: 'Reopen', value: 2 }],
        labelKey: 'label',
        valueKey: 'value'
      },
      priority: {
        inputType: 'dropdown',
        placeholder: 'Please Select Priority',
        title: 'Priority',
        options: [{ label: 'None', value: 0 }, { label: 'P1', value: 1 }, { label: 'P2', value: 2 }],
        labelKey: 'label',
        valueKey: 'value'
      },
      assignee: {
        inputType: 'dropdown',
        placeholder: 'Please Select Assignee',
        title: 'Assignee',
        options: [...options, { label: 'Unassigned', value: 0 }],
        labelKey: 'label',
        valueKey: 'value'
      },
      created_by: {
        inputType: 'dropdown',
        placeholder: 'Please Select Created By',
        title: 'Created By',
        options: createdListOptions,
        labelKey: 'label',
        valueKey: 'value'
      },
      tags: {
        inputType: 'dropdown',
        placeholder: 'Please Select Tags',
        title: 'Tags',
        multiple: true,
        options: this.state.tags,
        labelKey: 'name',
        valueKey: 'id'
      },
      created_at_from: {
        inputType: 'date',
        placeholder: 'Please Select Discrepancy Raised on (from)',
        title: 'Discrepancy Raised on (from)',
        maxDate: moment().subtract(1, 'days'),
        minDate: moment().subtract(25, 'years')
      },
      created_at_to: {
        inputType: 'date',
        placeholder: 'Please Select Discrepancy Raised on (to)',
        title: 'Discrepancy Raised on (to)',
        maxDate: moment(),
        minDateMessage: 'Please enter date greater than Discrepancy Raised from',
        maxDateMessage: 'Please enter date less than Discrepancy Raised on',
        minDate: applyingFilter.created_at_from ? moment(applyingFilter.created_at_from) : moment().subtract(25, 'years')
      },
      section: {
        inputType: 'dropdown',
        placeholder: 'Please Select Category',
        title: 'Category',
        options: t011ReportInfo.list,
        labelKey: 'name',
        valueKey: 'id'
      },
      part_number: {
        inputType: 'text',
        placeholder: 'Please enter Part Number',
        title: 'Part Number',
      },
      serial_number: {
        inputType: 'text',
        placeholder: 'Please enter Serial Number',
        title: 'Serial Number',
      },
      unique_code: {
        inputType: 'text',
        placeholder: 'Please enter Unique Code',
        title: 'Unique Code',
      },
      ata: {
        inputType: 'text',
        placeholder: 'Please enter ATA',
        title: 'ATA',
      },
      free_text: {
        inputType: 'text',
        placeholder: 'Please enter ATA',
        title: 'Search Text',
        hideFilter: true
      },
    }
    const graphData = {
      chart: {
        type: 'column',
        height: 500,
        options3d: {
          enabled: true,
          alpha: 10,
          beta: 10,
          viewDistance: 55,
          depth: 50
        }
      },
      title: {
        text: null
      },
      xAxis: {
        categories: t011ReportInfo.list.filter(item => item.open_item != 0 || item.close_item != 0 || item.reopen_item !== 0).map(item => item.name)
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: ''
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat: 'Total: {point.stackTotal} <br/> Open: {point.open} <br/> Reopen: {point.reopen} <br/> Closed: {point.close}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          depth: 40
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (e) => {
                this.graphToTabMove(e.point.category)
              }
            }
          }
        }
      },
      series: [{
        name: `Reopen (${t011ReportInfo.list.reduce((result, content) => result + content.reopen_item, 0)})`,
        type: 'column',
        color: '#b99897',
        data: t011ReportInfo.list.filter(item => item.open_item != 0 || item.close_item != 0 || item.reopen_item != 0).map(item => (item.name == this.state.sectiontitle ? { y: item.reopen_item, open: item.open_item, close: item.close_item, reopen: item.reopen_item } : { y: item.reopen_item, open: item.open_item, close: item.close_item, reopen: item.reopen_item }))
      }, {
        name: `Open (${t011ReportInfo.list.reduce((result, content) => result + content.open_item, 0)})`,
        type: 'column',
        color: '#67a0ff',
        data: t011ReportInfo.list.filter(item => item.open_item != 0 || item.close_item != 0 || item.reopen_item != 0).map(item => (item.name == this.state.sectiontitle ? { y: item.open_item, open: item.open_item, close: item.close_item, reopen: item.reopen_item } : { y: item.open_item, open: item.open_item, close: item.close_item, reopen: item.reopen_item }))
      }, {
        name: `Closed (${t011ReportInfo.list.reduce((result, content) => result + content.close_item, 0)})`,
        type: 'column',
        color: '#49e6cf',
        data: t011ReportInfo.list.filter(item => item.open_item != 0 || item.close_item != 0 || item.reopen_item != 0).map(item => (item.name == this.state.sectiontitle ? { y: item.close_item, open: item.open_item, close: item.close_item, reopen: item.reopen_item } : { y: item.close_item, open: item.open_item, close: item.close_item, reopen: item.reopen_item }))
      }],
      exporting: {
        chartOptions: {
          title: {
            text: (formInfo.asset ? 'MSN ' + formInfo.asset.msn : ' ') + ' - ' + (formInfo.project ? 'Technical: ' + formInfo.project.name : ' ') + ' PRS Report Summary'
          }
        },
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      },
      credits: {
        enabled: false
      }
    }

    const graphDataUserStats = {
      chart: {
        type: 'column',
        height: 300,
        options3d: {
          enabled: true,
          alpha: 10,
          beta: 10,
          viewDistance: 55,
          depth: 50
        }
      },
      title: {
        text: "User Wise Stats"
      },
      xAxis: {
        categories: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened !== 0).map(item => item.user.name)
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: ''
        },
        labels: {
          formatter: function () {
            return this.value;
          }
        }
      },
      tooltip: {
        headerFormat: '<b>{point.key}</b><br>',
        pointFormat: 'Total: {point.stackTotal} <br/> Open: {point.open} <br/> Reopen: {point.reopen} <br/> Closed: {point.close}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          depth: 40
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (e) => {
                this.graphToTabMove(e.point.category)
              }
            }
          }
        }
      },
      series: [{
        name: 'Reopen',
        type: 'column',
        color: '#b99897',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened != 0).map(item => ({ y: item.reopened, open: item.open, close: item.closed, reopen: item.reopened }))
      }, {
        name: 'Open',
        type: 'column',
        color: '#67a0ff',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened != 0).map(item => ({ y: item.open, open: item.open, close: item.closed, reopen: item.reopened }))
      }, {
        name: 'Closed',
        type: 'column',
        color: '#49e6cf',
        data: userStats.length && userStats.filter(item => item.open != 0 || item.closed != 0 || item.reopened != 0).map(item => ({ y: item.closed, open: item.open, close: item.closed, reopen: item.reopened }))
      }],
      exporting: {
        chartOptions: {
          title: {
            text: (formInfo.asset ? 'MSN ' + formInfo.asset.msn : ' ') + ' - ' + (formInfo.project ? 'Technical: ' + formInfo.project.name : ' ') + ' PRS Report Summary'
          }
        },
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      },
      credits: {
        enabled: false
      }
    }
    const graphPieData = {
      chart: {
        type: 'pie',
        height: 300,
      },
      title: {
        text: null
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          depth: 35,
          showInLegend: true,
          dataLabels: {
            enabled: false,
            format: '{point.name}'
          }
        }
      },
      series: [{
        name: 'Item',
        colorByPoint: true,
        data: [{
          name: `Open (${t011ReportInfo.list.reduce((result, content) => result + content.open_item, 0)})`,
          color: '#67a0ff',
          y: t011ReportInfo.list.reduce((result, content) => result + content.open_item, 0),
          sliced: true,
          selected: true
        }, {
          name: `Closed (${t011ReportInfo.list.reduce((result, content) => result + content.close_item, 0)})`,
          color: '#49e6cf',
          y: t011ReportInfo.list.reduce((result, content) => result + content.close_item, 0)
        },
        {
          name: `Reopen (${t011ReportInfo.list.reduce((result, content) => result + content.reopen_item, 0)})`,
          color: '#b99897',
          y: t011ReportInfo.list.reduce((result, content) => result + content.reopen_item, 0)
        }],
      }],
      exporting: {
        chartOptions: {
          title: {
            text: (formInfo.asset ? 'MSN ' + formInfo.asset.msn : ' ') + ' - ' + (formInfo.project ? 'Technical: ' + formInfo.project.name : ' ') + ' PRS Report Summary'
          }

        },
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      },
      credits: {
        enabled: false
      }
    }
    console.log('applyingFilter', applyingFilter)
    return (
      <div className="technical-inspection-form-v1">
        <MSNSwitcherHeader
          SecodaryHeaderRH={<SecondaryTechHeaderRH projectInfo={formInfo.project} />}
          SecondaryHeaderMid={<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset} />}
          activeAsset={formInfo.asset}
          projectSlug={Object.keys(formInfo).length ? formInfo.project.slug : ''}
        />
        <FormHeader
          formInfo={formInfo}
          exportReport={<ExportFiles exportFile={(fileType) => this.exportReport(formInfo.asset)} exportName={'Export Report '} files={[{ img: 'excel_icon.png', label: 'Excel', type: 'xls' }]} />}
          showUploadLogs={() => this.setState({ toggleUploadLogs: true })}
          toggleDownloadedLogs={this.toggleDownloadedLogs}
          showActivity={true}
          exportActivityLogName="Deleted Logs"
          showActivityLogs={() => this.setState({ addSectionId: 0 }, () => this.fetchActivityLogs())}
          updateFormStatus={(data) => this.updateFormStatusApi(data)}
          importReport={() => this.setState({ importPRS: true })}
          showImport={(t011SectionInfo.authorized_operator == undefined || t011SectionInfo.authorized_operator === 0 || t011SectionInfo.authorized_operator == null) && (formInfo.current_form && formInfo.current_form.user_security_group !== 'operator')}
        />
        {pageLoader ? <ListLoader /> : null}
        <div className="tabs-header-blk">
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={7} sm={6} md={6} className="left-blk">
              <h4>Problem Resolution Sheet</h4>
            </Grid>
            <Grid item xs={5} sm={6} md={6} className="right-blk">
              <img onClick={() => {
                this.getTableContentsApi(this.props, {}, 'init'); this.getTableSectionsApi(this.props, {}, 'init');
                trackActivity('T011', { event_type: 'Item Clicked', page_title: 'PRS', item_type: 'Refresh Icon', event_desc: 'Clicked on Refresh icon' })
              }}
                className="refresh-icon" src={refreshIcon}
                alt="Refresh"
              />
              <span className="right-border"></span>
              <ul className="list-inline">
                <li className={`list-inline-item ${tabIndex == 0 ? 'active' : 'inactive-bg'}`} onClick={() => { this.handleToggleView(0, 'List View'); trackActivity('T011', { event_type: 'Item Clicked', page_title: 'PRS', item_type: 'List View Icon', event_desc: 'Clicked on List View icon' }) }}>
                  <img src={tabIndex == 0 ? imgStoragePath + 'regular_list.svg' : imgStoragePath + 'regular_list_inactive.svg'} alt="Icon" />
                </li>
                <li className={`list-inline-item ${tabIndex == 1 ? 'active' : 'inactive-bg'}`} onClick={() => { this.handleToggleView(1, 'Graph View'); trackActivity('T011', { event_type: 'Item Clicked', page_title: 'PRS', item_type: 'Analytics Icon', event_desc: 'Clicked on Analytics View icon' }) }}>
                  <img src={tabIndex == 1 ? imgStoragePath + 'pie-chart_active.svg' : imgStoragePath + 'pie-chart.svg'} alt="Icon" />
                </li>
              </ul>
            </Grid>
            <Grid container alignItems="center" spacing={3}>
              <Grid item md={12}>
                <HeaderCountCards data={t011ReportInfo.items} />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="list-header" style={tabIndex === 1 ? { display: 'none' } : this.state.issueDetail.open ? { margin: '10px 0px 0px 0px' } : {}}>
          {
            !this.state.issueDetail.open ?
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={12} md={8} className="left-blk">
                  {
                    !this.state.issueDetail.open ?
                      <IssueListMenu refreshList={() => {
                        this.getTableContentsApi(this.props, {}, 'init');
                        this.getTableSectionsApi(this.props, {}, 'init');
                      }}
                        applyFilter={(value) => this.getTableSectionsApi(this.props, { status: value })}
                        applySearch={() => this.getTableSectionsApi(this.props, removeEmptyKey({ ...this.state.filter, free_text: this.state.free_text }))}
                        removeSearch={() => this.setState({ free_text: "" }, () => this.getTableSectionsApi(this.props, removeEmptyKey(this.state.filter)))}
                        onChangeSearch={(event) => this.setState({ free_text: event.target.value })}
                        totalCount={t011SectionInfo.pagination.total}
                        free_text={this.state.free_text}
                      />
                      : <a style={{ color: '#3f51b5' }} href="#" onClick={() => {
                        let location = browserHistory.getCurrentLocation()
                        let query = location.query
                        delete query.issueId
                        location = {
                          ...location,
                          query: query
                        }
                        browserHistory.push(location)
                        this.setState({ issueDetail: { open: false, data: {} } })
                      }}>Back to Issues</a>
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  {
                    !this.state.issueDetail.open ?
                      <ul className="list-inline right-blk">
                        {
                          formInfo && formInfo.project && formInfo.project.leads && formInfo.project.leads.length > 0 && formInfo.project.leads.filter(user => user.name.trim().toLowerCase() === getLocalStorageInfo().user.name.toLowerCase()).length > 0 && formInfo.current_form && formInfo.current_form.status
                            && ![0, 4, 5, 6, 7].includes(formInfo.current_form.status.id) ?
                            <li className="list-inline-item">
                              <span onClick={() => { this.setState({ deleteType: 'prs', deleteModal: true }); trackActivity('T011', { event_type: 'Item Clicked', page_title: 'PRS', item_type: 'Delete Entire PRS Button', event_desc: 'Clicked on Delete Entire PRS Button' }) }}><img alt="Delete Icon" className="delete-icon" src={imgStoragePath + 'delete_red.png'} />Entire PRS</span>
                            </li>
                            : null
                        }
                        {
                          // this.state.gap_report_ids.length && editablePermission ?
                          // <li className="list-inline-item">
                          //   <span onClick={() => this.setState({deleteType: 'multiple'}, () => this.setState({deleteModal: true}))}><img alt="Delete Icon"  className="delete-icon" src={imgStoragePath + 'delete_red.png'} /> Selected Rows({this.state.gap_report_ids.length})</span>
                          // </li>
                          // :null
                        }
                        {
                          formInfo.current_form && formInfo.current_form.status && ![0, 4, 5, 6, 7].includes(formInfo.current_form.status.id) && creatablePermission ?
                            <li className="list-inline-item">
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => { this.setState({ openTechForm: true, addSectionId: parseInt(sectionId), formTabIndex: 0, hideTitleContent: true }); trackActivity('T011', { event_type: 'Item Clicked', page_title: 'PRS', item_type: 'Add New Issue Button', event_desc: 'Clicked on Add New Issue Button' }) }}
                              >
                                Add New Issue
                              </Button>
                            </li>
                            : null
                        }
                      </ul>
                      : null
                  }
                </Grid>
              </Grid>
              : null
          }
        </div>
        <EditFormBar
          titleContent={<h4 style={{ fontSize: '18px' }}>Add New Issue</h4>}
          toggleEditSideBar={() => this.setState({ openTechForm: false, attachment:'', T011SectionCrud : T011SectionCrud })}
          isOpen={openTechForm}
          style={openTechForm ? { width: '800px' } : { width: '0px' }}
        >
          <div className="sidebar-form">
            {openTechForm ?
              <TechnicalInspectionFormV1
                closeTechForm={() => this.setState({ openTechForm: false, attachment:'', T011SectionCrud : T011SectionCrud })}
                sectionId={addSectionId}
                onFieldChange={(event, keyParam, value) => this.onFieldChange(event, keyParam, value)}
                updateCkeditor={(event, keyParam) => this.updateCkeditorAdd(event, keyParam)}
                exportReport={() => this.exportReport(formInfo.asset)}
                sectionIds={sectionIds}
                t011ReportInfo={t011ReportInfo}
                tabIndex={this.state.formTabIndex}
                refreshList={() => this.getTableContentsApi(this.props)}
                closeModalOnReturn={() => this.setState({ openTechForm: false })}
                updateTitleContent={(flag, tabIndex) => this.setState({ hideTitleContent: flag, formTabIndex: tabIndex })}
                formInfo={formInfo}
                dropdownMenuList={[{ id: 0, name: 'Table of Content' }, ...t011ReportInfo.list]}
                operatorFlag={this.state.operatorFlag}
                itemData={this.state.T011SectionCrud}
                updateTechnicalFormDataApi={(props, payload, id, update) => this.updateTechnicalFormDataApi(props, payload, id, update)}
                getTableContentsApi={() => this.getTableContentsApi(this.props)}
                userList={[{ id: 0, name: 'None' }, ...assigneeList]}
                editablePermission={editablePermission}
                addAttachment={(files) => this.addAttachment(files)}
                attachment={attachment}
                removeAttachFiles={(deleteIndex) =>  this.removeAttachFiles(deleteIndex)}
              />
              : null
            }
          </div>
        </EditFormBar>
        {
          tabIndex === 0 && this.state.gap_report_ids.length && formInfo.current_form && formInfo.current_form.status && ![0.4, 5, 6, 7].includes(formInfo.current_form.status.id) && !this.state.operatorFlag ?
            <div className="bulk-operation-menu-blk">
              <Grid container alignItems="center" spacing={0}>
                <Grid item xs={12}>
                  <BulkOperationMenu
                    editablePermission={editablePermission}
                    modules={t011ReportInfo.list}
                    deleteMultiple={() => this.setState({ deleteType: 'multiple' }, () => this.setState({ deleteModal: true }))}
                    moveGapIds={(section) => this.moveGapIdsApi(this.props, section, this.state.gap_report_ids)}
                    assignGapIds={(assignee) => this.assignGapIds(assignee)}
                    users={assigneeList.length ? [{ id: 0, name: 'Unassigned' }, ...assigneeList] : []}
                    bulkOpsApi={(key, value) => this.bulkOpsApi(this.props, this.state.gap_report_ids, key, value)}
                    closeBulkOps={() => this.setState({ gap_report_ids: [] })}
                  />
                </Grid>
              </Grid>
            </div> : null
        }
        <div className="issues-listing-blk" style={tabIndex === 1 ? { display: 'none' } : this.state.issueDetail.open ? { margin: '0px' } : {}}>
          {
            !this.state.issueDetail.open ?
              <FilterUIComp
                filter={filter}
                applyingFilter={applyingFilter}
                removeFilter={this.removeFilter}
                removeAllFilter={() => this.setState({ applyingFilter: {} }, () => this.getTableSectionsApi(this.props, {},))}
                submitFilter={() => { this.getTableSectionsApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), per_page: t011SectionInfo.pagination.per_page }, 'pageLoader') }}
                applyFilter={(keyParam, value) => this.applyFilter(keyParam, value)}
                filterMenu={filterOptions}
              />
              : null
          }
          {
            this.state.issueDetail.open ?
              <IssueDetail
                formInfo={formInfo}
                operatorFlag={this.state.operatorFlag}
                deleteIssue={() => this.openDeleteModal(this.state.issueDetail.data.id)}
                getIssueDetail={(item) => this.setState({ issueDetail: { open: true, data: item } }, () => {
                  this.fetchIssueComments(item.id)
                  this.fetchIssueTags(this.props)
                  let location = browserHistory.getCurrentLocation()
                  location = {
                    ...location,
                    query: {
                      issueId: item.id
                    }
                  }
                  browserHistory.push(location)
                })}
                props={this.props}
                fileIcons={this.state.fileIcons}
                previewFile={(file) => this.setState({ filePreview: { open: true, data: file } })}
                pagination={t011SectionInfo.pagination}
                moveNextPage={() => this.changePagination(null, t011SectionInfo.pagination.page)}
                issuesList={t011SectionInfo.list}
                deleteFileApi={(deleteFileId) => this.deleteFileApi(deleteFileId)}
                onDownloadAttachment={(fileId) => this.onDownloadAttachmentApi(this.props, fileId)}
                userList={userList}
                assigneeList={assigneeList}
                comments={this.state.comments}
                tags={this.state.tags}
                issueTags={this.state.issueTags}
                addTags={(tag) => this.addTags(this.props, tag)}
                removeIssueTags={(tagid) => this.removeIssueTags(this.props, tagid)}
                addIssueComment={(item_id, comment) => this.addIssueComments(item_id, comment)}
                updateIssueComment={(item_id, comment, commentId) => this.updateIssueComment(item_id, comment, commentId)}
                deleteIssueComments={(item_id, commentId) => this.deleteIssueComments(item_id, commentId)}
                issueDetail={this.state.issueDetail.data}
                onFieldChange={this.onFieldChangeIssueDetail}
                t011ReportInfo={t011ReportInfo}
                updateCkeditor={this.updateCkeditor}
                uploadAttachment={(files) => this.uploadAttachment(files, this.state.issueDetail.data.id)}
                editIssue={() => this.setState({ openTechForm: true, T011Crud: this.state.issueDetail.data, addSectionId: this.state.issueDetail.data.gap_report_section.id })}
                onCloseIssueModal={() => { this.setState({ issueDetail: { modal: false, data: {} } }, () => this.getTableSectionsApi(this.props, {...filter})); browserHistory.push(this.props.location.pathname) }}
                updatePRSSectionsField={(key, data) => this.updateTableSectionsFieldApi(this.props, key, data, this.state.issueDetail.data.id)}
                editablePermission={editablePermission}
              />
              : <TableListComp
                heads={tableContentHd}
                noRecord={t011SectionInfo.list.length > 0 && !this.state.pageLoader ? null : <NoRecordFound description="There are lots of issues to be fetched. Wait up for few more seconds." />}
                bulkOperation= {formInfo?.current_form?.permissions?.can_save ? this.state.t011SectionInfo.list.length > 0 && formInfo.current_form && formInfo.current_form.status && ![0, 4, 5, 6, 7].includes(formInfo.current_form.status.id) && !this.state.operatorFlag: false}
                bulkFlag={this.state.t011SectionInfo.list.length && this.state.gap_report_ids.length === this.state.t011SectionInfo.list.length}
                toggleBulkOps={() => this.setState({ gap_report_ids: this.state.gap_report_ids.length === this.state.t011SectionInfo.list.length ? [] : this.state.t011SectionInfo.list.map(gapList => gapList.id) })}
                createSortHandler={(key) => this.setState({
                  sort: key,
                  sort_by: this.state.sort_by === 'asc' ? 'desc' : 'asc',
                }, () => this.getTableSectionsApi(this.props, {per_page:t011SectionInfo.pagination.per_page}))}
                sort={this.state.sort}
               
                onChangePage={(type, currentPage) => this.changePagination(type, currentPage)}
                pagination={t011SectionInfo.pagination}
                sort_by={this.state.sort_by}
                onChangeRowsPerPage={(...args) => this.getTableSectionsApi(this.props, removeEmptyKey({ page: 1, per_page: args[1].props.value }))}
                data={t011SectionInfo && t011SectionInfo.list && t011SectionInfo.list.length ?
                  t011SectionInfo.list.map((item, index) =>
                    <IssueList
                      item={item}
                      formInfo= {formInfo}
                      checked={this.state.gap_report_ids.indexOf(item.id) !== -1}
                      index={index}
                      bulkOps={formInfo.current_form && formInfo.current_form.status && ![0, 4, 5, 6, 7].includes(formInfo.current_form.status.id) && !this.state.operatorFlag}
                      deleteIssue={() => this.openDeleteModal(item.id)}
                      selectItem={() => this.setState({ gap_report_ids: this.state.gap_report_ids.indexOf(item.id) !== -1 ? this.state.gap_report_ids.filter(gapId => gapId !== item.id) : [...this.state.gap_report_ids, item.id] })}
                      userList={userList}
                      issueDetail={() => this.setState({ issueDetail: { open: true, data: item } }, () => {
                        this.fetchIssueComments(item.id)
                        this.fetchIssueTags(this.props)
                        this.setState({
                          t011SectionInfo: {
                            list: [],
                            pagination: {}
                          }
                        }, () => this.getTableContentsApi(this.props, { ...filter, per_page: 2000 }, 'init'))
                        let location = browserHistory.getCurrentLocation()
                        location = {
                          ...location,
                          query: {
                            issueId: item.id
                          }
                        }
                        browserHistory.push(location)
                      })}
                    />
                  ) : ""}
              />
          }
        </div>
        <EditFormBar
          titleContent={<div><h4 style={{ fontSize: '18px' }}>{this.state.filePreview.data.name}</h4></div>}
          toggleEditSideBar={() => this.setState({ filePreview: { open: false, data: {} } })}
          isOpen={this.state.filePreview.open}
          style={this.state.filePreview.open ? { width: '100%' } : { width: '0px' }}
        >
          {
            this.state.filePreview.open ?
              <div style={{ padding: '20px' }}>
                <FilePreview
                  fileInfo={this.state.filePreview.data}
                  thumbnail={false}
                />
              </div>
              : null
          }
        </EditFormBar>
        <div className="charts-blk" style={tabIndex === 0 ? { display: 'none' } : {}}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <h4 className="graph-head">Issue Status</h4>
              <TaskStatusChart
                graphPieData={graphPieData}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="user-stats">
              <h4 className="graph-head">Team Status</h4>
              <UserStatsChart
                graphDataUserStats={graphDataUserStats}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h4 className="graph-head">Category Wise Issue Status</h4>
              <TaskStatusProgressChart
                graphData={graphData}
              />
            </Grid>
          </Grid>
        </div>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? { width: '600px' } : { width: '0px' }}
        >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} /> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={<div><h3 style={{ textTransform: 'capitalize' }}>Upload Logs</h3></div>}
          toggleEditSideBar={() => this.setState({ toggleUploadLogs: false })}
          isOpen={this.state.toggleUploadLogs}
          style={this.state.toggleUploadLogs ? { width: '400px' } : { width: '0px' }}
        >
          {this.state.toggleUploadLogs ?
            <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px' }}>
              <ul className="list-unstyled" >
                {formInfo.current_form.upload_report.map((user, index) =>
                  <li style={{ borderBottom: '1px solid #d7d7d7', padding: '8px 0', fontSize: '14px' }} key={index}>{user.user}, <b style={{ fontSize: '15px' }}>{moment(user.created_at).format(displayDateTimeFormatShort) + ' UTC'}</b></li>
                )}
              </ul>
            </div> : null
          }
        </EditFormBar>
        <EditFormBar
          titleContent={
            <div style={{ marginTop: '10px' }}>
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item" style={{ width: '20%' }}>
                  <h3 style={{ textTransform: 'capitalize' }}>Deleted Logs for</h3>
                </li>
                <li className="list-inline-item" style={{ width: '75%' }}>
                  <Select
                    name="form-field-name"
                    value={addSectionId}
                    options={[{ id: 0, name: 'All' }, ...t011ReportInfo.list]}
                    onChange={(section) => this.setState({ addSectionId: section.id }, () => this.fetchActivityLogs({ gap_report_section_id: this.state.addSectionId }))}
                    labelKey="name"
                    valueKey="id"
                    style={{ border: 'none', borderBottom: '1px solid #717171', borderRadius: '0' }}
                  />
                </li>
              </ul>
            </div>
          }
          toggleEditSideBar={() => this.setState({ showActivityLogs: false, addSectionId: 0 })}
          isOpen={this.state.showActivityLogs}
          style={this.state.showActivityLogs ? { width: '800px' } : { width: '0px' }}
        >
          <div className="sidebar-form" style={{ padding: '0 20px 10px' }}>
            <input placeholder="type to search" style={{ border: "1px solid rgb(176 168 168)", padding: '10px', borderRadius: '5px', marginBottom: '10px' }} type="text" onChange={(event) => this.setState({ searchLog: event.target.value })} value={this.state.searchLog} />
          </div>
          {this.state.showActivityLogs ?
            <div className="sidebar-form" style={{ height: (window.innerHeight - document.getElementById('sidebar-header').clientHeight + 5) + 'px' }}>
              <ul className="list-unstyled">
                {
                  activityLogs && activityLogs.length ?
                    this.state.searchLog.trim() === '' ?
                      activityLogs.map((log, logIndex) => <ActivityLog log={log} key={`activity_log_${logIndex}`} />)
                      : activityLogs.filter(log => log.toLowerCase().includes(this.state.searchLog.toLowerCase().trim())).map((log, logIndex) => <ActivityLog log={log} key={`activity_log_${logIndex}`} />)
                    : <NoRecordFound />
                }
              </ul>
            </div> : null
          }
        </EditFormBar>
        <DeleteModal isOpen={deleteModal} toggle={() => this.setState({ deleteModal: false })} title={this.state.deleteType === 'single' ? "Are you sure to delete ?" : this.state.deleteType === 'section' ? `Are you sure to empty ${t011ReportInfo.list.filter(sec => parseInt(sec.id) === parseInt(sectionId)).length > 0 ? t011ReportInfo.list.filter(sec => parseInt(sec.id) === parseInt(sectionId))[0].name : 'selected'}?` : this.state.deleteType === 'prs' ? `Are you sure to empty complete PRS?` : 'Are you sure to delete selected rows?'} className="modal-tform-delete-pictures modal-dialog-centered">
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => { this.setState({ deleteModal: false }); this.deleteTableSectionRowApi(this.props, deleteItem, sectionId, this.state.deleteType) }}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.setState({ deleteModal: false })}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <DeleteModal isOpen={importPRS} toggle={() => this.setState({ importPRS: false, importError: "" })} title="Import PRS Report" style={{ height: '300px' }}>
          <div className="drop-zone-area">
            <ContentDrop
              className="drag-drop-modal"
              mutlipleEnabled={false}
              acceptExtensions={'.xls, .xlsx'}
              error={this.state.importError}
              filesUpload={(evt) => {
                if (!['xls', 'xlsx'].includes(evt.target.files[0].name.substr(evt.target.files[0].name.lastIndexOf('.') + 1).toLowerCase())) {
                  this.setState({ importError: 'Only xls and xlsx files are allowed.' })
                  return
                }
                this.setState({ importError: '' })
                this.importExcelApi(evt.target.files)
              }}
              content="Drag & Drop Or Click here to choose PRS Report"
            />
          </div>
        </DeleteModal>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  storageUsage: state.sharedReducers.storageUsage,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    usageDetail: () => dispatch(usageDetailAc()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT011v1);
