import React, { Fragment, Component, useState, useEffect } from 'react';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemAvatar, ListItemText, Checkbox, ListItemSecondaryAction, CircularProgress, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Info } from '@material-ui/icons';
const AssignEngineer = ({form, engineerAssigned, onSelectAllForms, toggleModalFn, assignUserToForm, selectProjectEngineerFn, engineerAssignedLoader}) => {
  return(
    <Dialog
        maxWidth={'md'}
        open={engineerAssigned.modal}
        onClose={toggleModalFn}
        aria-labelledby="scroll-dialog-title"
        className='project-management-modal'
    >
        <DialogTitle id="scroll-dialog-title">
            {`Assign ${ form =='t012' ||engineerAssigned?.formSlug ? 'Form User(s)':'Lead(s)'}`}
            <span>
                <Tooltip title={engineerAssigned?.formSlug || form =='t012' ?
                <div style={{width:"250px"}}>The Project Engineer is responsible for updating the information in the assigned forms and submitting the same for review.' </div> :
                <div style={{width:"250px"}}>The Project Lead is responsible for overseeing project activities, reviewing and approving the submitted forms and publishing the same.</div> } arrow> 
                    <Info style={{fontSize:'14px', marginLeft:"3px", marginTop:'3px'}}/>
                </Tooltip>

            </span>
        </DialogTitle>
        <DialogContent dividers={true}>
        <List>
            {(() => {
                if(engineerAssigned?.engineers?.length){
                  return (
                      engineerAssigned.engineers.map((item, index) =>
                      <ListItem key={index} role={undefined} dense button>
                          <ListItemAvatar>
                              <Avatar alt='' src={item.profile_pic} />
                          </ListItemAvatar>
                          <ListItemText id={item.id} primary={item?.user_id?.name||''} />
                          <ListItemSecondaryAction>
                          <Checkbox
                              color="primary"
                              edge="end"
                              onChange={(e) => selectProjectEngineerFn(e.target.checked ,item)}
                              checked={engineerAssigned?.selectedEngineer.includes(item?.user_id?.id)}
                          />
                          </ListItemSecondaryAction>
                      </ListItem>
                      )
                  )
                }else{
                  return(
                    <p style={{textAlign:'center'}}>No User(s) Found</p>
                  )
                }
            })()}
        </List>
        </DialogContent>
        <DialogActions>
            <div>
                <div style={{float:'right'}}>
                    <Button onClick={toggleModalFn} color="primary" size="small">Cancel</Button>
                    {/* { engineerAssigned?.selectedEngineer?.length ? */}
                      <Button disabled={engineerAssignedLoader ?true: engineerAssigned?.engineers?.length ?false: true} variant="contained" onClick={assignUserToForm} color="primary" size="small">
                          {engineerAssignedLoader ? <CircularProgress size={24} /> : 'Save' }
                      </Button>
                      {/* :null } */}
                </div>
                {engineerAssigned?.formSlug ?
                    <p style={{clear:'both',textAlign:'right'}}>
                        <Checkbox checked={engineerAssigned.assign_to_all} onChange={(e) => onSelectAllForms(e.target.checked)} color='primary' size="small" />
                        Assign to all Form
                    </p>:null
                }
                <div>
                {engineerAssigned.assign_to_all?
                <p style={{background:'yellow', fontSize:'10px', width:"216px", textDecoration:'underline'}}>The selected users will update all forms or replace the existing users of the form if any users are assigned.</p>:null
                 }
                 </div>
            </div>
        </DialogActions>
    </Dialog>
  )
}
export default AssignEngineer;
