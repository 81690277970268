import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell, Checkbox, Tooltip } from '@material-ui/core';
import deleteIcon from '../../../../../shared/assets/img/delete_icon.svg';
import { ToolTipHover } from '../../../../../shared'
import moment from 'moment'
import { displayDateFormatShort } from '../../../../../constants';
const IssueList = ({item, userList, issueDetail, checked, selectItem, deleteIssue, bulkOps,formInfo}) => {
  const [readMore, setReadMore] = useState(false)
  return(
    <TableRow hover tabIndex={-1} className="listing-row">
      {
        bulkOps ?
       formInfo?.current_form?.permissions?.can_save?
        <TableCell>
          {
            bulkOps ?
            <>
            <Checkbox size="small" checked={checked} color="primary" onClick={() => selectItem()}/>
              <img width="10" src={deleteIcon} alt='Delete Icon'
                onClick={() => deleteIssue()}
                />
              </>
            :null
          }
        </TableCell>
          : null
        :null
      }
      <TableCell width="250" onClick={() => issueDetail()}>
        {
          item.status && item.status.value === 0 ?
          <div className="flex-centered">
            <Tooltip title={item.title} arrow>
              <div className="issue-desc">
                <span className="issue-no open-issue" >
                  {item.unique_code}
                </span> {item.title ? item.title : '--'}
              </div>
            </Tooltip>
          </div>
          :null
        }
        {
          item.status && item.status.value === 2 ?
          <div className="flex-centered">
            <Tooltip title={item.title} arrow>
              <div className="issue-desc">
                <span className="issue-no reopen-issue" >
                  {item.unique_code}
                </span> {item.title ? item.title : '--'}
              </div>
            </Tooltip>
          </div>
          :null
        }
        {
          item.status && item.status.value === 1 ?
          <div className="flex-centered">
            <Tooltip title={item.title} arrow>
              <div className="issue-desc">
                <span className="issue-no closed-issue" >
                  {item.unique_code}
                </span> {item.title ? item.title : '--'}
              </div>
            </Tooltip>
          </div>
          :null
        }
        </TableCell>
        <TableCell width="250" onClick={() => issueDetail()}>
          {item.files.length} File(s)
        </TableCell>
        <TableCell onClick={() => issueDetail()}>{item.gap_report_section && item.gap_report_section.name ? item.gap_report_section.name : '--'}</TableCell>
        <TableCell onClick={() => issueDetail()}>{item.priority && item.priority.label ? item.priority.label : '--'}</TableCell>
        <TableCell width="400" onClick={() => issueDetail()}>
          <p><span style={{wordBreak: 'break-all'}} dangerouslySetInnerHTML={{__html: item.description.length > 80 ? item.description.substring(0,50) : item.description}}></span></p>
        </TableCell>
        <TableCell onClick={() => issueDetail()}>
          { item.status && item.status.value === 0 ?
           <span className="status-open">{item.status.label}</span> : null }

           { item.status && item.status.value === 1 ?
           <span className="status-close">{item.status.label}</span> : null}

           { item.status && item.status.value === 2 ?
           <span className="status-reopen">{item.status.label}</span> : null}
        </TableCell>
        <TableCell onClick={() => issueDetail()} id={`issueList_${item.id}`}>{item.status_update_by && item.status_update_by.length ? item.status_update_by[0].updated_by.name : '--'}</TableCell>
        <TableCell onClick={() => issueDetail()}>{item.assignee && item.assignee.name ? item.assignee.name : '--'}</TableCell>
        <TableCell onClick={() => issueDetail()}>{item.started_date ? moment.utc(item.started_date).format(displayDateFormatShort) : '--'}</TableCell>
        <TableCell onClick={() => issueDetail()}>{item.created_by && item.created_by.name ? item.created_by.name : '--'}</TableCell>
        <TableCell onClick={() => issueDetail()}>{item.part_number ? item.part_number : '--'}</TableCell>
        <TableCell onClick={() => issueDetail()}>{item.serial_number ? item.serial_number : '--'}</TableCell>
        {
          item.status_update_by && item.status_update_by.length && item.status_update_by[0].from_status && item.status_update_by[0].to_status ?
          <ToolTipHover placement="bottom" tagetId={`issueList_${item.id}`}>
            {item.status_update_by[0].from_status.label} to {item.status_update_by[0].to_status.label}
          </ToolTipHover>
          :null
        }
    </TableRow>
  )
}
export default IssueList;
