import React, { Component } from 'react';
import { Link } from 'react-router';
import { Collapse, CardBody, Card } from 'reactstrap';
import { dateTransformAllSet, getLocalStorageInfo, toastFlashMessage} from '../../../../utils'
import { imgStoragePath, displayDateFormat } from '../../../../constants';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../shared/assets/img/view_icon.svg';
import moment from 'moment';
import { ToolTipHover } from '../../../../shared';
import revisionHistoryIcon from '../../../../shared/assets/img/revision_history_icon.svg';
export default class AssetInfoWrapper extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  shareWorkOrderRpt = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let copyText = 'https://'+ window.location.hostname +'/techical/asset/report/'+this.props.projectViewData.share_key;
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = copyText;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    toastFlashMessage('Copied to clipboard', 'success');
  }
  render(){
    const { projectViewData } = this.props;
    return(
      <div className="project-view-asset">
        <table style={this.state.collapse ? {boxShadow: '0 1px 2px rgba(0,0,0,0.3)'}:{}} className="header-info" onClick={this.toggle}>
          <tbody>
            <tr>
              <td style={{width: '360px'}}>
                <img className="asset-type" src={imgStoragePath +  (projectViewData.asset.type == 1 ? 'icon_Aircraft.png':  (projectViewData.asset.type == 2) ? 'icon_engine.png' :  projectViewData.asset.type == 3 ? 'icon_apu.svg' :  projectViewData.asset.type == 4 ? 'icon_lg.svg' : projectViewData.asset.type == 5 ? 'icon_propeller.svg' : '')} alt="" />
                <div className="progess-info-widget">
                  <label className="for-label">
                    { projectViewData.asset.type == 1 ? projectViewData.asset.aircraft_type ? projectViewData.asset.aircraft_type : '': projectViewData.asset.engine_type ? projectViewData.asset.engine_type:''}{projectViewData.asset.registration_number ? ` / Reg. No. : ${projectViewData.asset.registration_number}` : ''}
                  </label>
                  <h4>
                    { projectViewData.asset.type == 1 ? 'MSN ': projectViewData.asset.type == 2 ? 'ESN ' :  projectViewData.asset.type == 3 ? 'APU ' :  projectViewData.asset.type == 4 ? 'LG ' : 'PROPELLER ' }
                    { projectViewData.asset.type == 1 ? projectViewData.asset.msn: projectViewData.asset.type == 2 ? projectViewData.asset.esn : projectViewData.asset.serial_number}
                    { this.props.showEdit && this.props.archiveStatus == 0 && getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.work_orders && getLocalStorageInfo().user.permission.technical.work_orders.includes('U') ?
                      <Link
                       onClick= {(e) => projectViewData.asset.type != 2 ? this.props.workorderEdit(e):this.props.esnEdit(e)}
                         className="edit-asset"
                       >
                       <img className="" src={editIcon} />Add Forms
                     </Link>
                      :null
                    }
                    { projectViewData.status.id == 7 ? <span onClick={this.shareWorkOrderRpt} style={{fontSize: '12px', color:'#3f51b5', fontFamily: 'Conv_IBMPlexSans-Regular', paddingLeft: '10px'}}> <img src={imgStoragePath + 'share_workorder_icon.png'} alt="" style={{width: '13px', position:'relative', top: '-1px'}} /> Share </span>:null}
                  </h4>
                  <p id={ 'workorder'+ projectViewData['id']}><span style={{width:projectViewData.status && projectViewData.status.label == "Completed" ? "100%" : projectViewData.progress+"%" }}></span></p>
                  <ToolTipHover placement="top" tagetId={ 'workorder'+ projectViewData['id']}>
                    {projectViewData.status && projectViewData.status.label == "Completed"? "100%":projectViewData.progress+"%"  }
                  </ToolTipHover>
                </div>
              </td>
              <td style={{width:'150px', textAlign:'center'}}>
                <label className="for-label">Forms</label>
                <h6>{ projectViewData.forms.length}</h6>
              </td>
              <td style={{width:'225px', display:'none'}}>
                <label className="for-label">Last Activity</label>
                <h6>{ projectViewData.updated_at ? dateTransformAllSet(projectViewData.updated_at):'--'}</h6>
              </td>
              <td  style={{width:'132px', display:'none'}}>
                <label className="for-label">Status</label>
                <h6>{ projectViewData.status && projectViewData.status.label ? projectViewData.status.label:'--'}</h6>
              </td>
              <td >
                <span onClick={(event) => this.setState({ collapse: this.state.collapse }, () => this.props.getRevisionHistory())} style={{cursor: 'pointer', color: '#3f51b5', fontSize: '14px', right:'40px', textTransform:'uppercase' }}><img width="15" src={revisionHistoryIcon} alt="export"  style={{marginRight:'3px'}}/>Revision History</span>
              </td>
              <td align="center">
                <img className="collapse-img" style={this.state.collapse ? { transform: 'rotate(0deg)'} : { transform: 'rotate(180deg)' }} src={imgStoragePath + 'up_arrow.png'} alt="img"/>
              </td>
            </tr>
          </tbody>
        </table>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody>
              {this.props.children}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    )
  }
}
