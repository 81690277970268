import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { Button, Grid, TableRow, TableCell, Avatar, Tooltip, IconButton, Chip, Menu, MenuItem, TextField, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { Link, browserHistory } from 'react-router';
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { CircularProgress } from '../../../shared_elements';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { displayDateFormatShort, fieldDateFormat, backendDateFormat } from '../../../constants';
import { onCheckPermission,getLocalStorageInfo } from '../../../utils_v2'
import config from '../../../config'
import { projectsSelfPermission } from '../'
import { castleAirInstance } from '../../../shared_elements/components';
const assetType = { 1: 'MSN', 2: 'ESN', 3: 'APU', 4: 'LG', 5: 'Propeller' }
const ProjectStatus = ({item, projectsInfo, onChangeProjectStatus}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [projectStatus, setProjectStatus] = useState({modal:true, data:null})
  const handleClose = () => {
      setAnchorEl(null);
  };
  const handleClick = (status) => {
      handleClose();
  };
  console.log('item',item);
  return(
    <>
        <span className='flex-centered' onClick={(event) => setAnchorEl(event.currentTarget)}>
          <span className={`project-status ${item.archive_status === 1 ? 'archived' : item.status.label.toLowerCase()}`}>
            <span>
              {item.archive_status === 1 ? 'Archived' : item.status.label }
            </span>
            { item?.permissions?.status || onCheckPermission('project_management','projects','STS') && projectsSelfPermission('projects','STS', item, projectsInfo?.permission?.status?.value||null) && item.status.id !== 8 ?
              <ArrowDropDownIcon fontSize="small" />:null
            }

          </span>
        </span>
        { item?.permissions?.status || onCheckPermission('project_management','projects','STS') && projectsSelfPermission('projects','STS', item, projectsInfo?.permission?.status?.value||null) && item.status.id !== 8 ?
            <Menu
                id={`simple-menu_${item.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                { item.status.id !== 7 && item.status.id !== 0 ? <MenuItem onClick={() => {handleClick(item); onChangeProjectStatus({status:{id:8, label:'Cancelled'}, reason:'', end_date:null}) }}>Cancel</MenuItem>:null}
                { item.status.id !== 0 && item.status.id !== 7 ? <MenuItem onClick={() => {handleClick(item); onChangeProjectStatus({status:{id:0, label:'Archived'}, reason:'', end_date:null}) }}>Archived</MenuItem>:null}
                { item.status.id !== 7 && item.status.id !== 0 ? <MenuItem onClick={() => {handleClick(item); onChangeProjectStatus({status:{id:7,label:'Completed'}, reason:'', end_date:null}) }}>Complete</MenuItem>:null }
                { item.status.id === 7 || item.status.id == 0 ? <MenuItem onClick={() => {handleClick(item); onChangeProjectStatus({status:{id:3, label:'Draft'}, reason:'', end_date:null}) }}>Draft</MenuItem>:null}
            </Menu>:null
        }
    </>
  )
}
export default function ProjectList({ item, editProject ,toggleModalFn, projectsInfo, onChangeProjectStatus}) {
  const [open, setOpen] = useState(false);

  const [lessMore, setLessMore] = useState({});

  const toggleLessMore = (id) => {
      setLessMore({
          ...lessMore,
          [id]: !lessMore[id],
      });
  };

  const lessMoreFn = (name) => {
      return <>
          {
          name && name.length > 35 ? (
              <>
                  {lessMore[name] ?
                      (<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..less </span> </>)
                      :
                      (<>{name.substring(0, 35)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..more </span> </>)
                  }
              </>
          ) : name || '--'
          }
      </>
  }
  const rowClick = ()=> browserHistory.push(`/technical/project/view/${item.slug}`)
  const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 )
  return (
    <TableRow  hover tabIndex={-1}>
      <TableCell style={{ width: '280px' }} onClick={rowClick}>
        <span className={`project-status-bar ${item.archive_status === 1 ? 'archived' : item.status.label.toLowerCase()}`}></span>
        <div className="flex-centered" style={{display:'flex', flexFlow:"row"}} >
          <Tooltip title={`${item.progress}%`} arrow>
            <CircularProgress value={item.progress > 100 ? 100 : item.progress} />
          </Tooltip>
          <div style={{ marginLeft: '6px', width: '220px' }}>
            <span className="project-no">{`#${item.project_number}`}</span>
            <h4 className="project-name">{item.name}</h4>
          </div>
        </div>
        {(() => {
          const assetArray = item.assets.length > 3 ? open ? item.assets : item.assets.slice(0, 3) : item.assets;
          return (
            <Fragment>
              {
                assetArray.map((asset) => {
                  const workOrder = asset.work_order_number
                  const labelType = assetType[asset.asset_type];
                  const serialNo = asset.asset_type === 1 ? asset.msn : asset.asset_type === 2 ? asset.esn : asset.serial_number;
                  return (<Chip label={
                    <div>
                      {`${labelType}:${serialNo}`}
                      <br />
                      {workOrder ? 'WO No. ' + workOrder : ''}
                    </div>
                  } size="small" variant="outlined" style={{padding:'15px 0px'}} />)
                })
              }
              {item.assets.length > 3 ? <Chip color="primary" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOpen(!open) }} clickable={true} label={!open ? `+${item.assets.length - 3} More` : 'Show Less'} size="small" /> : null}
            </Fragment>
          )
        })()}
      </TableCell>
      {smbcInstance || castleAirInstance ? <TableCell style={{ maxWidth: '190px' }}> {lessMoreFn(item?.project_remarks)} </TableCell>: null}
      <TableCell onClick={rowClick}><div style={{ width: '140px' }}>{item.location}</div></TableCell>
      <TableCell onClick={rowClick}>{item.lessee && item.lessee.logo ? <img style={{ maxWidth: '50px' }} src={item.lessee.logo} alt="" /> : item?.lessee?.name ? item?.lessee?.name : '--'}</TableCell>
      <TableCell onClick={rowClick}>{item.start_date ? moment(item.start_date).format(displayDateFormatShort) : 'TBD'}</TableCell>
      <TableCell onClick={rowClick}>{item.expected_end_date ? moment(item.expected_end_date).format(displayDateFormatShort):'TBD'}</TableCell>
      <TableCell>
        <ProjectStatus
          item={item}
          projectsInfo={projectsInfo}
          onChangeProjectStatus={onChangeProjectStatus}
        />
      </TableCell>
      <TableCell className='actions-cell'>
        <ul className="list-inline" style={{ display: "flex" }}>
          <li className="list-inline-item">
            {item?.permissions?.update  || onCheckPermission('project_management', 'projects', 'U') && projectsSelfPermission('projects', 'U', item, projectsInfo?.permission?.update?.value||null) && item.archive_status !== 1 && (item.status && item.status.id && ![7, 8].includes(item.status.id)) ?
              <Tooltip title="Edit" arrow>
                <EditIcon onClick={(e) => { e.preventDefault(); e.stopPropagation(); editProject() }} color="primary" fontSize="small" />
              </Tooltip> : null
            }
          </li>
          <li className="list-inline-item"><Link to={`/technical/project/view/${item.slug}`}>
            <Tooltip title="View" arrow>
              <VisibilityIcon color="primary" fontSize="small" />
            </Tooltip>
          </Link></li>
          {item?.permissions?.delete || onCheckPermission('project_management', 'projects', 'D') && projectsSelfPermission('projects', 'D', item, projectsInfo?.permission?.delete?.value||null) ?
            <li className="list-inline-item">
              <Tooltip title="Delete" arrow>
                <DeleteOutlinedIcon color='secondary' onClick={toggleModalFn} />
              </Tooltip>
            </li>:null
          }
        </ul>
      </TableCell>

    </TableRow>
  )
}
