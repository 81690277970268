import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment'
const AppliedFilter = ({filter, filterMenu, removeFilter}) => {
  const getFilterValue = (keyParam) => {
  if(keyParam !== undefined && filterMenu[keyParam] !== undefined){
    if(filterMenu[keyParam].inputType === 'text'){
      return (
        <li className="list-inline-item flex-centered">
          <span className="filter-label">{filterMenu[keyParam].title}:</span>
          <span className="filter-data">{filter[keyParam]}</span>
          <span className="remove-filter" onClick={() => removeFilter(keyParam)}>
            <CancelIcon fontSize="small" />
          </span>
        </li>
      )
      }else if (filterMenu[keyParam].inputType === 'dropdown') {
        if(filterMenu[keyParam].multiple){
          let option = filterMenu[keyParam].options.filter(item =>   filter[keyParam].split(',').includes(item[filterMenu[keyParam].valueKey].toString()));
          return(
            <li className="list-inline-item flex-centered">
              <span className="filter-label">{filterMenu[keyParam].title}:</span>
              {option.map((item, index) =>
                <span className="filter-data"> {item[filterMenu[keyParam].labelKey]} { index === option.length-1 ? '':','}</span>
              )}
              <span className="remove-filter" onClick={() => removeFilter(keyParam)}>
                <CancelIcon fontSize="small" />
              </span>
            </li>
          )
        }else{
          let option = filterMenu[keyParam].options.find(item => item[filterMenu[keyParam].valueKey] === filter[keyParam]);
          return (
            <li className="list-inline-item flex-centered">
              <span className="filter-label">{filterMenu[keyParam].title}:</span>
              <span className="filter-data">
              {option[filterMenu[keyParam].labelKey]}
              </span>
              <span className="remove-filter" onClick={() => removeFilter(keyParam)}>
                <CancelIcon fontSize="small" />
              </span>
            </li>
          )
        }
      }else if (filterMenu[keyParam].inputType === 'date') {
        return(
          <li className="list-inline-item flex-centered">
            <span className="filter-label">{filterMenu[keyParam].title}:</span>
            <span className="filter-data">
              {
                window.location.href.includes('t011') ?
                moment(filter[keyParam]).format('DD MMM, YYYY')
                :filter[keyParam]
              }
            </span>
            <span className="remove-filter" onClick={() => removeFilter(keyParam)}>
              <CancelIcon fontSize="small" />
            </span>
          </li>
        )
      }
    }
  }
  return(
    <div className="filter-applied">
      <ul className="list-inline" style={{display:'flex'}}>
        {Object.keys(filter).map((keyParam, index) =>  getFilterValue(keyParam))}
      </ul>
    </div>
  )
}
export default AppliedFilter;
