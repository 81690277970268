import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Button, Paper, Table, TableBody, Grid, TableHead, TableRow, TableCell } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DeletePopUp, EmptyCollection, ExportManu, PageLoader, FilterComponent } from '../../../shared_elements';
import { FormSkeletonLoader, FormWrapper } from '../../Elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { FindingHd, FindingRow, FindingCRU, Attachments, ImportTemplate} from '../components';
import { checkApiStatus ,downloadFileType,getLocalStorageInfo} from '../../../utils_v2';
import { globalGetService, globalDeleteService, globalPostService,globalPutService } from '../../../utils_v2/globalApiServices';
import { globalStaticExportService } from '../../../globalServices';

import { trackActivity } from '../../../utils/mixpanel';
import { findingObj, result } from '../'
import bluePrint from '../../assets/images/B_787_8.gltf'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ViewListIcon from '@material-ui/icons/ViewList';
const PictorialFindings = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [openShortTable, setOpenShortTable] = useState(false);
    const [tabIndex, setTabIndex] = useState('table')
    const [formLoader, setFormLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [currentFormDetail, setCurrentFormDetail] = useState(null);
    const [pictorialFindings, setPictorialFindings] = useState({list:[], sections:[]});
    const [addEditFinding, setAddEditFinding] = useState({modal:false, mode:'', data:null});
    const [editAttachments, setEditAttachments] = useState({modal:false, data:null})
    const [deleteFinding, setDeleteFinding] = useState({modal:false, data:null});
    useEffect(() => {
        getCurrentFormDetail('formLoader');
        getFindings({}, 'skeletonLoader');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'T007 Form'
        });

        window.addEventListener('sectionClick', (data) => {
            console.log(data)
           alert('inside sectionClick')
           setAddEditFinding({modal:true, mode:'view', data:{}})
        })
    },[]);
    const getCurrentFormDetail = (loaderType) => {
        if(loaderType){setFormLoader(true)}
        globalGetService(`technical/workorder/${params.workOrderSlug}/forms/`,{form:params.formSlug})
        .then(response => {
            if(loaderType){setFormLoader(false)}
            if(checkApiStatus(response)){
                setCurrentFormDetail(response.data.data)
            }
        })
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Updated',
                    page_title: 'T007 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    success_msg: response.data.message
                  });
            }else{
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Update Failed',
                    page_title: 'T007 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                  });
            }
        });
    }
    
    const getFindings = (query={}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/findings/`,query)
        .then(response => {
            if(checkApiStatus(response)){
                setPictorialFindings(response.data.data);
                if(tabIndex === 'blueprint'){
                    setTimeout(() => {
                        onPlotHotSpots(response.data.data.list)
                    },300)   
                }
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    const getFinding = (item) => {
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/findings/${item.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setEditAttachments({modal:true, data:response.data.data})
                setAddEditFinding({...addEditFinding, data:response.data.data})
            }
        })
    }
    const hideHotSpots=()=>{
        document.querySelectorAll(".hotspot").forEach(e => e.remove());
        const elements = document.getElementsByClassName('remove_after_move');
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }
    const allowDrop = (event) => {
        event.preventDefault();
    }

    //2d load sections on images
    const plotSections = (sections) => {
        var nonPositionedTable=document.querySelector('#nonPositionedSections');
        var hide_no_data=false
        var tr=''
        var viewer2D = document.querySelector('#aircraft2d');
        var viewer_hotspots ='';
        if(sections?.length){
            sections.map((item, index) => {
                var hotspot_id=`section-${index}`;
                if (item.position_x == null || item.position_y == null){
                    hide_no_data=true
                    var hotspot=`<button id="${hotspot_id}" class="section-hotspot existing-section-hotspot" draggable="true" ondragstart='event.dataTransfer.setData("text", event.target.id)' data-section_id=${item.id} title=${item.name} >${index+1}</button>`
                    tr +=`<tr><td><span>${item.name}</span></td><td>${hotspot}</td></tr>`
                }else{
                    var left_css= item.position_x + 'px';
                    var top_css= item.position_y + 'px';
                    if (document.getElementById(hotspot_id)) {
                        document.getElementById(hotspot_id).remove()
                    }

                    var hotspot=`<button onclick="window.dispatchEvent(new CustomEvent('sectionClick'));" id="${hotspot_id}" style="position:absolute;top:${top_css};left:${left_css}" class="section-hotspot existing-section-hotspot" draggable="true" ondragstart='event.dataTransfer.setData("text", event.target.id)' data-section_id=${item.id} title=${item.name} >${index+1}</button>`
                    viewer_hotspots +=hotspot
                }
            })
            nonPositionedTable.innerHTML=''
            var th=`<tr><th colspan='2'>Unpositioned Sections </th></tr>`
            nonPositionedTable.insertAdjacentHTML('afterbegin', th)
            if (!hide_no_data){
                var tr=`<tr id='noSections'><td colspan='2' style='text-align:center'>-</td></tr>`
            }
            nonPositionedTable.insertAdjacentHTML('beforeend', tr)
            viewer2D.insertAdjacentHTML('beforeend', viewer_hotspots)
        }
    }


// Adds Camera Views - ability to animate camera framing
    const onHotSpotClicked = (hotspot_id,item,is_on_model=false) => {
        var modelViewer = document.querySelector("#modelblock");
        setAddEditFinding({modal:true, mode:'view', data:item})
        var hotspot=document.querySelector("#"+hotspot_id);
        if (is_on_model){
            let camAngle = hotspot.dataset.orbit; //Pulls orbit values from clicked hotspot
            let hsPos = modelViewer.queryHotspot(hotspot.slot).position.toString();//Pulls world-space position values from clicked hotspot
            modelViewer.cameraTarget = hsPos;//Moves camera to clicked hotspot world-space position
            modelViewer.cameraOrbit = camAngle;//Orbits camera to pre-defined angle stored in clicked annotation
        }

    };
    const onPlotHotSpots = (findings) => {
        hideHotSpots()
        var nonPositionedTable=document.querySelector('#nonPositionedHotspots');
        var viewer = document.querySelector('#modelblock');
        var hide_no_data=false
        if(findings?.length){
            findings.map((item, index) => {
                var hotspot=''
                var hotspot_id=`hotspot-${index}`;
                var hotspot = document.createElement('button');
                hotspot.innerHTML= index+1;
                hotspot.slot = hotspot_id;
                hotspot.id = hotspot_id;
                hotspot.title =item.finding_type;
                hotspot.classList.add('hotspot');
                hotspot.classList.add('existing-hotspot');
                hotspot.draggable = true;
                hotspot.ondragstart = function (ev) {
                    ev.dataTransfer.setData("text", ev.target.id);
                }
                hotspot.dataset.finding_id =item.id;
                if (item.viewer_position == null || item.viewer_normal == null){
                    hide_no_data=true
                    var tr = document.createElement('tr');
                    tr.addEventListener('click', () => onHotSpotClicked(hotspot_id,item,false));
                    tr.classList.add('remove_after_move');
                    var td_0 = document.createElement('td');
                    td_0.innerHTML = `<span>${item.section.name}/${item.finding_type}</span>`;
                    var td_1 = document.createElement('td');
                    td_1.append(hotspot)
                    tr.append(td_0)
                    tr.append(td_1)
                    nonPositionedTable.append(tr)
                }else{
                    document.querySelector('#noHotspots').hidden=false
                    hotspot.addEventListener('click', () => onHotSpotClicked(hotspot_id,item,false));
                    hotspot.dataset.position = item.viewer_position;
                    hotspot.dataset.normal = item.viewer_normal;
                    viewer.append(hotspot)
                }
                document.querySelector('#noHotspots').hidden=hide_no_data
            })
        }
    }
    const exportFormReport = (extension) => {
        enqueueSnackbar('T007 Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/v2/workorder/${params.workOrderSlug}/inspection-finding-export/`, {download:extension})
        .then(response => {
            downloadFileType(response, (`T007_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), extension);
            closeSnackbar();
            trackActivity('Project Management', {
                event_type: 'File Exported',
                page_title: 'T007 Form',
                project_slug: params.project_slug ? params.project_slug : '',
                file_name: `T007_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`,
                file_extension: extension,
                success_msg: 'T007 Report Downloaded'
            })
        });
    }
    const onDeleteFinding = () => {
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/findings/${deleteFinding.data.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                setDeleteFinding({modal:false, data:null});
                getFindings({}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
            
        })
    }

    const onAddFinding = (event) => {
        const viewer = document.querySelector('#modelblock');
        const rect = viewer.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const boundingRect = viewer.getBoundingClientRect();
        const positionAndNormal = viewer.positionAndNormalFromPoint(boundingRect.left + x, boundingRect.top + y);
        const { position, normal } = positionAndNormal
        setAddEditFinding({modal:true,mode:'add', data:{...findingObj, viewer_normal:normal.toString(), viewer_position:position.toString()}});
    }
    const onHoverEvent = (type, id) => {
        if(type === 'enter'){
            let element = document.getElementById(`hotspot-${id}`);
            if (element){
                element.classList.add('hotspot-hovering')
            }
        }else{
            let element = document.getElementById(`hotspot-${id}`);
            if (element){
                element.classList.remove('hotspot-hovering')
            }
        }
    }
    const updatePosition = (event,hotspotId) => {
        var viewer = document.querySelector('#modelblock');
        const rect = viewer.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const boundingRect = viewer.getBoundingClientRect();
        const positionAndNormal = viewer.positionAndNormalFromPoint(boundingRect.left + x, boundingRect.top + y);
        if (positionAndNormal == null) {
           return;
        }
        const { position, normal } = positionAndNormal
        const findingID = document.getElementById(hotspotId).dataset.finding_id
        var innerHTML= document.getElementById(hotspotId).innerHTML;
        document.getElementById(hotspotId).remove()
        var hotspot =`<button slot=${hotspotId} id=${hotspotId} class="hotspot existing-hotspot" data-position='${position.toString()}' data-normal='${normal.toString()}' >${innerHTML}</button>`
        viewer.insertAdjacentHTML('beforeend', hotspot)
        var formData = new FormData();
        formData.append('viewer_normal', normal.toString());
        formData.append('viewer_position', position.toString());
        setLoading(true);
        globalPutService(`technical/v2/workorder/${params.workOrderSlug}/findings/${findingID}/`,formData)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar('Position Updated!', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                getFindings({}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
            setLoading(false);
        })
    }

    const moveHotspot = (event) => {
        event.preventDefault();
        const hotspotId = event.dataTransfer.getData("text");
        updatePosition(event,hotspotId)

    }
    const updateSectionPosition = (event,hotspot_section_id) => {
        var viewer = document.querySelector('#aircraft2d');
        const rect = viewer.getBoundingClientRect();
        const x = event.clientX - rect.left-8;
        const y = event.clientY - rect.top-10;
        var left_css= x + 'px';
        var top_css= y + 'px';
        var title = document.getElementById(hotspot_section_id).title
        var sectionID = document.getElementById(hotspot_section_id).dataset.section_id
        var hotspot_text = document.getElementById(hotspot_section_id).innerHTML
        document.getElementById(hotspot_section_id).remove();
        var hotspot=`<button id="${hotspot_section_id}" style="position:absolute;top:${top_css};left:${left_css}" class="section-hotspot existing-section-hotspot" draggable="true" ondragstart='event.dataTransfer.setData("text", event.target.id)' data-section_id=${sectionID} title=${title} >${hotspot_text}</button>`
        viewer.insertAdjacentHTML('beforeend', hotspot)
        setLoading(true);
        globalPutService(`technical/v2/workorder/${params.workOrderSlug}/findings/sections/${sectionID}/`,{'position_x':x,'position_y':y})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar('Section Position Updated!', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                getFindings({}, 'pageLoader')
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
            setLoading(false);
        })
    }

    const moveSection = (event) => {
        event.preventDefault();
        const section_id=event.dataTransfer.getData("text")
        updateSectionPosition(event,section_id)
    }


    return(
        <section className='project-forms-section'>
            { currentFormDetail && Object.keys(currentFormDetail).length ?
                <FormWrapper 
                    formInfo={currentFormDetail} 
                    exportReportBtn={<ExportManu disabled={pictorialFindings?.list?.length ? false: true} exportReportFn={(file) => exportFormReport(file.extension)} title="Export Report" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Doc(In-development)', extension: 'docx', key:''}]} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                />:formLoader ? <FormSkeletonLoader />:null
            }
            { !skeletonLoader && pictorialFindings?.sections?.length === 0 ?
                <ImportTemplate 
                    getResponseBack={() => getFindings({})}
                />:null
            }
            {pictorialFindings?.sections?.length ?
                <div className='t007-form-section'>
                    <Paper square style={{padding:'5px 10px'}}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item md={9}>
                                <Alert severity="info">Double click on blueprint to add new Finding !</Alert>
                            </Grid>
                            <Grid item md={3}>
                                <ul className='list-inline' style={{float:'right'}}>
                                    <li className='inline-item'>
                                        <span className='blueprint-switcher'>
                                            <span style={{display:'none'}} onClick={()=>{setTabIndex('2d');setTimeout(() => {plotSections(pictorialFindings.sections)},300)}}>
                                                2D
                                            </span>
                                            <span onClick={()=>{setTabIndex('blueprint');setTimeout(() => {onPlotHotSpots(pictorialFindings.list)},300)}}>
                                                <AirplanemodeActiveIcon color={tabIndex === 'blueprint'?'primary':'default'} fontSize='small' />
                                            </span>
                                            <span onClick={()=>setTabIndex('table')}>
                                                <ViewListIcon color={tabIndex !== 'blueprint'?'primary':'default'} fontSize='small' />
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                    {tabIndex === '2d' ?
                        <div className='aircraft-blueprint'>
                            <ul className='list-inline hot-spots-re-position flex-centered'>
                                <li className='list-inline-item' style={{cursor:'pointer'}} onClick={()=> setOpenShortTable(!openShortTable)}>
                                    {openShortTable ?
                                        <ArrowForwardIosIcon fontSize='small' color='primary' />:
                                        <ArrowBackIosIcon fontSize='small' color='primary' />
                                    }
                                </li>
                            </ul>
                            <div className='missing-hot-spots-finding'>
                                <table className='nonPositionedHotspotsTable'  id='nonPositionedSections'></table>
                            </div>
                            <div  style={{ width: '50%' }}
                            id='aircraft2d'
                             onDrop={moveSection}
                                onDragOver={allowDrop}
                                >
                                <img src='https://aims-dev.ams3.cdn.digitaloceanspaces.com/others/assets/samples/istockphoto.jpg' alt="" />
                            </div>

                            {openShortTable ?
                                <div className='findings-short-preview'>
                                    <Table className='mui-table-format'>
                                        <FindingHd previewType='short' />
                                        <TableBody>
                                            {pictorialFindings.list.map((item, index) =>
                                                <FindingRow
                                                    onHoverEvent={(hoverType) => onHoverEvent(hoverType, index)}
                                                    index={index+1}
                                                    previewType='short'
                                                    item={item}
                                                    onEdit={() => setAddEditFinding({modal:true, mode:'edit', data:item})}
                                                    onDelete={() => setDeleteFinding({modal:true, data:item})}
                                                    onView={() => setAddEditFinding({modal:true, mode:'view', data:item})}
                                                    openAttachments={() => setEditAttachments({modal:true, data:item})}
                                                />
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>:null
                            }
                        </div>:null
                    }
                    {tabIndex === 'blueprint' ?
                        <div className='aircraft-blueprint'>
                           {pictorialFindings.list?.length ?
                            <ul className='list-inline hot-spots-re-position flex-centered'>
                                <li className='list-inline-item'>
                                    <Button color='secondary' size='small' variant='outlined'>Move HotSpots</Button>
                                </li>

                                <li className='list-inline-item' style={{cursor:'pointer'}} onClick={()=> setOpenShortTable(!openShortTable)}>
                                       <ViewListIcon color={openShortTable?'primary':'default'} fontSize='small' />
                                </li>
                            </ul> :null
                            }
                            <div className='missing-hot-spots-finding'>
                                <table className='nonPositionedHotspotsTable'  id='nonPositionedHotspots'>
                                    <tr>
                                        <th colSpan='2'>Unpositioned Findings </th>
                                    </tr>
                                    <tr id='noHotspots'>
                                        <td colSpan='2' style={{textAlign:'center'}}>-</td>
                                    </tr>
                                </table>
                            </div>
                            <model-viewer
                                id='modelblock'
                                className="model-viewer"
                                src={bluePrint}
                                alt="A rock"
                                camera-controls
                                onDoubleClick={onAddFinding}
                                onDrop={moveHotspot}
                                onDragOver={allowDrop}
                                interaction-prompt="none"
    //                            camera-target="10m 30m 90m"
    //                            orientation="180deg 270deg 0deg"
                                disable-tap
                                >
                            </model-viewer>
                            {openShortTable ?
                                <div className='findings-short-preview'>
                                    <Table className='mui-table-format'>
                                        <FindingHd previewType='short' />
                                        <TableBody>
                                            {pictorialFindings.list.map((item, index) =>
                                                <FindingRow 
                                                    onHoverEvent={(hoverType) => onHoverEvent(hoverType, index)}
                                                    index={index+1}
                                                    previewType='short'
                                                    item={item}
                                                    onEdit={() => setAddEditFinding({modal:true, mode:'edit', data:item})}
                                                    onDelete={() => setDeleteFinding({modal:true, data:item})}
                                                    onView={() => setAddEditFinding({modal:true, mode:'view', data:item})}
                                                    openAttachments={() => setEditAttachments({modal:true, data:item})}
                                                />
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>:null
                            }
                        </div>:null
                    }
                    { tabIndex === 'table' ?
                        <>
                            {skeletonLoader ? <STableLoader count={10} /> :
                                <>
                                    <Paper square style={{padding:'5px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item md={9}>
                                                {/* {<FilterComponent
                                                    filter={filter}
                                                    filterMenu={{}}
                                                    getResponseBack={(applyFilter) => {this.getFindings({...applyFilter}, 'pageLoader');}}
                                                />} */}
                                            </Grid>
                                            <Grid item md={3}>
                                                <ul className='list-inline' style={{float:'right'}}>
                                                    <li className='list-inline-item'>
                                                        <Button onClick={() => setAddEditFinding({modal:true, mode:'add', data:findingObj})} size='small' color='primary' variant='contained'>Add Finding</Button>
                                                    </li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <Paper>
                                        <Table className='mui-table-format'>
                                            <FindingHd previewType='brief'/>
                                            <TableBody>
                                                {pictorialFindings.list.map((item, index) =>
                                                    <FindingRow
                                                        onHoverEvent={(hoverType) => onHoverEvent(hoverType, index)}
                                                        index={index+1}
                                                        previewType='brief'
                                                        item={item}
                                                        onEdit={() => setAddEditFinding({modal:true, mode:'edit', data:item})}
                                                        onDelete={() => setDeleteFinding({modal:true, data:item})}
                                                        onView={() => setAddEditFinding({modal:true, mode:'view', data:item})}
                                                        openAttachments={() => setEditAttachments({modal:true, data:item})}
                                                    />
                                                )}

                                            </TableBody>
                                        </Table>
                                        {!pictorialFindings?.list?.length ?
                                            <div style={{textAlign:'center'}}>
                                                <EmptyCollection title='No records found'/>
                                            </div>:null
                                        }
                                    </Paper>
                                </>
                            }
                        </>:null
                    }
                </div>:null
            }
            { addEditFinding.modal ?
                <FindingCRU 
                    addEditFinding={addEditFinding}
                    toggleModalFn={() => setAddEditFinding({modal:false, mode:'', data:null})}
                    getResponseBack={() => getFindings({}, 'pageLoader')}
                    openAttachments={(finding) => setEditAttachments({modal:true, data:finding})}
                    onChangeMode={(mode) => setAddEditFinding({...addEditFinding, mode})}
                />:null
            }
            { deleteFinding.modal ?

                <DeletePopUp
                    modal={deleteFinding.modal}
                    toggleModalFn={() => setDeleteFinding({modal:false, data:null})}
                    title="Delete Finding"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={onDeleteFinding}
                />:null
            }
            {editAttachments.modal?
                <Attachments 
                    editAttachments={editAttachments}   
                    toggleModalFn={() => {setEditAttachments({modal:false, data:null}); getFindings({}, 'pageLoader')}}
                    getResponseBack={() => getFinding(editAttachments.data)}
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}

export default PictorialFindings;