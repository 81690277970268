import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import {  GetInputField } from'../../Elements';
import { checkString, checkValidDate, checkIntNumber,checkEmail, checkNumber, checkDateDiff,checkFloat } from '../../../../formValidator';
import PlacesAutocomplete from 'react-places-autocomplete';
import { imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils'
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import moment from 'moment'
class AddEditApuWorkOrder extends Component{
	constructor(props){
    super(props);
    this.state = {
			workorder:{},
      error: {},
      create_msn: false
		};
	}
	componentDidMount(){

    let data = Object.assign({}, this.props.workorder.data);
    if(this.props.workorder.type == 'add'){
      data = {
        ...data,
        start_date: this.props.projectViewData.start_date,
        expected_end_date : this.props.projectViewData.expected_end_date,
      }
    }
    this.setState({
      workorder:{...data, ...this.props.workorder.data}
    });

		setTimeout(() => {
			if(this.state.workorder.asset_type == 3){
				this.setState(prevState =>({
					...prevState,
					workorder: {
						...prevState.workorder,
						apu_id: this.state.workorder.asset && this.state.workorder.asset.slug && !this.state.create_msn  ? this.state.workorder.asset.slug : this.state.workorder.apu_id,
					}
				}))
			}else if(this.state.workorder.asset_type == 4){
				this.setState(prevState =>({
					...prevState,
					workorder: {
						...prevState.workorder,
						lg_id:  this.state.workorder.asset && this.state.workorder.asset.slug && !this.state.create_msn  ? this.state.workorder.asset.slug : this.state.workorder.lg_id,
					}
				}))
			}else if(this.state.workorder.asset_type == 5){
				this.setState(prevState =>({
					...prevState,
					workorder: {
						...prevState.workorder,
						propeller_id:  this.state.workorder.asset && this.state.workorder.asset.slug && !this.state.create_msn  ? this.state.workorder.asset.slug : this.state.workorder.propeller_id,
					}
				}))
			}
		}, 500)

	}



	updateForm = (key,value) => {

    this.setState(prevState => ({
      ...prevState,
      workorder:{
        ...prevState.workorder,
        [key]:value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }));

    if((key == 'apu_id' || key == 'lg_id' || key == 'propeller_id') && value && !this.state.create_msn){
      this.props.fetchMsnDetails(value, this.props.workorder.data.asset_type == 3 ? 'apu' : this.props.workorder.data.asset_type == 4 ? 'lg' : 'propeller');
    }
    // if(key == 'inspection_types' && value){
    //   this.props.fetchForms({'inspection_type': value});
    // }
  }
  updatejsonFormat = (key, value, type) =>{
    this.setState(prevState => ({
      ...prevState,
      workorder: {
        ...prevState.workorder,
        [type]: {
          ...prevState.workorder[type],
          [key] : value
        }
      }
    }));
  }
  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      workorder: {
        ...prevState.workorder,
        location : address
      }
    }));
	};

	handleSelect = address => {
    let location =  geocodeByAddress(address);
    location.then(results =>
      getLatLng(results[0]))
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          workorder: {
            ...prevState.workorder,
            location : address,
            lat: latLng['lat'],
            lang: latLng['lng'],
          }
      })))
      .catch(error => console.error('Error', error));
      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>   data['types'][0] == 'country'  );

          this.setState(prevState => ({
            ...prevState,
            workorder: {
              ...prevState.workorder,
                country: place.length ?  place[0]['long_name']: address
              }
            }))
           })
      .catch(error => console.error('Error', error));
  };
	 createMsn = (flag) => {
	    this.setState(prevState => ({
	      ...prevState,
	      create_msn: flag
	    	}))
	  	}
  componentWillReceiveProps(nextProps){

    let data = this.state.workorder;
    if(!nextProps.projectLoader){
      this.setState(prevState => ({
        ...prevState,
        workorder: {
          date_of_manufacture: nextProps.msnDetails &&  nextProps.msnDetails.date_of_manufacture  ? nextProps.msnDetails.date_of_manufacture : this.state.workorder.date_of_manufacture,
          tsn : nextProps.msnDetails && nextProps.msnDetails.tsn ? nextProps.msnDetails.tsn : this.state.workorder.tsn,
          csn : nextProps.msnDetails && nextProps.msnDetails.csn ? nextProps.msnDetails.csn : this.state.workorder.csn,
          status_as_of : nextProps.msnDetails && nextProps.msnDetails.status_as_of ? nextProps.msnDetails.status_as_of : this.state.workorder.status_as_of,
          operator_storage_facility : nextProps.msnDetails && nextProps.msnDetails.operator_storage_facility ? nextProps.msnDetails.operator_storage_facility : this.state.workorder.operator_storage_facility,
          aoc_regulation : nextProps.msnDetails && nextProps.msnDetails.aoc_regulation ? nextProps.msnDetails.aoc_regulation : '',
          line_number : nextProps.msnDetails && nextProps.msnDetails.line_number ? nextProps.msnDetails.line_number : '',
          forms:  nextProps.updatedForm && nextProps.updatedForm.data ? nextProps.updatedForm.data.map(data => data.id) : this.state.workorder.forms,
          inspection_types:  nextProps.updatedForm && nextProps.updatedForm.inspection_type ? nextProps.updatedForm.inspection_type  : this.state.workorder.inspection_types,
          start_date: this.state.workorder.start_date ? this.state.workorder.start_date : '',
          expected_end_date: this.state.workorder.expected_end_date ? this.state.workorder.expected_end_date : '',
          location: this.state.workorder.location ? this.state.workorder.location : '',
          slug: this.state.workorder.slug ? this.state.workorder.slug : '',
          physical_inspection_on_start_date:this.state.workorder.physical_inspection_on_start_date ? this.state.workorder.physical_inspection_on_start_date : null,
          physical_inspection_on_end_date:this.state.workorder.physical_inspection_on_end_date ? this.state.workorder.physical_inspection_on_end_date : null,
          records_inspection_on_end_date:this.state.workorder.records_inspection_on_end_date?this.state.workorder.records_inspection_on_end_date:null,
          records_inspection_on_start_date:this.state.workorder.records_inspection_on_start_date?this.state.workorder.records_inspection_on_start_date:null,
        }
      }));

      if(nextProps.workorder.data.asset_type == 3){
        this.setState(prevState =>({
          ...prevState,
          workorder: {
            ...prevState.workorder,
            apu_id: nextProps.msnDetails &&  nextProps.msnDetails.slug && !this.state.create_msn  ? nextProps.msnDetails.apu_id : this.state.workorder.apu_id,
          }
        }))
      }else if(nextProps.workorder.data.asset_type == 4){
        this.setState(prevState =>({
          ...prevState,
          workorder: {
            ...prevState.workorder,
            lg_id: nextProps.msnDetails &&  nextProps.msnDetails.slug && !this.state.create_msn  ? nextProps.msnDetails.lg_id : this.state.workorder.lg_id,
          }
        }))
      }else if(nextProps.workorder.data.asset_type == 5){
        this.setState(prevState =>({
          ...prevState,
          workorder: {
            ...prevState.workorder,
            propeller_id: nextProps.msnDetails &&  nextProps.msnDetails.slug && !this.state.create_msn  ? nextProps.msnDetails.propeller_id : this.state.workorder.propeller_id,
          }
        }))
      }
    }
  }

  workorderUpdate = (e) => {
    e.preventDefault();
    let data = this.state.workorder;
    data = {
      ...data,
      create_msn: false
    }

    let validateNewInput = {
      start_date: checkValidDate({value: this.state.workorder.start_date, required: true, minLength: '', maxLength: '', message: 'Please enter Start Date'}),
      expected_end_date: checkValidDate({value: this.state.workorder.expected_end_date, required: true, minLength: '', maxLength: '', message: 'Please enter Expected End date'}),
      expected_end_date:checkDateDiff({value:this.state.workorder.start_date,required:true},{value:this.state.workorder.expected_end_date,required:true}),
      date_of_manufacture: checkValidDate({value: this.state.workorder.date_of_manufacture, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
			inspection_types: checkString({value: this.state.workorder.inspection_types, required: true, minLength: '', maxLength: '', message: 'Please enter Inspection Types'}),
    };

    if(this.state.workorder.asset_type == 3){
      validateNewInput = {
        ...validateNewInput,
        apu_id: checkString({value: this.state.workorder.apu_id, required: true, minLength: '', maxLength: '', message: 'Please select APU'}),
      }
    }else if(this.state.workorder.asset_type == 4){
      validateNewInput = {
        ...validateNewInput,
        lg_id: checkString({value: this.state.workorder.lg_id, required: true, minLength: '', maxLength: '', message: 'Please select LG'}),
      }
    }else if(this.state.workorder.asset_type == 5){
      validateNewInput = {
        ...validateNewInput,
        propeller_id: checkString({value: this.state.workorder.propeller_id, required: true, minLength: '', maxLength: '', message: 'Please select PROPELLER'}),
      }
    }

		if(this.state.workorder.records_inspection_on_end_date && this.state.workorder.records_inspection_on_end_date.trim() != '' && parseInt(moment(this.state.workorder.records_inspection_on_end_date).diff(moment(this.state.workorder.records_inspection_on_start_date), 'days')) < 0){
			validateNewInput = {
				...validateNewInput,
				records_inspection_on_end_date: 'Record Inspection end date can not be before Record Inspection start date'
			}
		}
		if(this.state.workorder.physical_inspection_on_end_date && this.state.workorder.physical_inspection_on_end_date.trim() != '' && parseInt(moment(this.state.workorder.physical_inspection_on_end_date).diff(moment(this.state.workorder.physical_inspection_on_start_date), 'days')) < 0){
			validateNewInput = {
				...validateNewInput,
				physical_inspection_on_end_date: 'Physical Inspection end date can not be before Physical Inspection start date'
			}
		}

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {

      this.props.workOrderAddEdit(data, this.props.workorder.type);

    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

	render(){
    const { type } = this.props.workorder;
    const { msnDetails } =  this.props;
    const { workorder, error } = this.state;
		const userInfo = getLocalStorageInfo()
		return(
		  <form  className="workorder-crud-form"  onSubmit = {(e) =>  this.workorderUpdate(e)} >
        <div className="sidebar-form" style={{ height: (window.innerHeight - 70) + 'px'}}>
          <Row>
						<Col md="6">
					   {type != 'edit' ?
								<label className="radio-block" style={{marginBottom: '20px'}} >
							 		<input type="radio" name="msn-type" checked={this.state.create_msn ? false : true} onChange={() => this.createMsn(false)} />
									Link Existing MSN
								</label>:null}
						</Col>
						<Col md="6" >
							{	type != 'edit' && getLocalStorageInfo().user.permission.technical && !getLocalStorageInfo().user.permission.technical.is_saas && getLocalStorageInfo().defaultLessor.lessor_type == 1 ?
									<label className="radio-block" style={{marginBottom: '20px'}} >
										<input type="radio" name="msn-type" checked={this.state.create_msn ? true : false} onChange={() => this.createMsn(true)} />
										Create New MSN
								</label> : null}
						</Col>
            <Col md="12" style={{marginBottom: '20px'}}>
              {
                this.props.workorder.data.asset_type == 3 ?
                GetInputField(
                  'dropdown',
                  <label className="for-label">APU {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.apu_id,
                    error: error.apu_id,
                    options: this.props.msnSwitcher ? this.props.msnSwitcher.filter(data => data.asset_type == 3) : [],
                    labelKey: 'asset',
                    valueKey: 'slug',
                    isDisabled: type == 'edit' && workorder.status != 1 ? true: false,
                    updateForm: (value) => this.updateForm('apu_id', value)
                  }
                )
                :this.props.workorder.data.asset_type == 4 ?
                GetInputField(
                  'dropdown',
                  <label className="for-label">LG {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.lg_id,
                    error: error.lg_id,
                    options: this.props.msnSwitcher ? this.props.msnSwitcher.filter(data => data.asset_type == 4) : [],
                    labelKey: 'asset',
                    valueKey: 'slug',
                    isDisabled: type == 'edit' && workorder.status != 1 ? true: false,
                    updateForm: (value) => this.updateForm('lg_id', value)
                  }
                )
                :GetInputField(
                  'dropdown',
                  <label className="for-label">PROPELLER {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.propeller_id,
                    error: error.propeller_id,
                    options: this.props.msnSwitcher ? this.props.msnSwitcher.filter(data => data.asset_type == 5) : [],
                    labelKey: 'asset',
                    valueKey: 'slug',
                    isDisabled: type == 'edit' && workorder.status != 1 ? true: false,
                    updateForm: (value) => this.updateForm('propeller_id', value)
                  }
                )
              }
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Start Date {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.start_date,
                    error: error.start_date,
                    isDisabled: type == 'edit' && workorder.status >= 7 ? true: false,
                    updateForm: (value) => this.updateForm('start_date', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Expected End Date  {type != 'view'?<sup className="reqStar"> * </sup>:null}</label>,
                  { type: type,
                    value: workorder.expected_end_date,
                    error: error.expected_end_date,
                    isDisabled: type == 'edit' && workorder.status >= 7 ? true: false,
                    updateForm: (value) => this.updateForm('expected_end_date', value)
                  }
              )}
            </Col>
							<Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Lease Start Date</label>,
                  { type: type,
                    value: workorder.lease_start_date,
                    updateForm: (value) => this.updateForm('lease_start_date', value==''?null:value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">TSN</label>,
                  { type: type,
                    value: workorder.tsn,
                    error: error.tsn,
                    updateForm: (value) => this.updateForm('tsn', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">CSN</label>,
                  { type: type,
                    value: workorder.csn,
                    error: error.csn,
                    updateForm: (value) => this.updateForm('csn', value)
                  }
              )}
            </Col>
							<Col md="6" style={{marginBottom: '20px'}}>
               <label className="for-label">Location</label>
							 {
								 window.google !== undefined && window.google !== null ?
								 <PlacesAutocomplete
	                    value={workorder.location}
	                    onChange={this.handleChange}
	                    onSelect={this.handleSelect}
	                  >
	                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
	                    <div style={{position: 'relative'}}>
	                      <input
	                        {...getInputProps({
	                          placeholder: 'Search Location',
	                          className: 'location-search-input',
	                        })}
	                      />
	                      {
	                        workorder.location ?
	                        <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
	                        {loading && <div>Loading...</div>}
	                        {suggestions.map(suggestion => {
	                          const className = suggestion.active
	                            ? 'suggestion-item--active'
	                            : 'suggestion-item';
	                          // inline style for demonstration purpose
	                          const style = suggestion.active
	                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
	                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
	                          return (
	                            <div
	                              {...getSuggestionItemProps(suggestion, {
	                                className,
	                                style,
	                              })}
	                            >
	                              <span>{suggestion.description}</span>
	                            </div>
	                          );
	                        })}
	                      </div> :  null
	                      }
	                    </div>
	                  )}
	                </PlacesAutocomplete>
								 :GetInputField(
                   'string',
                   "",
                   { type: type,
                     value: workorder.location,
                     error: error.location,
                     updateForm: (value) => this.updateForm('location', value)
                   }
               )
							 }
            </Col>

						<Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label"> Date of Manufacture {type != 'view' && this.state.create_msn ? <sup className="reqStar"> * </sup>:null} </label>,
                  { type: type,
                    isDisabled: !this.state.create_msn,
                    value: workorder.date_of_manufacture,
                    error: this.state.create_msn ? error.date_of_manufacture : null,
                    updateForm: (value) => this.updateForm('date_of_manufacture', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">Line Number  </label>,
                  { type: type,
                    value: workorder.line_number ? workorder.line_number : '',
                    error: error.line_number,
                    updateForm: (value) => this.updateForm('line_number', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'date',
                  <label className="for-label">Status As Of  </label>,
                  { type: type,
                    value: workorder.status_as_of,
                    error: error.status_as_of,
                    updateForm: (value) => this.updateForm('status_as_of', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">Operator Storage Facility </label>,
                  { type: type,
                    value: workorder.operator_storage_facility,
                    error: error.operator_storage_facility,
                    updateForm: (value) => this.updateForm('operator_storage_facility', value)
                  }
              )}
            </Col>
            <Col md="6" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'string',
                  <label className="for-label">AOC Regulation  </label>,
                  { type: type,
                    value: workorder.aoc_regulation ? workorder.aoc_regulation : '',
                    error: error.aoc_regulation,
                    updateForm: (value) => this.updateForm('aoc_regulation', value)
                  }
              )}
            </Col>

     		   <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label"> Inspection Types  {type != 'view'?<sup className="reqStar"> * </sup>:null} <img width="15" style={{marginLeft:'5px',cursor:'pointer'}} onClick={()=>this.props.workOrderInfo()}src={imgStoragePath+"help_question.png"} alt="img"/> </label>,
                  { type: type,
                    value: workorder.inspection_types,
                    options: this.props.inspectionType,
                    labelKey: 'name',
                    valueKey: 'id',
										error: error.inspection_types,
                    isDisabled: type == 'edit' && workorder.status > 3 ? true:false,
                    updateForm: (value) => this.updateForm('inspection_types', value)
                  }
              )}
            </Col>
            {

            }
					 <Col md="12" style={{marginBottom: '20px'}}>
              {
                GetInputField(
                  'multiDropDown',
                  <label className="for-label"> Forms </label>,
                  { type: type,
                    value: workorder.forms,
                    options: this.props.formList,
                    labelKey: 'name',
                    valueKey: 'id',
                    isDisabled: type == 'edit' && workorder.status > 3 ? true:false,
                    updateForm: (value) => this.updateForm('forms', value)
                  }
              )}
            </Col>


			<Col md="12"  className="grouped-blocks">
				<div className="inner-grouped-block">
					<h4>Record Inspection</h4>
					<Row>
						<Col md="6">
              {
                GetInputField(
                  'date',
                  <label className="for-label">Start Date </label>,
                  { type: type,
                    value: workorder.records_inspection_on_start_date,
                    option: [],
                    minDate: workorder.start_date,
                    maxDate: workorder.expected_end_date,
                    updateForm: (value) => this.updateForm('records_inspection_on_start_date', value)
                  }
              )}
								</Col>
							<Col md="6">
								{
                GetInputField(
                  'date',
                  <label className="for-label"> End Date</label>,
                  { type: type,
                    value: workorder.records_inspection_on_end_date,
                    minDate: workorder.records_inspection_on_start_date,
                   	maxDate: workorder.expected_end_date,
                    updateForm: (value) => this.updateForm('records_inspection_on_end_date', value)
                  }
              )}
							</Col>

							</Row>
							<h6 className="error-msg">{error.records_inspection_on_end_date}</h6>


				</div>
			</Col>

			<Col md="12"  className="grouped-blocks">
				<div className="inner-grouped-block">
				<h4>Physical Inspection</h4>
				<Row>
					<Col md="6">
					{
					GetInputField(
						'date',
						<label className="for-label">Start Date </label>,
						{ type: type,
							value: workorder.physical_inspection_on_start_date,
              minDate: workorder.start_date,
              maxDate: workorder.expected_end_date,
							updateForm: (value) => this.updateForm('physical_inspection_on_start_date', value)
						}
				)}
					</Col>
					<Col md="6">
					{
					GetInputField(
						'date',
						<label className="for-label"> End Date</label>,
						{ type: type,
              value: workorder.physical_inspection_on_end_date,
              minDate: workorder.physical_inspection_on_start_date,
              maxDate: workorder.expected_end_date,
              updateForm: (value) => this.updateForm('physical_inspection_on_end_date', value)
						}
				)}
				</Col>
			</Row>
			<h6 className="error-msg">{error.physical_inspection_on_end_date}</h6>
			</div>
			</Col>
     </Row>
        </div>
        { type != 'view' ?
          <div className="edit-sidebar-footer">
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.props.projectLoader} type="submit" formNoValidate className="btn btn-primary">{this.props.projectLoader ? 'Processing...':'Save'}</button>
              </li>
              <li className="list-inline-item">
                <Link onClick={this.props.toggleEditSideBar}> Cancel</Link>
              </li>
             </ul>
          </div>:null
        }
      </form>
		)
	}
}
export default AddEditApuWorkOrder;
