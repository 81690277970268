import React, { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { Paper, Grid, Button } from "@material-ui/core";
import { TableListComp, EmptyCollection, FilterUIComp, PageLoader, DeletePopUp, Pagination } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { FormTemplate, AddEditFormTemplate } from '../components'
import { globalGetService, globalDeleteService } from '../../../utils_v2/globalApiServices'
import { removeEmptyKey, convertFilterObject, checkApiStatus, onCheckPermission,getLocalStorageInfo } from '../../../utils_v2';
import { formTemplateHd, templateFilterOptions } from '../';
import { UserManageSideBar } from "../../../User/components";
import DeploymentMessage from "../../../shared/components/DeploymentMessage";
const FormTemplates = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(true)
    const [forms, setForms] = useState([])
    const [formTemplates, setFormTemplates] = useState({list:[], pagination:{}})
    const [deleteTemplate, setDeleteTemplate] = useState({modal:false, template:null})
    const [templateId, setTemplateId] = useState(null);
    const [modal, setModal] = useState(false);
    const [filter, setFilter] = useState({});
    const [applyingFilter, setApplyingFilter] = useState({});
    const [sort, setSort] = useState('');
    const [sort_by, setSortBy] = useState('');
    useEffect(() => {
        getFormTemplates({},'skeletonLoader');
        getForms();
    }, []);
    const getForms = () => {
        globalGetService(`technical/forms/`)
        .then(response => {
            if(checkApiStatus(response)){
                setForms(response.data.data);
            }
        })
    }
    const getFormTemplates = (query={}, loaderType) => {
        if(loaderType === 'skeletonLoader'){
            setSkeletonLoader(true);
        }else{
            setLoading(true);
        }
        globalGetService(`technical/templates/`, query)
        .then(response => {
            if(loaderType === 'skeletonLoader'){
                setSkeletonLoader(false);
            }else{
                setLoading(false);
            }
            if(checkApiStatus(response)){
                setFormTemplates(response.data.data);
                delete query.sort;
                delete query.sort_by;
                delete query.page;
                delete query.per_page;
                setFilter(query);
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    const onDeleteTemplateApi = () => {
        globalDeleteService(`technical/templates/${deleteTemplate.template.id}/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                setDeleteTemplate({modal:false, template:null});
                getFormTemplates();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        })
    }
    const applyFilter = (keyParam, value, rangeKey) => {
        setApplyingFilter({...applyingFilter, [keyParam]: value});
    }
    const removeFilter = (keyParam) => {
        let query = Object.assign({}, filter);
        delete query[keyParam];
        getFormTemplates({...query, page:1, per_page:formTemplates.pagination.per_page}, 'pageLoader');
        delete applyingFilter[keyParam]
        setApplyingFilter(applyingFilter);
    }
    const createSortHandler = (sortField) => {
        let sort_order = 'asc';
        if(sortField === sort){
            sort_order = sort_by === 'asc' ? 'desc':'asc'
        }
        getFormTemplates({page:1, per_page: formTemplates.pagination.per_page, sort_by:sortField, sort:sort_order},'pageLoader')
        setSort(sortField);
        setSortBy(sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    }
    let filterOptions = {
        ...templateFilterOptions,
        type: {
          ...templateFilterOptions.type,
          options: forms.filter(i => [1,2,3,4,19,20].includes(i.id))
        }
      }
    return (
        <>
            {/* <UserManageSideBar/> */}
        <section className='projects-management-apps projects-management-section'>
            <DeploymentMessage />
            <Paper>
                <div style={{display:'flex'}}>
                    <div style={{width:'91vw'}}>
                        <FilterUIComp
                            filter={filter}
                            applyingFilter={applyingFilter}
                            removeFilter={removeFilter}
                            removeAllFilter={() => {getFormTemplates({page:1, per_page:formTemplates.pagination.per_page}, 'pageLoader'); setApplyingFilter({})}}
                            submitFilter={() => getFormTemplates({...convertFilterObject(removeEmptyKey({...applyingFilter, page:1, per_page: formTemplates.pagination.per_page}), filterOptions)}, 'pageLoader')}
                            applyFilter={applyFilter}
                            filterMenu={filterOptions}
                        />
                    </div>
                    { onCheckPermission('project_management','template','C') ?
                      <div style={{minWidth:'max-content' , marginRight:'10px', marginTop:'10px'}} >
                          <ul style={{float:'right'}} className="list-inline">
                              <li className="list-inline-item">
                                  <Button onClick={() => setModal(true)} color='primary' size='small' variant="contained"> <span style={{fontSize:'12px'}}> Add Template </span></Button>
                              </li>
                          </ul>
                      </div>:null
                    }

                </div>
            </Paper>
            { skeletonLoader ? <STableLoader count={6} /> :
                <TableListComp
                    style={{height: !formTemplates.list.length ? 'auto':`${window.innerHeight-180}px`,overflow:'auto'}}
                    heads={formTemplateHd}
                    sort={sort}
                    sort_by={sort_by}
                    data={ formTemplates.list && formTemplates.list.map((item,index) =>
                        <FormTemplate
                            key={index}
                            index={index}
                            item={item}
                            onDelete={() => setDeleteTemplate({modal:true, template:item})}
                            onEdit={() => {setModal(true); setTemplateId(item.id)}}
                        />
                    )}
                    noRecord={ formTemplates.list.length ? null:
                        <EmptyCollection
                            title="No records found"
                        />
                    }
                    pagination={formTemplates.pagination}
                    onChangePage={(event, newPage) => getFormTemplates({...filter, page:newPage+1, per_page: formTemplates.pagination.per_page, sort_by: sort_by, sort:sort}, 'pageLoader')}
                    onChangeRowsPerPage={(event) => getFormTemplates({...filter, page:1, per_page: event.target.value, sort_by: sort_by, sort:sort}, 'pageLoader')}
                    createSortHandler={createSortHandler}
                />
            }
            { modal ?
                <AddEditFormTemplate
                    modal={modal}
                    toggleModalFn={() => {setModal(false); setTemplateId(null)}}
                    getResponseBack={() => getFormTemplates()}
                    id={templateId}
                    forms={forms}
                    templates={formTemplates.list}
                />:null
            }
            <DeletePopUp
                modal={deleteTemplate.modal}
                toggleModalFn={() => setDeleteTemplate({modal:false, template:null})}
                title="Delete Form Template"
                content={<h4>Are you sure you want to delete?</h4>}
                deleteRecordFn={onDeleteTemplateApi}
            />
            { isLoading ? <PageLoader /> : null }
        </section>
        </>
    );
}
export default FormTemplates;
