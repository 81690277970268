import React, { useState } from 'react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { Box, Typography, Link } from '@material-ui/core';
import { imgStoragePath } from '../../../constants';
import { getLocalStorageInfo } from '../../../utils';
import config from '../../../config';

const TechnicalLocations = ({ google, title, markerList, height }) => {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [aircraftType, setAircraftType] = useState();
  const [assetSlug, setAssetSlug] = useState();
  const [lesseeLogo, setLesseeLogo] = useState();
  const [assets, setAssets] = useState({});

  const onMarkerClick = (map, marker) => {
    setSelectedPlace(map);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
    setAssetSlug(map.assetSlug);
    setAircraftType(map.aircraftType);
    setLesseeLogo(map.lesseeLogo);
    setAssets(map.assets);
  };

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532);

  if (!google) {
    return <div>Loading...</div>;
  }
console.log('first---',markerList)
  return (
    <div
      className="map-wrapper-block"
      style={{
        position: 'relative',
        height: (window.innerHeight - 360) + 'px',
        width: '100%',
      }}
    >
      <Map 
        google={google} 
        zoom={2.1} 
        initialCenter={{ lat: 20.0529906, lng: 64.4385803 }} 
        onClick={onMapClicked}
      >
        {markerList?.map((marker, index) => (
          <Marker 
            key={index}
            position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
            onClick={onMarkerClick}
            name={marker.name}
            aircraftType={marker.lead_by && marker.lead_by.length ? marker.lead_by[0].user.name : '--'}
            assetSlug={marker.slug}
            lesseeLogo={marker.lessee_logo}
            assets={marker.assets}
            icon={{
              url: `${imgStoragePath}${smbcInstance ? "icon_engine.png" : "mapPointer.png"}`,
              scaledSize: new window.google.maps.Size(25, 25),
            }}
          />
        ))}

        <InfoWindow className="map-tooltip-style flex-centered" marker={activeMarker} visible={showingInfoWindow}>
          <div className="map-tooltip-style flex-centered">
            <div className="inner-first-block">
              <Typography variant="h1" style={{fontSize:'14px',color:'#696969'}}>{selectedPlace.name}</Typography>
              <Typography variant="h5" style={{fontSize:'10px',color:'#9999A1',marginTop:'1px'}}>
                {aircraftType ? aircraftType.length > 15 ? 'Lead By ' + aircraftType.substring(0, 13) + '...' : 'Lead By: ' + aircraftType : null}
              </Typography>
              <Typography variant="h5"style={{fontSize:'10px',color:'#696969',marginTop:'3px'}}>
                {assets && assets.msn_count ? assets.msn_count + ' MSN' : assets && assets.msn ? 'MSN ' + assets.msn : null}
              </Typography>
              <Typography variant="h5" style={{fontSize:'10px',color:'#696969',marginTop:'1px'}}>
                {assets && assets.esn_count ? assets.esn_count + ' ESN' : assets && assets.esn ? 'ESN ' + assets.esn : null}
              </Typography>
              <a
                href={`/technical/project/view/${assetSlug}`} 
                style={{fontSize:'10px', marginTop:'5px',borderBottom:'1px solid #2789ff'}}
              >
                View
              </a>
            </div>
            {lesseeLogo && (
              <div className="inner-second-block">
                <img width='30px' style={{ marginTop: '-20px', marginLeft: '10px' }} src={lesseeLogo} alt="logo" />
              </div>
            )}
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAH2ZCLdjcGqMZeJ6IAFNT_82Y6xT2opIA"
})(TechnicalLocations);
