import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, PageLoader, EmptyCollection, DeletePopUp } from "../../../shared_elements";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import { checkApiStatus, onCheckFormPermission } from '../../../utils_v2';
import moment from "moment";
import { backendDateFormat, fieldDateFormat, displayDateFormatShort } from "../../../constants";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { regexConstants } from "../../../constants/regEx";
import { castleAirInstance } from "../../../shared_elements/components";
import { Autocomplete } from "@material-ui/lab";
import { airframeEvent, apuEvent, compName, compSubClass, engineEvent, lgEvent } from ".";
const maintainanceobj = {
    component_name: castleAirInstance?null:'',
    sl_no: '',
    shop_visit_name: castleAirInstance?null:'',
    entry_date: null,
    release_date: null,
    total_time_at_sv: '',
    total_cycle_at_sv: '',
    comments: ''
}
const MaintainanceHistoryHD = ({ currentFormDetail }) => {
    return (
        <TableHead>
            <TableRow>
                {onCheckFormPermission(currentFormDetail) ? 
                <TableCell>Action(s)</TableCell> : null}
                <TableCell>Component </TableCell>
                <TableCell>Serial Number</TableCell>
                <TableCell>Shop Visit Name</TableCell>
                <TableCell style={{ width: '115px' }}>Entry Date</TableCell>
                <TableCell style={{ width: '115px' }}>Release Date</TableCell>
                <TableCell style={{ width: '107px' }}>Total Time At Shop Visit</TableCell>
                <TableCell style={{ width: '107px' }}>Total Cycles At Shop Visit</TableCell>
                <TableCell>Comments</TableCell>
            </TableRow>
        </TableHead>
    )
}
const MaintainanceHistoryList = ({ item, onEdit, onDelete, currentFormDetail }) => {
    const [showMore, setShowMore] = useState({});
    const toggleComment = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    return (
        <TableRow>
            {onCheckFormPermission(currentFormDetail) ?
                <TableCell>
                    <ul className="list-inline" style={{ width: '60px', float: '' }}>
                        <li className="list-inline-item hover-inline" onClick={onEdit}>
                            <Tooltip title='Edit' style={{ cursor: 'pointer' }} arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                        </li>
                        <li className="list-inline-item hover-inline" onClick={onDelete}>
                            <Tooltip title='Delete' style={{ cursor: 'pointer' }} arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                        </li>
                    </ul>
                </TableCell> : null
            }
            <TableCell>
                {item?.component_name && item?.component_name?.length > 25 ? (
                    <div style={{ width: '225px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.component_name}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.component_name.substring(0, 20)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '115px' }}>{item?.component_name || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.sl_no && item?.sl_no?.length > 20 ? (
                    <div style={{ width: '200px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.sl_no}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.sl_no.substring(0, 18)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '135px' }}>{item?.sl_no || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.shop_visit_name && item?.shop_visit_name?.length > 30 ? (
                    <div style={{ width: '225px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.shop_visit_name}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.shop_visit_name.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :

                    <div style={{ width: '220px' }}>{item?.shop_visit_name || '--'}</div>
                }
            </TableCell>
            <TableCell>
                <div style={{ width: '115px' }}>{item?.entry_date ? moment(item?.entry_date).format(displayDateFormatShort) : '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ width: '115px' }}>{item?.release_date ? moment(item?.release_date).format(displayDateFormatShort) : '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ width: '142px' }}>{item?.total_time_at_sv || '--'}</div>
            </TableCell>
            <TableCell>
                <div style={{ width: '154px' }}>{item?.total_cycle_at_sv || '--'}</div>
            </TableCell>
            <TableCell>
                {item?.comments && item?.comments?.length > 30 ? (
                    <div style={{ width: '215px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.comments}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.comments.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleComment(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: '135px' }}>{item.comments || '--'}</div>
                }
            </TableCell>
        </TableRow>
    )
}
const AddEditMaintananceHistory = ({ params, addEditMaintananceHistory, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [maintanance, setMaintanance] = useState(addEditMaintananceHistory.data);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setMaintanance(prevState => ({
            ...prevState,
            [key]: value 
        }));
        if (castleAirInstance && key === 'component_name') {
            setMaintanance({ ...maintanance, [key]: value, 'shop_visit_name':null})

        }

    };
    
    const onAddEditMaintananceHistory = () => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            release_date: moment(maintanance.release_date).isValid() ? moment(maintanance.release_date).isValid() && moment(maintanance.release_date).isSameOrAfter(moment(maintanance.entry_date)) ? '' : "Release date should be equal or greater than Entry date" : '',
        }
        if (maintanance.release_date && !moment(maintanance.release_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                release_date: 'Enter valid Release date'
            }
        }
        if (maintanance.entry_date && !moment(maintanance.entry_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                entry_date: 'Enter valid Entry date'
            }
        }
        if (castleAirInstance && !maintanance.component_name) {
            validationInputs = {
                ...validationInputs,
                component_name: 'Select the Component'
            }
        }
        if (castleAirInstance && !maintanance.shop_visit_name) {
            validationInputs = {
                ...validationInputs,
                shop_visit_name: 'Select the Shop Visit Name'
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            const payload = Object.assign({}, (!maintanance.id ? { full_maintenance_history: [maintanance] } : maintanance))
            setLoading(true);
            if (maintanance.id) {
                globalPutService(`technical/workorder/${params.workOrderSlug}/t014/full-maintenance-history/${maintanance.id}/?type=T014&asset_type=${params.type}`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            } else {
                globalPostService(`technical/workorder/${params.workOrderSlug}/t014/full-maintenance-history/?type=T014&asset_type=${params.type}`, payload)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false);
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    const isFieldEmpty = () => {
        if(maintanance.id){
            return Object.entries(maintanance).filter(([key]) => key !== 'id').some(([_, value]) => value !== '' && value !== null);        
        }else{
            return Object.values(maintanance).some(value => value !== '' && value !== null);
        }
    };

    return (
        <Dialog
            open={addEditMaintananceHistory.modal}
            onClose={toggleModalFn}
            className='project-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {maintanance?.id ? 'Edit ' : 'Add '} Maintainance History
            </DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        {castleAirInstance?
                         <Autocomplete
                             options = {compName}
                             getOptionLabel={option => option}
                             id="component_name"
                             value={maintanance?.component_name||null}
                             onChange={(e,value) => {onFieldChange('component_name', value);setError({ ...error, 'component_name': '' })}}
                             renderInput={params => <TextField required error={error.component_name ? true : false} helperText={error.component_name || ''} {...params} label="Component" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                         />
                        :
                        <TextField
                            name='component_name'
                            label='Component'
                            value={maintanance?.component_name || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('component_name', e.target.value)}
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                        />
                        }
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='sl_no'
                            label='Serial Number'
                            value={maintanance?.sl_no || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('sl_no', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item md={4}>
                    {castleAirInstance?
                         <Autocomplete
                             options = {maintanance?.component_name==='AIRFRAME'?airframeEvent:maintanance?.component_name==='LG'?lgEvent:maintanance?.component_name==='APU'?apuEvent:maintanance?.component_name==='ENGINE'?engineEvent:compSubClass}
                             getOptionLabel={option => option}
                             id="component_name"
                             value={maintanance?.shop_visit_name||null}
                             onChange={(e,value) =>{ onFieldChange('shop_visit_name', value);setError({ ...error, 'shop_visit_name': '' }) }}
                             renderInput={params => <TextField required error={error.shop_visit_name ? true : false} helperText={error.shop_visit_name || ''} {...params} label="Shop Visit Name" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                         />
                        :
                        <TextField
                            name='shop_visit_name'
                            label='Shop Visit Name'
                            value={maintanance?.shop_visit_name || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('shop_visit_name', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            variant='outlined'
                        />
                    }
                    </Grid>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                name="entry_date"
                                label="Entry Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                maxDate={moment()}
                                minDate={moment().subtract(25, 'years')}
                                value={maintanance?.entry_date || null}
                                onChange={(data, value) => { onFieldChange('entry_date', moment(data).format(backendDateFormat)); setError({ ...error, 'entry_date': '' }) }}
                                inputVariant='outlined'
                                error={error.entry_date ? true : false}
                                helperText={error.entry_date || ''}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                name="release_date"
                                label="Release Date"
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment().subtract(25, 'years')}
                                value={maintanance?.release_date || null}
                                onChange={(data, value) => { onFieldChange('release_date', moment(data).format(backendDateFormat)); setError({ ...error, 'release_date': '' }) }}
                                inputVariant='outlined'
                                error={error.release_date ? true : false}
                                helperText={error.release_date || ''}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='total_time_at_sv'
                            label='Total Time At Shop Visit'
                            value={maintanance?.total_time_at_sv ? maintanance?.total_time_at_sv : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('total_time_at_sv', e.target.value) : e.preventDefault()}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            name='total_cycle_at_sv'
                            label='Total Cycles At Shop Visit'
                            value={maintanance?.total_cycle_at_sv ? maintanance?.total_cycle_at_sv : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('total_cycle_at_sv', e.target.value) : e.preventDefault()}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            name='comments'
                            label='Comment'
                            value={maintanance?.comments || ''}
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            onChange={(e) => onFieldChange('comments', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading || !isFieldEmpty()} onClick={onAddEditMaintananceHistory} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const MaintananceHistory = ({ params, getCurrentFormDetail, currentFormDetail }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [MaintananceInfo, setMaintananceInfo] = useState({ list: [], pagination: {} });
    const [addEditMaintananceHistory, setAddEditMaintananceHistory] = useState({ modal: false, data: null });
    const [deleteMaintananceHistory, setDeleteMaintananceHistory] = useState({ modal: false, data: null });
    useEffect(() => {
        getMaintananceHistory('skeletonLoader');
    }, []);

    const getMaintananceHistory = (query,loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`technical/workorder/${params.workOrderSlug}/t014/full-maintenance-history/?type=T014&asset_type=${params.type}`,query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setMaintananceInfo(response.data.data)
                    getCurrentFormDetail()
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteMaintananceHistory = () => {
        setLoading(true);
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/t014/full-maintenance-history/${deleteMaintananceHistory.data.id}/?type=T014&asset_type=${params.type}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteMaintananceHistory({ modal: false, data: null });
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    getMaintananceHistory({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    return (
        <>
            <div className="tech-specs-content">
                {skeletonLoader ? <STableLoader count={8} /> :
                    <div style={{ padding: '10px' }}>
                        <Paper square style={{ padding: '4px 8px' }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item md={9}></Grid>
                                <Grid item md={3}>
                                    <ul className="list-inline" style={{ float: 'right' }}>
                                        {onCheckFormPermission(currentFormDetail) ?
                                            <li className="list-inline-item">
                                                <Button onClick={() => setAddEditMaintananceHistory({ modal: true, data: maintainanceobj })} color="primary" size='small' variant="contained"><AddOutlinedIcon style={{ fontSize: '16px' }} />ADD</Button>
                                            </li> : null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ maxHeight: window.innerHeight - 235 + 'px', overflow: "scroll" }}>
                            <Table className='mui-table-format' stickyHeader>
                                <MaintainanceHistoryHD currentFormDetail={currentFormDetail} />
                                <TableBody>
                                    {MaintananceInfo.list?.map((item, index) =>
                                        <MaintainanceHistoryList
                                            key={index}
                                            item={item}
                                            currentFormDetail={currentFormDetail}
                                            onEdit={() => setAddEditMaintananceHistory({ modal: true, data: item })}
                                            onDelete={() => setDeleteMaintananceHistory({ modal: true, data: item })}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                        </Paper>
                        {MaintananceInfo?.pagination ?
                            <Paper>
                                <Pagination
                                    pagination={MaintananceInfo.pagination}
                                    onChangePage={(event, newPage) => getMaintananceHistory({ ...filter, page: newPage + 1, per_page: MaintananceInfo.pagination.per_page }, 'pageLoader')}
                                    onChangeRowsPerPage={(event) => getMaintananceHistory({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                />
                            </Paper>
                            : null
                        }
                        <Paper>
                            {!MaintananceInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                        </Paper>
                    </div>
                }
            </div>
            {addEditMaintananceHistory.modal ?
                <AddEditMaintananceHistory
                    params={params}
                    addEditMaintananceHistory={addEditMaintananceHistory}
                    toggleModalFn={() => setAddEditMaintananceHistory({ modal: false, data: null })}
                    getResponseBack={() => getMaintananceHistory({}, 'pageLoader')}
                /> : null
            }
            {deleteMaintananceHistory.modal ?
                <DeletePopUp
                    modal={deleteMaintananceHistory.modal}
                    title='Delete Maintainance History'
                    content={`Are you sure, you want to Delete?`}
                    toggleModalFn={() => setDeleteMaintananceHistory({ modal: false, data: null })}
                    deleteRecordFn={onDeleteMaintananceHistory}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </>

    )
}
export default withRouter(MaintananceHistory);