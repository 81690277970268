import { globalDownloadService, globalGetService, globalPostService, globalExportService, globalPutService, globalDeleteService, globalSignUpPostService, globalStaticExportService } from '../../globalServices';
import { getLocalStorageInfo, toastFlashMessage, downloadFileType, removeEmptyKey, getGlobalCookie, eraseGlobalCookie, setGlobalCookie, checkEnvironment } from '../../utils';
import { trackLogin, trackActivity } from '../../utils/mixpanel'
import * as actions from '../actions';
import { fetchPortfolioListAC } from '../../shared/actionCreators'
import { browserHistory } from 'react-router';
import { assetsType } from '../../constants'
import appConfig from '../../config';
export const loginAccess = (userInfo) => {

  return (dispatch) => {
    globalPostService('api/login/', userInfo)
      .then(response => {
        dispatch({
          type: actions.FORM_SUBMIT_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {

          dispatch({
            type: actions.FORM_SUBMIT_LOADER,
            payload: false
          });
          if (response.data.data.lessor_list.length == 0) {
            browserHistory.push('/login');
            toastFlashMessage('You don\'t have the access to any Lessor, Please contact to admin ', 'error');
          } else {
            localStorage.setItem('userInfo', JSON.stringify(response.data.data));
            if (response.data.data.lessor_list.length > 1) {
              browserHistory.push('/select-lessor');
            } else {
              dispatch(authorizationAc(response.data.data.lessor_list[0]));
            }
            toastFlashMessage('Login Successfully', 'success');
          }

        }
      })
  }
}
export const assetsListAc = (filterParams, allTypeFlag) => {
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    })
    if (allTypeFlag) {
      Promise.all([
        globalGetService('console/list/', { ...filterParams, exclude_engine_modules: true ,module:'projects'}),
        globalGetService('console/lessees/', {}),
        globalGetService('console/operators/', {}),
        globalGetService('console/aircraft-types/', {}),
        globalGetService('console/engine-types/', {})
      ])
        .then(response => {
          dispatch({
            type: actions.ASSET_LIST,
            payload: {
              assetsInfo: response[0].data.data,
              clients: response[1].data.data.lessees,
              operators: response[2].data.data.operators,
              aircraftTypes: response[3].data.data.aircraftTypes,
              engineTypes: response[4].data.data.engineTypes,
              queryFilter: filterParams,
              allTypeFlag: allTypeFlag
            }
          })
          dispatch({
            type: actions.LIST_LOADER,
            payload: false
          })
          dispatch(fetchPortfolioListAC({ status: 3 }));
          dispatch(filteredLesseeListAc({distinct:1}));
          dispatch(filteredAircraftTypeListAc({distinct:1}));
          dispatch(filteredEngTypeListAc({distinct:1}));
          // dispatch(filteredApuTypeListAc({distinct:1}))
        })
    } else {
      globalGetService('console/list/', { ...filterParams, exclude_engine_modules: true })
        .then(response => {
          dispatch({
            type: actions.LIST_LOADER,
            payload: false
          })
          dispatch({
            type: actions.ASSET_LIST,
            payload: {
              assetsInfo: response.data.data,
              allTypeFlag: allTypeFlag
            }
          })
          dispatch(fetchPortfolioListAC({ status: 3 }))
        })
    }
  }
}
export const assetViewAc = (assetList) => {
  let assetType = assetList.asset_type == 1 ? 'aircraft'
    : assetList.asset_type == 2 ? 'engine'
      : assetList.asset_type == 3 ? 'apu'
        : assetList.asset_type == 4 ? 'lg'
          : assetList.asset_type == 5 ? 'propeller'
            : assetList.asset_type == 6 ? "fan"
              : assetList.asset_type == 7 ? "hpc"
                : assetList.asset_type == 8 ? "hpt"
                  : 'lpt'
  return (dispatch) => {
    dispatch({
      type: actions.FORM_SUBMIT_LOADER,
      payload: true
    })
    globalGetService('console/' + `${assetType == 'landing-gear' ? 'lg' : assetType}` + '/' + assetList.slug + '/', {})
      .then(response => {
        dispatch({
          type: actions.FORM_SUBMIT_LOADER,
          payload: false
        })
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.ASSET_DETAIL,
            payload: response.data.data
          })
          let assetInfo = response.data.data;
          let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3, 4, 5].indexOf(assetInfo.asset_type) !== -1 ? `${assetsType[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
          if ((getLocalStorageInfo().user.permission.technical && assetInfo.is_technical_enabled && ![6, 7, 8, 9].includes(assetInfo.asset_type) && assetInfo.technical && assetInfo.technical.work_order_slug)) {
            browserHistory.push('/' + assetType + '/' + assetInfo.slug + '/technical/' + assetInfo.technical.work_order_slug + '/forms/' + assetInfo.technical.form_slug + '/' + assetInfo.technical.form)
          } else {
            browserHistory.push('/technical/projects?project_status=all')
          }
          trackActivity('Page Visited', {
            application_module: 'Console',
            asset: asset_serial_number,
            page_title: 'Assets View'
          })
        }
      })
  }
}
export const assetViewIdAc = (id, params, pageTitle, applicationModule) => {
  return (dispatch) => {
    globalGetService('console/contract/' + params.type + '/' + id + '/', {})
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.ASSET_DETAIL_ID,
            payload: response.data.data
          })
          let assetViewId = response.data.data
          let assetTypeNames = {
            3: 'APU',
            4: 'LG',
            5: 'PROPELLER'
          }
          if (pageTitle && applicationModule) {
            trackActivity('Page Visited', {
              application_module: applicationModule,
              asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3, 4, 5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
              page_title: pageTitle
            })
          }
        }
      })
  }
}


export const recordInitializeAc = (params) => {
  return (dispatch) => {
    globalGetService('records/' + params.aircraft_slug + '/initialize/', {})
      .then(response => {
        // if(response.data.statusCode == 200) {
        dispatch({
          type: actions.RECORDS_INIT,
          payload: response.data.data
        })
        // }
      })
  }
}
export const forgotPwdAc = (email) => {
  return (dispatch) => {
    globalPostService('api/password/reset/', email)
      .then(response => {
        dispatch({
          type: actions.FORM_SUBMIT_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          toastFlashMessage('Email Sent', 'success');
        }
      })
  }
}
export const resetPwdAc = (data, type) => {
  return (dispatch) => {
    dispatch({
      type: actions.FORM_SUBMIT_LOADER,
      payload: true
    });
    globalPostService('api/password/reset-confirm/', data)
      .then(response => {
        dispatch({
          type: actions.FORM_SUBMIT_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          if (type == 'reset') {
            browserHistory.push('/reset-successfully');
          } else {
            browserHistory.push('/create-successfully');
          }
          toastFlashMessage('Password reset successfully', 'success');
        }
      })
  }
}
export const downLoadAssetAc = (fileType, queryFilter) => {
  let filterParam = {
    ...queryFilter,
    download: fileType
  }
  delete filterParam['page'];
  let newFilterParam = removeEmptyKey(filterParam);

  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    });
    globalStaticExportService('console/list/', newFilterParam)
      .then(response => {
        if(getLocalStorageInfo().defaultLessor.id===442 && newFilterParam.download == 'xls'){
          toastFlashMessage("Download link for Report will be sent to your registered email", 'success')
          }else if (getLocalStorageInfo().defaultLessor.id===442 && newFilterParam.download == 'pdf') {
            downloadFileType(response, getLocalStorageInfo().defaultLessor.name + '_Projects_Management_Assets.', newFilterParam.download)
          }else{
            downloadFileType(response, getLocalStorageInfo().defaultLessor.name + '_Projects_Management_Assets.', newFilterParam.download)
          }
        dispatch({
          type: actions.LIST_LOADER,
          payload: false
        });
        trackActivity('Asset List', {
          event_type: 'Report Exported',
          file_name: getLocalStorageInfo().defaultLessor.name + '_Projects_Management_Assets.',
          extension: fileType
        })
      })
  }
}
export const authorizationAc = (lessor) => {
  let location = browserHistory.getCurrentLocation().pathname
  return (dispatch) => {
    dispatch({
      type: actions.FORM_SUBMIT_LOADER,
      payload: true
    });
    globalSignUpPostService('/api/user-authorization/', { lessor_id: lessor.id })
      .then(response => {
        dispatch({
          type: actions.FORM_SUBMIT_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          let userDetail = getLocalStorageInfo();
          userDetail = {
            ...userDetail,
            access: response.data.data.lessor_access_token,
            defaultLessor: response.data.data.current_lessor,
            user: response.data.data.user
          }
          let baseDomain = '.sparta.aero', expireAfter = new Date();
          localStorage.setItem('userInfo', JSON.stringify(userDetail));
          let cookieData = JSON.stringify({ access: response.data.data.lessor_access_token, id: response.data.data.current_lessor.id, refresh: '', environment: appConfig.env.key })
          let cookieName = JSON.stringify({ name: response.data.data.user.name, designation: response.data.data.user.designation })
          setGlobalCookie('userName', cookieName, 3)
          setGlobalCookie("lessorAccess", cookieData, 3)
          setGlobalCookie("domain", baseDomain, 3)
          let redirectURIProjects = getGlobalCookie('redirectURIProjects')
          if (redirectURIProjects !== undefined && redirectURIProjects !== null && redirectURIProjects !== '' && redirectURIProjects !== '/' && !redirectURIProjects.includes('login') && !redirectURIProjects.includes('select-lessor') && redirectURIProjects.includes('projects.sparta') && !redirectURIProjects.includes('signup') && !redirectURIProjects.includes('termscondition') && !redirectURIProjects.includes('data-policy')) {
            window.location.assign(redirectURIProjects)
          } else if (userDetail.user.user_role == 'lessee') {
            if (userDetail.user.permission.technical) {
              browserHistory.push('/fleet/technical/dashboard');
            } else {
              browserHistory.push('/technical/projects');
            }
          } else {
            if (userDetail.user.permission.technical) {
              browserHistory.push('/fleet/technical/dashboard');
            } else if (userDetail.defaultLessor.lessor_type == 4) {
              
            } else {
              if (userDetail.user.permission.technical && userDetail.user.permission.technical.dashboard && userDetail.user.permission.technical.dashboard.indexOf('R') != -1) {
                browserHistory.push('/fleet/technical/dashboard');
              } else {
                browserHistory.push('/technical/projects');
              }
            }
          }
          if (!userDetail) {
            if (!location.includes('lgoin') && !location.includes('select-lessor')) {
              setGlobalCookie('redirectURIProjects', window.location.href, 3)
            }
            window.location.reload();
          }
          setTimeout(() => trackLogin(userDetail, () => {
            window.location.reload()
          }), 500)
        }
      })
  }
}

export const AddAssetAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    });
    globalPostService('/console/add-aircraft/', data)
      .then(response => {
        dispatch({
          type: actions.LIST_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          if (Object.keys(response.data.data).length) {
            trackActivity('Add Asset', {
              event_type: 'Asset Created',
              asset_type: 'Aircraft',
              asset_name: `MSN ${response.data.data.msn}`,
              request_body: data,
              response_body: JSON.stringify(response.data.data)
            })
            dispatch(assetsListAc())
          }
          toastFlashMessage(response.data.message, 'success');
        }
        if (response.data.statusCode == 5000 || response.data.statusCode == 5001 || response.data.statusCode == 5002) {
          dispatch({
            type: actions.TOGGLE_LICENCE_MODAL,
            payload: true,
            message: response.data.message
          })
        }
      })
  }
}

export const AddEngineAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    });
    globalPostService('/console/add-engine/', data)
      .then(response => {
        dispatch({
          type: actions.LIST_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          if (Object.keys(response.data.data).length) {
            trackActivity('Add Asset', {
              event_type: 'Asset Created',
              asset_type: 'Engine',
              asset_name: `ESN ${response.data.data.esn}`,
              request_body: data,
              response_body: JSON.stringify(response.data.data)
            })
            dispatch(assetsListAc())
          }
          toastFlashMessage(response.data.message, 'success');
        }
        if (response.data.statusCode == 5000 || response.data.statusCode == 5001 || response.data.statusCode == 5002) {
          dispatch({
            type: actions.TOGGLE_LICENCE_MODAL,
            payload: true,
            message: response.data.message
          })
        }
      })
  }
}
export const AddModuleAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    });
    let moduleData = data
    moduleData = {
      ...moduleData,
      esn: data.esn + '-' + data.engine_module_type_id + " Module"
    }
    globalPostService(`/console/add-${data.engine_module_type_id.toLowerCase()}/`, moduleData)
      .then(response => {
        dispatch({
          type: actions.LIST_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          if (Object.keys(response.data.data).length) {
            trackActivity('Add Asset', {
              event_type: 'Asset Created',
              asset_type: 'Engine Module',
              asset_name: `${response.data.data.msn}`,
              module_name: data.esn + '-' + data.engine_module_type_id + " Module",
              request_body: moduleData,
              response_body: JSON.stringify(response.data.data)
            })
            dispatch(assetsListAc())
          }
          toastFlashMessage(response.data.message, 'success');
        }
        if (response.data.statusCode == 5000 || response.data.statusCode == 5001 || response.data.statusCode == 5002) {
          dispatch({
            type: actions.TOGGLE_LICENCE_MODAL,
            payload: true,
            message: response.data.message
          })
        }
      })
  }
}

export const editProfileAc = (formdata) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_PROCESSING,
      payload: true,
    })

    globalPutService('/console/user/', formdata)
      .then(response => {
        dispatch({
          type: actions.PROFILE_PROCESSING,
          payload: false,
        })
        if (response.data.statusCode == 200) {
          trackActivity('User Management', { event_type: 'Profile Updated', request_body: formdata })
          toastFlashMessage(response.data.message, "success");

          let userDetail = getLocalStorageInfo();
          userDetail = {
            ...userDetail,
            user: {
              ...userDetail.user,
              name: response.data.data.first_name + ' ' + response.data.data.last_name,
              profile_pic: response.data.data.profile_pic,
              designation: response.data.data.designation
            }
          }
          localStorage.setItem('userInfo', JSON.stringify(userDetail));

          dispatch({
            type: actions.USER_PROFILE_INFO,
            payload: response.data.data
          })
          dispatch({
            type: actions.TYPE_UPDATE,
            payload: 'view'
          })
          //   setTimeout(function() { //Start the timer
          //     window.location.reload();//After 1 second, set render to true
          // }.bind(this), 1000)

        }
      })
  }
}

export const deleteSuperGroupAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.DELETE_EXP_LOADER,
      payload: true,
    })
    globalPutService('/console/user/', data)
      .then(response => {
        dispatch({
          type: actions.DELETE_EXP_LOADER,
          payload: false,
        })
        if (response.data.statusCode == 200) {
          // dispatch(getUsetContractsAc({id: response.data.data.slug}))
          dispatch({
            type: actions.USER_PROFILE_INFO,
            payload: response.data.data
          })
        }
      })
  }
}

export const removeUserAc = (slug) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_PROCESSING,
      payload: true,
    })
    globalGetService('console/lessor-unlink-user/' + slug + '/', {})
      .then(response => {
        dispatch({
          type: actions.PROFILE_PROCESSING,
          payload: false,
        })
        if (response.data.statusCode == 200) {
          trackActivity('User Management', { event_type: 'User Removed', user_slug: slug, response_body: JSON.stringify(response.data.data) })
          toastFlashMessage(response.data.message, "success");
          browserHistory.push('/users/manage');
        }
      })
  }
}

//contractors contracts in user userCrud
export const getUsetContractsAc = (params) => {
  return (dispatch) => {
    globalGetService(`console/contract-user/${params.id}/`)
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.USER_CONTRACTS,
            payload: response.data.data
          })
        }
      })
  }
}

export const deleteUserContractAc = (params, contractId) => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_PROFILE_LOADER,
      payload: true
    })
    globalDeleteService(`console/contract-user/${params.id}/${contractId}`)
      .then(response => {
        dispatch({
          type: actions.USER_PROFILE_LOADER,
          payload: false
        })
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success')
          dispatch(getUsetContractsAc(params))
        }
      })
  }
}

export const deleteExpAc = (paramsID, expID) => {
  return (dispatch) => {
    dispatch({
      type: actions.DELETE_EXP_LOADER,
      payload: true
    })
    globalDeleteService(`console/user-details/${paramsID}/create-experience-of-act/${expID}/`)
      .then(response => {
        dispatch({
          type: actions.DELETE_EXP_LOADER,
          payload: false
        })
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success')
          dispatch({
            type: actions.DELETE_EXPERIENCE,
            payload: expID
          }
          )

        }
      })
  }
}

export const addEditExperienceAc = (params, experienceCrud, expId) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_PROCESSING,
      payload: true,
    })
    if (experienceCrud.type == 'add') {
      globalPostService(`console/user-details/${params.id}/create-experience-of-act/`, experienceCrud.data)
        .then(response => {
          dispatch({
            type: actions.PROFILE_PROCESSING,
            payload: false,
          })
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success')
            dispatch({
              type: actions.ADD_UPDATE_EXPEREIENCE,
              payload: {
                ...experienceCrud,
                flag: false,
                type: 'add',
                data: {
                  aircraft_type: '',
                  remarks: '',
                  year_of_experience: '',
                }
              },
            })
            dispatch({
              type: actions.ADD_EXPERIENCE,
              payload: response.data.data
            }
            )
          }
        })
    } else {
      globalPutService(`console/user-details/${params.id}/create-experience-of-act/${expId}/`, experienceCrud.data)
        .then(response => {
          dispatch({
            type: actions.PROFILE_PROCESSING,
            payload: false,
          })
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success')
            dispatch({
              type: actions.ADD_UPDATE_EXPEREIENCE,
              payload: {
                ...experienceCrud,
                flag: false,
                type: 'add',
                data: {
                  aircraft_type: '',
                  remarks: '',
                  year_of_experience: '',
                }
              },
            })
            dispatch({
              type: actions.UPDATE_EXPERIENCE,
              payload: response.data.data
            }
            )
          }
        })
    }
  }
}

export const addEditUserContractAc = (params, contractCrud, contractId) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_PROCESSING,
      payload: true,
    })
    if (contractCrud.type == 'add') {
      globalPostService(`console/contract-user/${params.id}/`, contractCrud.data)
        .then(response => {
          dispatch({
            type: actions.PROFILE_PROCESSING,
            payload: false,
          })
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success')
            dispatch({
              type: actions.USER_CONTRACT_CRUD,
              payload: {
                ...contractCrud,
                flag: false,
                type: 'add',
                data: {
                  start_date: '',
                  end_date: '',
                  contract_file: '',
                  fee_rate: ''
                }
              },
            })
            dispatch(getUsetContractsAc(params))
          }
        })
    } else {
      globalPutService(`console/contract-user/${params.id}/${contractId}/`, contractCrud.data)
        .then(response => {
          dispatch({
            type: actions.PROFILE_PROCESSING,
            payload: false,
          })
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success')
            dispatch({
              type: actions.USER_CONTRACT_CRUD,
              payload: {
                ...contractCrud,
                flag: false,
                type: 'add',
                data: {
                  start_date: '',
                  end_date: '',
                  contract_file: '',
                  fee_rate: ''
                }
              },
            })
            dispatch(getUsetContractsAc(params))
          }
        })
    }
  }
}

export const editUserAc = (formdata, userId) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_PROCESSING,
      payload: true,
    })
    globalPutService('/console/get-user-details/' + userId + '/', formdata)
      .then(response => {
        dispatch({
          type: actions.PROFILE_PROCESSING,
          payload: false,
        })
        if (response.data.statusCode == 200) {
          let userDetail = getLocalStorageInfo();

          if (response.data.data.is_active) {
            toastFlashMessage(response.data.message, "success")
          }
          if (!response.data.data.is_active) {
            trackActivity('User Management', { event_type: 'User Deactivated', user_id: userId, request_body: formdata, response_body: JSON.stringify(response.data.data) })
            toastFlashMessage("User deactivated successfully !", "success")
            browserHistory.push('/users/manage/inactive');
          }

          userDetail = {
            ...userDetail,
            user: {
              ...userDetail.user,
              name: response.data.data.first_name + ' ' + response.data.data.last_name,
              profile_pic: response.data.data.profile_pic,
              designation: response.data.data.designation
            }
          }

          // window.location.reload()
          dispatch(userInfoAc(userId))
          dispatch({
            type: actions.USER_PROFILE_INFO,
            payload: response.data.data
          })
          dispatch({
            type: actions.TYPE_UPDATE,
            payload: 'view'
          })
        }
      })
  }
}
export const updateUserProjectsAc = (formdata, userId) => {
  return (dispatch) => {
    globalPutService('/console/get-user-details/' + userId + '/', formdata)
      .then(response => {
        if (response.data.statusCode == 200) {
          let userDetail = getLocalStorageInfo();
          if (!response.data.data.is_active) {
            toastFlashMessage("User deactivated successfully !", "success")
            browserHistory.push('/users/manage/inactive');
          }

          userDetail = {
            ...userDetail,
            user: {
              ...userDetail.user,
              name: response.data.data.first_name + ' ' + response.data.data.last_name,
              profile_pic: response.data.data.profile_pic,
              designation: response.data.data.designation
            }
          }
          // window.location.reload()
          toastFlashMessage(response.data.message, 'success')
          dispatch({
            type: actions.USER_PROFILE_INFO,
            payload: response.data.data
          })
          dispatch({
            type: actions.TYPE_UPDATE,
            payload: 'view'
          })
        }
      })
  }
}
export const updateUserSkillRatingsAc = (formdata, userId) => {
  return (dispatch) => {
    globalPutService('/console/user-competency-rating/' + userId + '/', formdata)
      .then(response => {
        if (response.data.statusCode == 200) {
          // window.location.reload()
          toastFlashMessage(response.data.message, 'success')
          dispatch({
            type: actions.TYPE_UPDATE,
            payload: 'view'
          })
          dispatch(userInfoAc(userId))
        }
      })
  }
}
export const activateUserAc = (userId) => {
  var formData = new FormData();
  formData.append("is_active", true);
  return (dispatch) => {
    globalPutService('/console/get-user-details/' + userId + '/', formData)
      .then(response => {
        if (response.data.statusCode == 200) {
          trackActivity('User Management', { event_type: 'User Activated', user_id: userId, request_body: formData })
          toastFlashMessage("User activated successfully !", "success")
          browserHistory.push('/users/manage/' + userId);
        }
      })
  }
}


export const resendInviteAc = (userId) => {
  return (dispatch) => {

    globalGetService('/console/resend-user-invite/' + userId + '/')
      .then(response => {

        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success');
        }
      })
  }
}

export const userProfileAc = () => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_PROFILE_LOADER,
      payload: true,
    })
    globalGetService('/console/user/', {})
      .then(response => {
        dispatch({
          type: actions.USER_PROFILE_LOADER,
          payload: false,
        })
        if (response.data.statusCode == 200) {
          dispatch(getUsetContractsAc({ id: response.data.data.slug }))
          dispatch(userStatusListAc({ id: response.data.data.slug }))
          dispatch({
            type: actions.USER_PROFILE_INFO,
            payload: response.data.data
          })
        }
      })
  }
}

export const exportPermisionAc = () => {
  let userDetail = getLocalStorageInfo();
  let queryParam = {
    export: 'xls'
  }
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_DOWNLOAD_LOADER,
      payload: true,
    })
    globalExportService('api/user/' + userDetail.user.id + '/permissions/', queryParam)
      .then(response => {
        dispatch({
          type: actions.PROFILE_DOWNLOAD_LOADER,
          payload: false,
        })
        downloadFileType(response, 'security_matrix_export', '.xls')
        trackActivity('User Management', { event_type: 'Report Exported', extension: 'xls', file_name: 'security_matrix_export' })
      })
  }
}
//manage users actions
export const getUsersListAc = (page, name, filter, type) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_DOWNLOAD_LOADER,
      payload: true,
    })
    globalGetService('v2/get-user-list/', removeEmptyKey({ page: page, search: name, sort: filter.sort, sort_by: filter.sort_by, filter_type: type, application: 8 }))
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.GET_USERS_LIST,
            payload: response.data.data
          })
          trackActivity('User Management', {
            event_type: 'Page Visited',
            page_title: 'User List'
          })
        }
        dispatch({
          type: actions.PROFILE_DOWNLOAD_LOADER,
          payload: false,
        })
      })
  }
}
//manage users actions
export const getInvitesListAc = (page) => {
  return (dispatch) => {
    dispatch({
      type: actions.PROFILE_DOWNLOAD_LOADER,
      payload: true,
    })
    globalGetService('console/invite-user-request/', { page: page })
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.GET_INVITE_LIST,
            payload: response.data.data
          })
          trackActivity('User Management', {
            event_type: 'Page Visited',
            page_title: 'User Invite'
          })
        }
        dispatch({
          type: actions.PROFILE_DOWNLOAD_LOADER,
          payload: false,
        })
      })
  }
}

export const getGroupsListAc = (userId = '') => {
  return (dispatch) => {
    if (userId) {
      globalGetService('console/sparta-group-list/user/' + userId + '/')
        .then(response => {
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.GET_GROUPS_LIST,
              flag: 'particular',
              payload: response.data.data
            })
          }
        })
    } else {
      globalGetService('console/sparta-groups/', { add_group_user: true })
        .then(response => {
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.GET_GROUPS_LIST,
              flag: 'all',
              payload: response.data.data
            })
          }
        })
    }

  }
}
export const getGroupsSuperListAc = (slug) => {
  return (dispatch) => {
    globalGetService('console/sparta-group-list/user/' + slug + '/', { super_user: true })
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.GET_GROUPS_LIST,
            flag: 'particular',
            payload: response.data.data
          })
        }
      })
  }
}


export const getAssetsListAc = (userId) => {
  return (dispatch) => {
    globalGetService('console/asset-access-list/user/' + userId + '/add-asset/')
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.GET_ASSETS_LIST,
            payload: response.data.data
          })
        }
      })
  }
}


export const userInfoAc = (userId) => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_PROFILE_LOADER,
      payload: true,
    })
    globalGetService('/console/get-user-details/' + userId + '/', {})
      .then(response => {
        dispatch({
          type: actions.USER_PROFILE_LOADER,
          payload: false,
        })
        dispatch(userStatusListAc({ id: response.data.data.slug }))
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.USER_PROFILE_INFO,
            payload: response.data.data
          })
        }
      })
  }
}

export const deleteUserGroupAc = (userGroups, userId) => {
  return (dispatch) => {
    globalDeleteService(`/console/sparta-group-list/user/${userId}/`, { sparta_group_list: userGroups })
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.DELETE_USER_GROUP,
            payload: userGroups
          })
          toastFlashMessage(response.data.message, 'success');
        }
      })
  }
}
export const addUserGroupAc = (userGroups, userId) => {
  let groups = [];
  return (dispatch) => {
    globalPutService(`/console/sparta-group-list/user/${userId}/`, { sparta_group_list: userGroups })
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.ADD_USER_GROUP,
            payload: response.data.data
          })
          toastFlashMessage(response.data.message, 'success');
        }
      })
  }
}

export const deleteUserAssetAc = (userAssets, userId) => {
  return (dispatch) => {
    globalDeleteService(`console/asset-access-list/user/${userId}/`, { ids: userAssets })
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.DELETE_USER_ASSET,
            payload: userAssets
          })
          toastFlashMessage(response.data.message, 'success');
        }
      })
  }
}


export const addUserAssetsAc = (userAssets, userId) => {
  return (dispatch) => {
    globalPostService(`console/asset-access-list/user/${userId}/`, { asset_list: userAssets })
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.ADD_USER_ASSETS,
            payload: response.data.data
          })
          toastFlashMessage(response.data.message, 'success');
        }
      })
  }
}

export const downLoadUserFormAc = (fileType, fileName, filters) => {
  let filterParam = {};
  filterParam = {
    ...filterParam,
    download: fileType
  }
  return (dispatch) => {
    dispatch({
      type: actions.USER_LIST_EXPORT_LOADER,
      payload: true
    });
    globalExportService('v2/get-user-list/', { ...filterParam, application: 8, ...filters })
      .then(response => {
        dispatch({
          type: actions.USER_LIST_EXPORT_LOADER,
          payload: false
        });
        downloadFileType(response, fileName + '.', fileType)
      })
  }
}


export const createUserAc = (data) => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_PROFILE_LOADER,
      payload: true,
    })
    globalPostService('console/create-user/', data)
      .then(response => {
        dispatch({
          type: actions.USER_PROFILE_LOADER,
          payload: false,
        })
        if (response.data.statusCode == 200) {
          browserHistory.push('/users/manage/' + response.data.data.slug);
          dispatch({
            type: actions.GET_USER_CRUD,
            payload: {}
          })
          dispatch({
            type: actions.USER_ADD_CRUD_BAR,
            payload: {}
          })
          dispatch(getUsersUsageAc())
          toastFlashMessage(response.data.message, 'success');
          trackActivity('User Management', { event_type: 'User Created', request_body: data, response_body: JSON.stringify(response.data.data) })
        }
      })
  }
}

export const acceptInviteRequestAc = (slug) => {
  return (dispatch) => {
    globalPutService('console/invite-user-request/' + slug + '/', { status: 2 })
      .then(response => {
        if (response.data.statusCode == 200) {
          browserHistory.push('/users/manage/' + response.data.data.slug);
          toastFlashMessage(response.data.message, 'success');
        }
      })
  }
}
export const rejectInviteRequestAc = (slug) => {
  return (dispatch) => {
    globalPutService('console/invite-user-request/' + slug + '/', { status: 3 })
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.REJECT_INVITE_REQUEST,
            payload: slug
          })
          toastFlashMessage(response.data.message, 'success');
        }
      })
  }
}

export const getUserEmailAc = () => {
  return (dispatch) => {
    globalGetService('console/get-user-list/', { filter_type: 'add_lessor' })
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.USER_EMAIL_LIST,
            payload: response.data.data
          })
        }
      })
  }
}

export const exportCVAc = (user_slug, user_name) => {
  return (dispatch) => {
    dispatch({
      type: actions.DELETE_EXP_LOADER,
      payload: true
    });
    globalExportService('console/export-user-profile/' + user_slug + "/")
      .then(response => {
        dispatch({
          type: actions.EXPORT_CV,
          payload: false
        });
        dispatch({
          type: actions.DELETE_EXP_LOADER,
          payload: false
        });
        downloadFileType(response, user_name + '.', 'pdf');
        trackActivity('User Management', { event_type: 'Exported User Profile', user_slug: user_slug, user_name: user_name, extension: 'pdf', file_name: user_name + '.pdf' })
      })
  }
}

export const userProjectRatingAc = (user_slug) => {
  return (dispatch) => {
    globalGetService('console/user-project-rating/' + user_slug + "/")
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.USER_PROJECT_RATING,
            payload: response.data.data
          })
        }
      })
  }
}

export const getUsersUsageAc = () => {
  return (dispatch) => {
    globalGetService('/console/license/user/usage/')
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.USERS_USAGE,
            payload: response.data.data
          })
        }
      })
  }
}

export const importAssetAc = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    })
    globalPostService(`/console/import-data-transfer-file/`, formData)
      .then(response => {
        dispatch(assetsListAc())
        if (response.data.statusCode == 200) {
          toastFlashMessage('Asset import intiated successfully.', 'success')
        }
      })
  }
}

export const getLessorListAc = () => {
  return (dispatch) => {
    globalGetService('/lessor/list/')
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.LESSOR_LIST,
            payload: response.data.data
          })
        }
      })
  }
}

export const transferAssetAc = (params, applications, asset) => {
  return (dispatch) => {
    globalPostService(`/console/${params.type}/${params.aircraft_slug}/get-data-transfer-file/`, applications)
      .then(response => {
        if (!response.data.statusCode) {
          let fileName = asset.msn ? `MSN_${asset.msn}_private_key.` : `ESN_${asset.esn}_private_key.`
          downloadFileType(response.data, fileName, 'txt')
          toastFlashMessage('Data Transfer Key has been downloaded successfully', 'success')
          browserHistory.push('/technical/projects')
        }
        dispatch(assetViewAc(params))
      })
  }
}

export const getApuTypesAc = (aircraft) => {
  return (dispatch) => {
    globalGetService(`/console/apu-types/`, aircraft)
      .then(response => {
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.APU_TYPES_LIST,
            payload: response.data.data.apu_types
          })
        }
      })
  }
}

export const addEditApuAc = (apu) => {
  let reqData = Object.assign(apu)
  reqData = {
    ...reqData,
    tsn:apu.tsn ? apu.tsn : 0,
    csn: apu.csn ? apu.csn : 0,
    tslsv: apu.tslsv ? apu.tslsv :0,
    cslsv: apu.cslsv ? apu.cslsv : 0,
    average_monthly_cycles : apu.average_monthly_cycles ? apu.average_monthly_cycles : 0,
    average_monthly_hours : apu.average_monthly_hours  ? apu.average_monthly_hours : 0
  }
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    });
    globalPostService(`/console/add-apu/`, reqData)
      .then(response => {
        dispatch({
          type: actions.LIST_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          if (Object.keys(response.data.data).length) {
            trackActivity('Add Asset', {
              event_type: 'Asset Created',
              asset_type: 'APU',
              asset_name: `APU ${response.data.data.serial_number}`,
              request_body: apu,
              response_body: JSON.stringify(response.data.data)
            })
            dispatch(assetsListAc())
          }
          toastFlashMessage(response.data.message, 'success');
        }
        if (response.data.statusCode == 5000 || response.data.statusCode == 5001 || response.data.statusCode == 5002) {
          dispatch({
            type: actions.TOGGLE_LICENCE_MODAL,
            payload: true,
            message: response.data.message
          })
        }
      })
  }
}
export const addEditLgAc = (lg) => {
  let reqData = Object.assign(lg)
  reqData = {
    ...reqData,
    tsn:lg.tsn ? lg.tsn : 0,
    csn: lg.csn ? lg.csn : 0,
    tslsv: lg.tslsv ? lg.tslsv :0,
    cslsv: lg.cslsv ? lg.cslsv : 0,
    average_monthly_cycles : lg.average_monthly_cycles ? lg.average_monthly_cycles : 0,
    average_monthly_hours : lg.average_monthly_hours  ? lg.average_monthly_hours : 0
  }
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    });
    globalPostService(`/console/add-landing-gear/`, reqData)
      .then(response => {
        dispatch({
          type: actions.LIST_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          if (Object.keys(response.data.data).length) {
            trackActivity('Add Asset', {
              event_type: 'Asset Created',
              asset_type: 'Landing Gear',
              asset_name: `LG ${response.data.data.serial_number}`,
              request_body: lg,
              response_body: JSON.stringify(response.data.data)
            })
            dispatch(assetsListAc())
          }
          toastFlashMessage(response.data.message, 'success');
        }
        if (response.data.statusCode == 5000 || response.data.statusCode == 5001 || response.data.statusCode == 5002) {
          dispatch({
            type: actions.TOGGLE_LICENCE_MODAL,
            payload: true,
            message: response.data.message
          })
        }
      })
  }
}
export const addEditPropellerAc = (lg) => {
  let reqData = Object.assign(lg)
  reqData = {
    ...reqData,
    tsn:lg.tsn ? lg.tsn : 0,
    csn: lg.csn ? lg.csn : 0,
    tslsv: lg.tslsv ? lg.tslsv :0,
    cslsv: lg.cslsv ? lg.cslsv : 0,
    average_monthly_cycles : lg.average_monthly_cycles ? lg.average_monthly_cycles : 0,
    average_monthly_hours : lg.average_monthly_hours  ? lg.average_monthly_hours : 0
  }
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    });
    globalPostService(`/console/add-propeller/`, reqData)
      .then(response => {
        dispatch({
          type: actions.LIST_LOADER,
          payload: false
        });
        if (response.data.statusCode == 200) {
          if (Object.keys(response.data.data).length) {
            trackActivity('Add Asset', {
              event_type: 'Asset Created',
              asset_type: 'Propeller',
              asset_name: `PROPELLER ${response.data.data.serial_number}`,
              request_body: lg,
              response_body: JSON.stringify(response.data.data)
            })
            dispatch(assetsListAc())
          }
          toastFlashMessage(response.data.message, 'success');
        }
        if (response.data.statusCode == 5000 || response.data.statusCode == 5001 || response.data.statusCode == 5002) {
          dispatch({
            type: actions.TOGGLE_LICENCE_MODAL,
            payload: true,
            message: response.data.message
          })
        }
      })
  }
}

export const exportMarkeTechSummeryAc = (aircraft) => {
  return (dispatch) => {
    dispatch({
      type: actions.LIST_LOADER,
      payload: true
    });
    globalExportService('console/aircraft/' + aircraft.slug + '/export-tech-marketing-summary/', { download: 'pdf' })
      .then(response => {
        dispatch({
          type: actions.LIST_LOADER,
          payload: false
        });
        downloadFileType(response, 'MSN' + aircraft.msn + '_marketing_summary.', 'pdf')
      });
  }
}

export const userStatusListAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_PROFILE_LOADER,
      payload: true
    })
    globalGetService(`console/user_status_update/${params.id}/`)
      .then(response => {
        dispatch({
          type: actions.USER_PROFILE_LOADER,
          payload: false
        })
        if (response.data.statusCode == 200) {
          dispatch({
            type: actions.USER_AVAILABILITY_LIST,
            payload: response.data.data
          })
        }
      })
  }
}
export const userStatusUpdateAc = (params, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_PROFILE_LOADER,
      payload: true
    })
    if (data.type == 'add') {
      globalPostService(`console/user_status_update/${params.id}/`, data.data)
        .then(response => {
          dispatch({
            type: actions.USER_PROFILE_LOADER,
            payload: false
          })
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.USER_AVAILABILITY_CRUD,
              payload: { flag: false, type: 'add', data: {} }
            })
            dispatch(userStatusListAc(params))
          }
        })
    } else {
      delete data.data.created_by
      delete data.data.updated_by
      globalPutService(`console/user_status_update/${params.id}/${data.data.id}/`, data.data)
        .then(response => {
          dispatch({
            type: actions.USER_PROFILE_LOADER,
            payload: false
          })
          if (response.data.statusCode == 200) {
            dispatch({
              type: actions.USER_AVAILABILITY_CRUD,
              payload: { flag: false, type: 'add', data: {} }
            })
            dispatch(userStatusListAc(params))
          }
        })
    }
  }
}

export const deleteUserStatusAc = (params, statusId) => {
  return (dispatch) => {
    dispatch({
      type: actions.USER_PROFILE_LOADER,
      payload: true
    })
    globalDeleteService(`console/user_status_update/${params.id}/${statusId}/`)
      .then(response => {
        dispatch({
          type: actions.USER_PROFILE_LOADER,
          payload: false
        })
        if (response.data.statusCode == 200) {
          dispatch(userStatusListAc(params))
        }
      })
  }
}
export function getSecurityPermissionListApi() {
  this.setState({
    loader: true
  })
  globalGetService(`console/get-sparta-group-permissions/`, { application: 'technical' })
    .then(response => {
      this.setState({
        loader: false
      })
      if (response.data.statusCode == 200) {
        this.setState({
          securityGrpList: response.data.data
        })
      }
    })
}
export function exportSecurityPermissionApi() {
  this.setState({
    exportLoader: true
  })
  globalExportService(`console/get-sparta-group-permissions/`, { download: 'xls' })
    .then(response => {
      this.setState({
        exportLoader: false
      })
      downloadFileType(response, 'Security_Permissions.', 'xls')
    })
}
export const filteredLesseeListAc = (query) => {
  return (dispatch) => {
    globalGetService('console/lessees/', query)
    .then(response => {
      dispatch({
        type: actions.FILTERED_LESSEE_LIST,
        payload: response.data.data
      })
    })
  }
}
export const filteredAircraftTypeListAc = (query) => {
  return (dispatch) => {
    globalGetService('console/aircraft-types/', query)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.FILTERED_AIRCRAFT_TYPE_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const filteredEngTypeListAc = (query) => {
  return (dispatch) => {
    globalGetService('console/engine-types/', query)
    .then(response => {
      dispatch({
        type: actions.FILTERED_ENGINE_TYPE_LIST,
        payload: response.data.data
      })
    })
  }
}
// export const filteredApuTypeListAc = (query) => {
//   return (dispatch) => {
//     globalGetService('console/apu-types/', query)
//     .then(response => {
//       dispatch({
//         type: actions.FILTERED_APU_TYPE_LIST,
//         payload: response.data.data
//       })
//     })
//   }
// }
