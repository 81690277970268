import React from 'react';
import {Paper} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const BudgetExpenseChartByCategory = ({project,series}) => {
    const chartOptions = {
        chart: {
            type: 'pie',
            height: 300,
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {
            text: 'Project Expense based on Category',
            align: 'left'
        },
        subtitle:{
            text:'Approved & Paid Expense ',
            align: 'left'
        },
         tooltip: {
            shared: true,
            headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
            pointFormat: '<span style="color:{point.color}">\u25CF</span>'+ project.currency+' <b>{point.y} </b><br/>'
        },
        plotOptions: {
            pie: {
                innerSize: series.length ? 100:0,
                depth: 45,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>({point.percentage:.1f}%)'
                }
            }
        },
        series:[
            {
                name: 'Category',
                colorByPoint: true,
                data:series
            }
        ],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
            // buttons: {
            //   contextButton: {
            //     menuItems: ['downloadPNG', 'downloadSVG'],
            //   },
            // },
        }

    }


    return(
        <Paper>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Paper>
    )
}
export default BudgetExpenseChartByCategory;